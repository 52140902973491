import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAtom } from 'jotai';

import { Button, Checkbox, useCompany, useIsAdmin, useWarehouse } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { WarehouseApi } from 'src/api';
import { selectedCompanyAtom } from 'src/features/Warehouse';

import { getViewSettings } from '../../actions';
import { warehouseColumnsConfig } from '../ProductsList/config';

import style from './ProductListSettings.module.scss';

const ProductListSettings = ({ onSave, onCancel }) => {
  const company = useCompany();
  const isAdmin = useIsAdmin();
  const dispatch = useDispatch();
  const { view } = useWarehouse();
  const [selectedCompany] = useAtom(selectedCompanyAtom);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState(view.columns);

  const allColumns = useMemo(() => {
    return warehouseColumnsConfig
      .sort((a, b) => a.order - b.order)
      .filter((column) => {
        const checkIsAllowed = (permission) => isAdmin || (company && company.permissions.includes(permission));
        const canView = column.permissions.length === 0 || column.permissions.some((perm) => checkIsAllowed(perm));
        return canView;
      });
  }, [isAdmin, company]);

  const toggleColumn = (id) => {
    if (selectedColumns.includes(id)) {
      setSelectedColumns(selectedColumns.filter((column) => column !== id));
    } else {
      setSelectedColumns([...selectedColumns, id]);
    }
  };

  const handleSave = async () => {
    const payload = {
      settings: { columns: selectedColumns },
      ...(isAdmin && { company_id: selectedCompany })
    };

    try {
      setIsLoading(true);
      await WarehouseApi.setViewSettings(payload);
      dispatch(getViewSettings(selectedCompany));
      onSave();
    } catch (err) {
      notifyApiError(err);
      setSelectedColumns(view.columns);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={style.container}>
      <div className={style.columnSettings}>
        {allColumns.map((column) => (
          <Checkbox
            key={`settings-column-${column.id}`}
            content={column.name}
            value={selectedColumns.includes(column.id)}
            onChange={() => toggleColumn(column.id)}
            disabled={selectedColumns.length === 1 && selectedColumns.includes(column.id)}
          />
        ))}
      </div>
      <div className={style.buttons}>
        <Button
          label={'Zapisz'}
          onClick={handleSave}
          isLoading={isLoading}
        />
        <Button
          label={'Anuluj'}
          gray
          disabled={isLoading}
          onClick={onCancel}
        />
      </div>
    </div>
  );
};

export default ProductListSettings;
