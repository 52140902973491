export const GET_SUBWAREHOUSES_REQUEST = 'GET_SUBWAREHOUSES_REQUEST';
export const GET_SUBWAREHOUSES_SUCCESS = 'GET_SUBWAREHOUSES_SUCCESS';
export const GET_SUBWAREHOUSES_FAILURE = 'GET_SUBWAREHOUSES_FAILURE';

export const REFRESH_SUBWAREHOUSES_REQUEST = 'REFRESH_SUBWAREHOUSES_REQUEST';
export const REFRESH_SUBWAREHOUSES_SUCCESS = 'REFRESH_SUBWAREHOUSES_SUCCESS';
export const REFRESH_SUBWAREHOUSES_FAILURE = 'REFRESH_SUBWAREHOUSES_FAILURE';

export const GET_VIEW_SETTINGS_REQUEST = 'GET_VIEW_SETTINGS_REQUEST';
export const GET_VIEW_SETTINGS_SUCCESS = 'GET_VIEW_SETTINGS_SUCCESS';
export const GET_VIEW_SETTINGS_FAILURE = 'GET_VIEW_SETTINGS_FAILURE';
