import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { AsyncSelect, useAuthUser } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { AdminApi, UserApi } from 'src/api';
import { userRoles } from 'src/constants/enums';
import { compareArrays, formatUserOption } from 'src/utils/helpers';

import style from '../Filtering.module.scss';

const FilterBySupplier = ({
  params,
  fullWidth,
  paramKey = 'supplier',
  valueKey = 'id',
  labelKey = 'name',
  isMulti,
  queryParams = {},
  asUser,
  label,
  disabledElements,
  isOptionDisabled,
  syncWithParams,
  ...rest
}) => {
  const [isInitiallyLoaded, setIsInitiallyLoaded] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState(isMulti ? [] : null);
  const user = useAuthUser();

  const paramValue = params.get(paramKey);

  const setSingleValue = (supplier) => {
    const areValueTheSame = supplier?.value === paramValue;

    if (!areValueTheSame) {
      if (params.get('page')) {
        params.setFew([
          { key: paramKey, value: supplier?.value },
          { key: 'page', value: '1' }
        ]);
      } else {
        params.set(paramKey, supplier?.value);
      }
    }
  };

  const setMultiValue = (suppliers) => {
    const idsArray = suppliers?.map((sup) => sup.value);
    const areArrayTheSame = compareArrays(idsArray, paramValue);

    if (!areArrayTheSame) {
      if (params.get('page')) {
        params.setFew([
          { key: paramKey, value: idsArray },
          { key: 'page', value: '1' }
        ]);
      } else {
        params.set(paramKey, idsArray);
      }
    }
  };

  const onChangeHandler = (e) => {
    setSelectedSupplier(e);
    if (isMulti) {
      setMultiValue(e);
    } else {
      setSingleValue(e);
    }
  };

  const fetchSupplier = (param = paramValue) => {
    const queryParams = {
      supplier_id: Number(param),
      perPage: 1,
      page: 1
    };
    return UserApi.getDistributors(queryParams);
  };

  const getDataForSelectedElement = async () => {
    const areValueTheSame = selectedSupplier?.value === paramValue;
    if (areValueTheSame) return;
    try {
      const { data } = await fetchSupplier();
      setSelectedSupplier(formatUserOption(data[0]) || null);
      setIsInitiallyLoaded(true);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const getDataForAllElements = async () => {
    const idsArray = selectedSupplier?.map((sup) => String(sup.value));
    const areArrayTheSame = compareArrays(idsArray, paramValue);
    if (areArrayTheSame) return;
    try {
      const results = await Promise.all(paramValue.map((param) => fetchSupplier(param)));
      setSelectedSupplier(results.map((result) => formatUserOption(result.data[0])) || []);
      setIsInitiallyLoaded(true);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const syncDataWithParams = async () => {
    if (!paramValue) {
      setSelectedSupplier(isMulti ? [] : null);
    } else {
      if (isMulti) {
        const wasElementDeleted = selectedSupplier?.some((sup) => !paramValue.includes(String(sup.value)));

        if (wasElementDeleted) {
          setSelectedSupplier((prev) => prev.filter((sup) => paramValue.includes(String(sup.value))));
        } else {
          const isArray = Array.isArray(paramValue);
          const paramArray = isArray ? paramValue : [paramValue];
          const missingElement = paramArray.find(
            (param) => !selectedSupplier?.some((sup) => String(sup.value) === String(param))
          );
          if (missingElement) {
            try {
              const { data } = await fetchSupplier(missingElement);
              setSelectedSupplier((prev) => [...prev, formatUserOption(data[0])]);
            } catch (err) {
              notifyApiError(err);
            }
          }
        }
      } else if (String(selectedSupplier?.value) !== paramValue) {
        getDataForSelectedElement();
      }
    }
  };

  useEffect(() => {
    if (paramValue) {
      if (Array.isArray(paramValue)) getDataForAllElements();
      else getDataForSelectedElement();
    } else {
      setIsInitiallyLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (syncWithParams && isInitiallyLoaded) {
      syncDataWithParams();
    }
  }, [paramValue]);

  if (user?.role !== userRoles.admin || asUser) {
    return (
      <AsyncSelect
        value={selectedSupplier}
        onChange={onChangeHandler}
        apiCallback={UserApi.getDistributors}
        valueKey={'id'}
        labelKey={'name'}
        label={label}
        placeholder={'Dostawca'}
        queryParams={{ pagination: 1, ...queryParams }}
        isOptionDisabled={isOptionDisabled}
        disabledElements={disabledElements}
        isClearable
        isMulti={isMulti}
        {...rest}
      />
    );
  }

  return (
    <AsyncSelect
      value={selectedSupplier}
      onChange={onChangeHandler}
      apiCallback={AdminApi.getUsers}
      valueKey={valueKey}
      label={label}
      labelKey={labelKey}
      placeholder={'Dostawca'}
      isOptionDisabled={isOptionDisabled}
      disabledElements={disabledElements}
      queryParams={{ role: userRoles.supplier, ...queryParams }}
      wrapperStyle={classNames(style.select, {
        [style.selected]: !!selectedSupplier,
        [style.fullWidth]: fullWidth
      })}
      isClearable
      isMulti={isMulti}
      {...rest}
    />
  );
};

export default FilterBySupplier;
