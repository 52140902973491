import React, { useEffect, useState } from 'react';

import { ReactComponent as PlusIcon } from 'assets/icons/plus-blue.svg';
import { AddressDetails, AddressForm, Card, InvoiceDetails, InvoiceForm, Modal } from 'components';

import style from './Address.module.scss';
import shared from 'styles/Shared.module.scss';

const Address = React.forwardRef(({ setShippingAddressId, setInvoiceDetailsId, company }, ref) => {
  const [isAddresModalOpen, setIsAddresModalOpen] = useState(false);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
  const [isAddressFormOpen, setIsAddressFormOpen] = useState(false);
  const [isInvoiceFormOpen, setIsInvoiceFormOpen] = useState(false);

  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [invoiceDetails, setInvoiceDetails] = useState(null);

  const addNewAddressButton = (
    <button
      className={style.addNew}
      onClick={() => setIsAddressFormOpen(true)}
    >
      <PlusIcon />
      Dodaj nowy adres
    </button>
  );

  const addNewInvoiceDetailsButton = (
    <button
      className={style.addNew}
      onClick={() => setIsInvoiceFormOpen(true)}
    >
      <PlusIcon />
      Dodaj nowe dane
    </button>
  );

  useEffect(() => {
    setShippingAddressId(deliveryAddress?.id);
  }, [deliveryAddress]);

  useEffect(() => {
    setInvoiceDetailsId(invoiceDetails?.id);
  }, [invoiceDetails]);

  // Set default data if not set, or update if already set
  useEffect(() => {
    if (!deliveryAddress && company?.shipping_details) {
      const defaultAddress = company?.shipping_details?.find((address) => address.is_default);
      setDeliveryAddress(defaultAddress || company?.shipping_details[0]);
    } else if (deliveryAddress && company?.shipping_details) {
      const newAddress = company?.shipping_details.find((address) => address.id === deliveryAddress.id);
      setDeliveryAddress(newAddress);
    }

    if (!invoiceDetails && company?.invoices_details) {
      const defaultInvoiceData = company?.invoices_details?.find((invoice) => invoice.is_default);
      setInvoiceDetails(defaultInvoiceData || company?.invoices_details[0]);
    } else if (invoiceDetails && company?.invoices_details) {
      const newInvoiceData = company?.invoices_details.find((invoice) => invoice.id === invoiceDetails.id);
      setInvoiceDetails(newInvoiceData);
    }
  }, [company]);

  if (!company) {
    return (
      <Card className={shared.defaultWrapper}>
        <h2>Przepraszamy,</h2>
        <p>Aktualnie administrator nie może składać zamówień.</p>
      </Card>
    );
  }

  return (
    <div className={style.container}>
      <div
        className={style.column}
        ref={ref}
      >
        <h2>Adres wysyłki</h2>
        <p>Wybierz adres na który mają zostać wysłane produkty.</p>
        {deliveryAddress ? (
          <AddressDetails
            details={deliveryAddress}
            companyId={company?.id}
          />
        ) : (
          addNewAddressButton
        )}
        {company?.shipping_details?.length > 0 && (
          <a
            onClick={() => setIsAddresModalOpen(true)}
            className={style.chooseAddress}
          >
            Wybierz inny adres
          </a>
        )}
      </div>
      <div className={style.column}>
        <h2>Dane do faktury</h2>
        <p>Zamówienia zostanie złożone w imieniu firmy, dla której przygotowana była wycena.</p>
        {invoiceDetails ? (
          <InvoiceDetails
            details={invoiceDetails}
            companyId={company?.id}
          />
        ) : (
          addNewInvoiceDetailsButton
        )}
        {company?.invoices_details?.length > 0 && (
          <a
            onClick={() => setIsInvoiceModalOpen(true)}
            className={style.chooseAddress}
          >
            Wybierz inne dane do faktury
          </a>
        )}
      </div>

      <Modal
        title={'Wybierz adres dostawy'}
        visible={isAddresModalOpen}
        onClose={() => setIsAddresModalOpen(false)}
      >
        <div className={style.wrapper}>
          {company?.shipping_details?.map((address) => (
            <AddressDetails
              details={address}
              companyId={company?.id}
              key={address.id}
              onClick={() => {
                setDeliveryAddress(address);
                setIsAddresModalOpen(false);
              }}
            />
          ))}
          {addNewAddressButton}
        </div>
      </Modal>
      <Modal
        title={'Wybierz dane do faktury'}
        visible={isInvoiceModalOpen}
        onClose={() => setIsInvoiceModalOpen(false)}
      >
        <div className={style.wrapper}>
          {company?.invoices_details?.map((invoice) => (
            <InvoiceDetails
              details={invoice}
              companyId={company?.id}
              key={invoice.id}
              onClick={() => {
                setInvoiceDetails(invoice);
                setIsInvoiceModalOpen(false);
              }}
            />
          ))}
          {addNewInvoiceDetailsButton}
        </div>
      </Modal>

      <Modal
        visible={isAddressFormOpen}
        onClose={() => setIsAddressFormOpen(false)}
        title={'Dodaj nowy adres'}
      >
        <AddressForm
          closeModal={() => setIsAddressFormOpen(false)}
          companyId={company?.id}
        />
      </Modal>
      <Modal
        visible={isInvoiceFormOpen}
        onClose={() => setIsInvoiceFormOpen(false)}
        title={'Dodaj nowe dane do faktury'}
      >
        <InvoiceForm
          closeModal={() => setIsInvoiceFormOpen(false)}
          companyId={company?.id}
        />
      </Modal>
    </div>
  );
});

export default Address;
