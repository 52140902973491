import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import { Button, CustomTooltip, ProgressLine, Tag } from 'components';
import ProductStatus from 'components/layout/ProductStatus';
import { notifyApiError } from 'components/layout/Toasts';

import { ValuationsApi } from 'src/api';
import { availabilities, valuationStatuses } from 'src/constants/enums';
import { refreshValuations } from 'src/features/ValuationView/actions';
import { getFormattedAmount } from 'src/utils/helpers';

import Comments from './components/Comments';
import Gifts from './components/Gifts';
import OfferWrapper from './components/OfferWrapper';
import { EmptyWrapper } from './components/OfferWrapper';

import style from './Column.module.scss';
import shared from 'styles/Shared.module.scss';

export const tooltipContent = (
  <>
    <p>Oszczędność procentowa</p>
    <p>względem najmniej korzystnej oferty</p>
  </>
);

const handleDragStart = (e) => e.preventDefault();

const Column = ({ column, lastElement, changeValuationSelections }) => {
  const { supplier } = column;

  const [items, setItems] = useState([]);
  const params = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const columnRef = useRef(null);
  const [heightRefresh, setHeightRefresh] = useState(0);

  const backgroundHeight = useMemo(
    () => (columnRef?.current ? columnRef.current.clientHeight - 6 + 'px' : '0 px'),
    [columnRef.current, supplier, heightRefresh]
  );

  const tableWrapperClasses = classNames(shared.tableWrapper, style.tableWrapper, {
    [style.lastTableWrapper]: lastElement
  });

  const wrapperClasses = classNames(style.wrapper, {
    [style.lastWrapper]: lastElement
  });

  const containerClasses = classNames(
    style.container,
    {
      [style.lastColumn]: lastElement
    },
    style.mainContainer
  );

  const columnClasses = classNames(style.container, {
    [style.lastColumn]: lastElement
  });

  const handleClickColumn = () => {
    if (supplier.amount) areAllProductsSelected ? unselectAllInColumn() : selectAllInColumn();
  };

  const columnPricesSum = useMemo(() => {
    let sum = 0;

    column.items.forEach((cat) => {
      cat.products.forEach((product) => {
        if (product?.valuation?.selected) {
          sum += (product.valuation?.price * (100 - product.valuation?.discount)) / 100;
        }
      });
    });

    return sum;
  }, [column]);

  const getProductsCount = useCallback(() => {
    let counter = 0;
    items.forEach(({ products }) => products.forEach(() => (counter += 1)));
    return counter;
  }, [items]);

  const getAvaliableProductsCount = useCallback(() => {
    let counter = 0;
    items.forEach(({ products }) =>
      products.forEach(({ product }) => {
        if (
          product?.prices
            .filter((price) => price.supplier_id === supplier.id)
            .some((price) => price.availability === availabilities.available)
        ) {
          counter += 1;
        }
      })
    );
    return counter;
  }, [items, supplier]);

  const selectAllInColumn = async () => {
    const queryParams = {
      supplier_id: supplier.id,
      uid: params.uid
    };

    try {
      setIsLoading(true);
      await ValuationsApi.selectAllInColumn(queryParams);
      dispatch(refreshValuations());
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const unselectAllInColumn = async () => {
    const queryParams = {
      supplier_id: supplier.id,
      uid: params.uid
    };

    try {
      setIsLoading(true);
      await ValuationsApi.unselectAllInColumn(queryParams);
      dispatch(refreshValuations());
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const priceDifference = (
    <CustomTooltip title={tooltipContent}>
      <label className={style.savings}>-{supplier.price_difference}%</label>
    </CustomTooltip>
  );

  const areAllProductsSelected = useMemo(() => {
    let areAllSelected = true;
    items.forEach(({ products }) => {
      products.forEach(({ valuation }) => {
        if (valuation && valuation.id !== null && !valuation.selected) {
          areAllSelected = false;
        }
      });
    });
    return areAllSelected;
  }, [items]);

  useEffect(() => {
    const timeout = setTimeout(() => setHeightRefresh((prev) => prev + 1), 400);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    setItems(column?.items);
  }, [column]);

  return (
    <div
      className={containerClasses}
      onDragStart={handleDragStart}
      ref={columnRef}
      id={'column-' + supplier.id}
    >
      {supplier.status === valuationStatuses.pending && (
        <div
          style={{ height: backgroundHeight }}
          className={style.background}
        />
      )}
      <div className={classNames(style.supplierWrapper, style.headCheckbox)}>
        <div className={style.columnHeader}>
          <label
            className={style.optionHeader}
            title={supplier.name}
          >
            {supplier.name}
          </label>
          {!!supplier.price_difference && priceDifference}
        </div>
        <div className={classNames(style.contentWrapper)}>
          {supplier.status === valuationStatuses.sent ? (
            <div className={style.amountWrapper}>
              <span>
                <p
                  className={classNames(style.amount, {
                    [style.loading]: isLoading
                  })}
                >
                  {getFormattedAmount(columnPricesSum)}
                </p>
                <p className={style.shippingPrice}>
                  {+columnPricesSum >= +supplier.free_shipment_price
                    ? 'Darmowa wysyłka'
                    : `(+ dostawa ${getFormattedAmount(supplier.shipment_price)})`}
                </p>
              </span>
              <span>
                <p>Wartość całego koszyka:</p>
                <p>{getFormattedAmount(supplier.amount)}</p>
              </span>
            </div>
          ) : (
            <p className={style.amount}>Oczekiwanie</p>
          )}
        </div>
        {supplier.status === valuationStatuses.sent &&
          (+supplier.available_products > 0 || +supplier.available_replacements > 0) && (
            <Button
              onClick={handleClickColumn}
              label={areAllProductsSelected ? 'Usuń zaznaczenie' : 'Zaznacz wszystko'}
              isLoading={isLoading}
              gray={!areAllProductsSelected}
            />
          )}
      </div>
      <div className={style.progressLine}>
        <ProgressLine
          prefix={'Dostępnych'}
          maxValue={getProductsCount()}
          currentValue={supplier ? getAvaliableProductsCount() : 0}
        />
      </div>
      <div className={columnClasses}>
        <section className={tableWrapperClasses}>
          <div className={classNames(shared.tableHeader, style.header)}>
            <p
              className={classNames(style.optionHeader, style.smaller)}
              title={supplier.name}
            >
              {supplier.name}
            </p>
          </div>
          {items.map(({ category, products }) => (
            <div
              className={shared.tableCategory}
              key={category.name}
            >
              <h3 className={classNames(shared.tableCategoryTitle, style.tableTitle)} />
              {products.map(({ product, valuation }, i) =>
                valuation ? (
                  <div
                    className={wrapperClasses}
                    key={valuation?.id + product.name}
                  >
                    <OfferWrapper
                      item={valuation}
                      product={product}
                      changeValuationSelections={changeValuationSelections}
                    />
                  </div>
                ) : (
                  <div
                    className={wrapperClasses}
                    key={i + product.name}
                  >
                    <EmptyWrapper
                      supplierName={supplier.name}
                      status={supplier.status}
                    >
                      {supplier.status === valuationStatuses.sent ? (
                        <div className={style.unavailableProductStatusWrapper}>
                          <ProductStatus availability={availabilities.notAvailable} />
                        </div>
                      ) : (
                        <Tag
                          value={'Oczekiwanie na wycenę'}
                          className={style.tag}
                        />
                      )}
                    </EmptyWrapper>
                  </div>
                )
              )}
            </div>
          ))}
          <Gifts
            gifts={supplier.gifts}
            supplier={supplier}
            lastElement={lastElement}
          />
          <Comments
            comment={supplier?.comment}
            supplier={supplier}
            lastElement={lastElement}
          />
        </section>
      </div>
    </div>
  );
};

export default Column;
