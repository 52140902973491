import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { EmptyState, SaveButton, Textarea, useIsAdmin, useValidator } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { OfferCatalogApi } from 'src/api';
import { refreshCartOrCatalog } from 'src/features/CatalogAndCartController/actions';

import SupplierThumbnail from '../../../SupplierThumbnail';

import style from './DeleteOfferForm.module.scss';

const DeleteOfferForm = ({ product, selectedCompany }) => {
  const isAdmin = useIsAdmin();
  const dispatch = useDispatch();
  const validator = useValidator();

  const [reason, setReason] = useState('');

  const deletePrice = async (id) => {
    if (!validator.allValid()) {
      validator.showMessages();
      return;
    }

    const data = {
      id,
      delete_reason: reason,
      ...(isAdmin && { company_id: selectedCompany })
    };

    try {
      await OfferCatalogApi.deleteSinglePrice(data);
      dispatch(refreshCartOrCatalog());
    } catch (err) {
      notifyApiError(err);
    }
  };

  return (
    <div className={style.wrapper}>
      {product.prices.length > 0 ? (
        <>
          <Textarea
            id={'delete-offer-reason'}
            name={'delete-offer-reason'}
            label={'Powód usunięcia oferty'}
            value={reason || ''}
            setValue={(reason) => setReason(reason)}
            validator={validator}
            rule={'required'}
          />
          {product.prices.map((price) => (
            <SupplierThumbnail
              key={price.id}
              item={price}
              notDraggable
              deletePrice={deletePrice}
            >
              <SaveButton
                type={'delete'}
                onClick={() => deletePrice(price.id)}
              />
            </SupplierThumbnail>
          ))}
        </>
      ) : (
        <EmptyState
          type={'productPrices'}
          tiny
        />
      )}
    </div>
  );
};

export default DeleteOfferForm;
