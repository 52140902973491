import React from 'react';

import { LabelWithValue, Menu, RelativeTime, useModalConfirm, usePermissions } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { WarehouseApi } from 'src/api';
import { userPermissions } from 'src/constants/enums';

import style from './SubwarehouseThumbnail.module.scss';

const SubwarehouseThumbnail = ({ subwarehouse, onEdit, refreshData }) => {
  const { updated_at, created_at, name, id } = subwarehouse;

  const [isCompanyAdmin] = usePermissions([userPermissions.company.admin]);
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();

  const deleteSubwarehouse = async () => {
    try {
      await WarehouseApi.deleteSubWarehouse(id);
      if (refreshData) await refreshData();
      notifyCommon([`Podmagazyn "${name}" został usunięty.`]);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const deleteSubwarehouseHandler = () => {
    handleOpenModalConfirm({
      message: `Czy na pewno chcesz usunąć "${name}"?`,
      handleConfirm: deleteSubwarehouse,
      cancelButtonName: 'Nie',
      confirmButtonName: 'Tak'
    });
  };

  const actions = [
    {
      title: 'Zmień nazwę',
      icon: 'edit',
      action: onEdit,
      hidden: !isCompanyAdmin
    },
    {
      title: 'Usuń',
      icon: 'trash',
      color: 'red',
      action: deleteSubwarehouseHandler,
      hidden: !isCompanyAdmin
    }
  ];

  return (
    <div className={style.container}>
      {renderedModalConfirm}
      <LabelWithValue
        value={name}
        className={style.name}
        label={'Nazwa podmagazynu'}
      />
      <LabelWithValue
        value={created_at ? <RelativeTime date={created_at} /> : '-'}
        label={'Data dodania'}
      />
      <LabelWithValue
        value={updated_at ? <RelativeTime date={updated_at} /> : '-'}
        label={'Data ostatniej edycji'}
      />
      <Menu
        actions={actions}
        className={style.menu}
        disabled={actions.every((action) => action.hidden)}
      />
    </div>
  );
};

export default SubwarehouseThumbnail;
