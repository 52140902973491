import React, { useState } from 'react';

import { OptionSwitch, usePermissions } from 'components';

import { userPermissions } from 'src/constants/enums';

import Export from './components/Export';
import Import from './components/Import';

import style from './ImportExportForm.module.scss';

const ImportExportForm = ({ closeModal }) => {
  const [canEditWarehouse] = usePermissions([userPermissions.warehouse.write]);
  const [isExport, setIsExport] = useState(true);

  return (
    <div className={style.container}>
      {canEditWarehouse && (
        <OptionSwitch
          defaultOption={isExport}
          onChange={setIsExport}
          falseLabel={'Import'}
          trueLabel={'Export'}
        />
      )}
      {isExport || !canEditWarehouse ? <Export closeModal={closeModal} /> : <Import closeModal={closeModal} />}
    </div>
  );
};

export default ImportExportForm;
