import { userPermissions } from 'src/constants/enums';

export const warehouseColumnsConfig = [
  {
    id: 'out_of_stock_quantity',
    name: 'Stan min.',
    width: '120px',
    order: 2,
    permissions: [],
    parameterName: 'out_of_stock_quantity'
  },
  {
    id: 'quantity',
    name: 'Ilość',
    width: '120px',
    order: 3,
    permissions: [],
    parameterName: 'quantity'
  },
  {
    id: 'price',
    name: 'Cena',
    width: '120px',
    order: 4,
    permissions: [userPermissions.orders.read],
    parameterName: 'price'
  },
  {
    id: 'value',
    name: 'Wartość',
    width: '120px',
    order: 5,
    permissions: [userPermissions.orders.read],
    parameterName: 'value'
  },
  {
    id: 'expire_date',
    name: 'Data ważności',
    width: '160px',
    order: 6,
    permissions: [],
    parameterName: 'expire_date'
  },
  {
    id: 'comment',
    name: 'Komentarz',
    width: '160px',
    order: 7,
    permissions: []
  },
  {
    id: 'tags',
    name: 'Tagi',
    width: 'minmax(290px, 1fr)',
    order: 8,
    permissions: []
  }
];

export const warehouseCheckboxColumnConfig = (checkbox = '') => ({
  id: 'checkbox',
  name: checkbox,
  width: '20px',
  order: 0,
  permissions: []
});

export const warehouseNameColumnConfig = {
  id: 'name',
  name: 'Nazwa',
  width: '1fr',
  order: 1,
  permissions: []
};
