import React, { useMemo } from 'react';
import classNames from 'classnames';

import { ReactComponent as BoxIcon } from 'assets/icons/box.svg';
import { CustomTooltip } from 'components';

import style from './CollectionInfo.module.scss';

const CollectionInfo = ({ className, collectionAmount, unitName, color = 'gray' }) => {
  const tooltipTitle = useMemo(() => {
    if (unitName === 'szt.') {
      return 'Produkt występuje w sztukach';
    }
    return `Produkt występuje w opakowaniach zbiorczych po ${collectionAmount} szt.`;
  }, [collectionAmount, unitName]);

  const classes = classNames(style.collectionInfo, className, {
    [style[color]]: color
  });

  return (
    <div className={classes}>
      <CustomTooltip
        title={tooltipTitle}
        placement={'top'}
      >
        <div className={style.inner}>
          <BoxIcon />
          <div className={style.amount}>
            {collectionAmount} {'szt.'}
          </div>
        </div>
      </CustomTooltip>
    </div>
  );
};

export default CollectionInfo;
