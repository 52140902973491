import React, { useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';

import { getMonthName } from 'src/api/utils';

import style from './ProductMonthVolumes.module.scss';

const formatVolumes = (volumes) => {
  const ocrs = [];
  const orders = [];
  const values = [];
  let average = null;

  if (volumes.length > 0) {
    volumes.forEach((volume) => {
      if (volume[2] === 'order') {
        orders.push({
          date: volume[0],
          amount: volume[1],
          type: volume[2]
        });
      } else if (volume[2] === 'ocr') {
        ocrs.push({
          date: volume[0],
          amount: volume[1],
          type: volume[2]
        });
      }
      values.push(volume[1]);
    });

    const sum = values.reduce((acc, curr) => acc + curr, 0);
    average = Math.ceil((sum * 12) / volumes.length);
  }

  return { ocrs, orders, average };
};

const ProductMonthVolumes = ({ volumes = [] }) => {
  const containerRef = useRef();

  const data = useMemo(() => {
    if (!volumes)
      return {
        ocrs: [],
        orders: []
      };
    return formatVolumes(volumes);
  }, [volumes]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollLeft = containerRef.current.scrollWidth;
    }
  }, [data]);

  if (!volumes?.length || !data) return <p className={style.empty}>Brak danych</p>;
  return (
    <div
      className={style.container}
      ref={containerRef}
    >
      {data?.ocrs?.length > 0 && (
        <div className={classNames(style.volumes, style.ocr)}>
          {data?.ocrs?.map((volume, i) => (
            <div
              key={i}
              className={style.monthItem}
            >
              <div>{getMonthName(volume?.date)}</div>
              <span>{volume?.amount}</span>
            </div>
          ))}
        </div>
      )}
      <div className={style.volumes}>
        {data?.orders?.map((volume, i) => (
          <div
            key={i}
            className={style.monthItem}
          >
            <div>{getMonthName(volume?.date)}</div>
            <span>{volume?.amount}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductMonthVolumes;
