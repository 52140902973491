import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import cn from 'classnames';

import {
  Button,
  FilterByCategory,
  FilterByProducer,
  FilterBySupplier,
  InfoWidget,
  Searchbar,
  Select,
  SwitchButton,
  useIsAdmin,
  useIsMobile
} from 'components';
import SortByOptions from 'components/layout/sorting/SortByOptions';
import { notifyApiError } from 'components/layout/Toasts';

import { OfferCatalogApi } from 'src/api';
import { getAllCategories, getParamsArray } from 'src/utils/helpers';

import { supplierOfferTypeOptions } from './options';

import style from './Filtering.module.scss';

const sortTopOptions = [
  { label: 'TOP 20 produktów pod względem wartości zamówień', value: 1, sort_by: 'VALUE', sort_order: 'DESC', top: 20 },
  { label: 'TOP 50 produktów pod względem wartości zamówień', value: 2, sort_by: 'VALUE', sort_order: 'DESC', top: 50 },
  { label: 'TOP 20 produktów pod względem zamówionych sztuk', value: 3, sort_by: 'QUANTITY', sort_order: 'DESC', top: 20 },
  { label: 'TOP 50 produktów pod względem zamówionych sztuk', value: 4, sort_by: 'QUANTITY', sort_order: 'DESC', top: 50 }
];

const sortOptions = [
  { label: 'Wartość zamówień (MAX)', value: 1, sort_by: 'VALUE', sort_order: 'DESC' },
  { label: 'Wartość zamówień (MIN)', value: 2, sort_by: 'VALUE', sort_order: 'ASC' },
  { label: 'Częstotliwość zamówień (MAX)', value: 3, sort_by: 'COUNT', sort_order: 'DESC' },
  { label: 'Częstotliwość zamówień (MIN)', value: 4, sort_by: 'COUNT', sort_order: 'ASC' },
  { label: 'Wolumen (MAX)', value: 5, sort_by: 'OFP_YEAR_VOLUME', sort_order: 'DESC' },
  { label: 'Wolumen (MIN)', value: 6, sort_by: 'OFP_YEAR_VOLUME', sort_order: 'ASC' },
  { label: 'Wartość per wolumem (MAX)', value: 7, sort_by: 'OFP_YEAR_VOLUME_F_SUP_VALUE', sort_order: 'DESC' },
  { label: 'Wartość per wolumem (MIN)', value: 8, sort_by: 'OFP_YEAR_VOLUME_F_SUP_VALUE', sort_order: 'ASC' }
];

const analyticsOptions = [
  { label: 'Produkty z ceną ref.', value: 5, type: 'only_with_ref_price' },
  { label: 'Produkty z wolumenem', value: 6, type: 'only_with_volume' },
  { label: 'Produkty z ceną ref. i wolumenem', value: 7, type: 'with_ref_and_volume' },
  { label: 'Produkty bez ceny ref. i wolumenu', value: 8, type: 'without_ref_and_volume' }
];

const Filtering = ({
  params,
  selectedCompany,
  onlyWithoutOffers,
  setOnlyWithoutOffers,
  showVolumesDetails,
  setShowVolumesDetails,
  isOfferCatalog = false
}) => {
  const { listUID } = useParams();
  const isAdmin = useIsAdmin();
  const isMobile = useIsMobile(1500);

  const [isCategoriesLoading, setIsCategoriesLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const categoryParam = params.get('category');
  const hasFilters =
    params.get('supplier') ||
    params.get('producer') ||
    params.get('category') ||
    params.get('preferred_supplier') ||
    params.get('search');

  const isOptionDisabled = (supplier) => supplier?.is_hidden;

  const formatCategory = (category, parentId = undefined) => {
    return {
      label: category.name,
      value: category.id,
      parent: parentId,
      children: category.children?.map((child) => formatCategory(child, category.id)) || []
    };
  };

  const getCategoryTree = async () => {
    const query = {
      ...(isAdmin && { company_id: selectedCompany })
    };
    try {
      setIsCategoriesLoading(true);
      const { data } = await OfferCatalogApi.getCategoryTree(query);
      const formattedCategories = data.map((category) => formatCategory(category));
      setCategories(getAllCategories(formattedCategories));
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsCategoriesLoading(false);
    }
  };

  useEffect(() => {
    getCategoryTree();
  }, [selectedCompany]);

  useEffect(() => {
    if (categories.length > 0) {
      const selectedCategoriesParam = getParamsArray(categoryParam);
      if (selectedCategoriesParam.length > 0) {
        const isEqual = selectedCategoriesParam?.every((id) => selectedCategories.includes(String(id)));
        if (!isEqual) {
          const selected = categories.filter((category) => selectedCategoriesParam.includes(String(category.value)));
          setSelectedCategories(selected);
        }
      } else if (selectedCategories) {
        setSelectedCategories([]);
      }
    }
  }, [categories, categoryParam]);

  const handleCategoryChange = (value) => {
    if (value) {
      const ids = value.map((category) => category.value);
      params.set('category', ids);
      setSelectedCategories(value);
    } else {
      params.remove('category');
      setSelectedCategories(null);
    }
  };

  const handlePreferredOfferChange = (value) => {
    if (value) {
      params.set('preferred_supplier', value);
    } else {
      params.remove('preferred_supplier');
    }
  };

  const clearFilters = () => {
    params.setFew([
      { key: 'supplier', value: null },
      { key: 'producer', value: null },
      { key: 'category', value: null },
      { key: 'preferred_supplier', value: null },
      { key: 'search', value: null },
      { key: 'page', value: 1 }
    ]);
  };

  const filters = (
    <>
      {isOfferCatalog ? (
        <Select
          options={categories}
          value={selectedCategories}
          onChange={handleCategoryChange}
          placeholder={'Kategoria'}
          isLoading={isCategoriesLoading}
          isClearable
          isMulti
        />
      ) : (
        <FilterByCategory
          params={params}
          queryParams={listUID ? { filter_by: 'LIST', list_uid: listUID } : undefined}
          fullWidth
          isMulti
        />
      )}
      {isOfferCatalog && (
        <FilterByProducer
          params={params}
          fullWidth
          isMulti
          syncWithParams
        />
      )}
      <FilterBySupplier
        params={params}
        queryParams={{ pagination: 1, ...(isAdmin && { company_id: selectedCompany }) }}
        isOptionDisabled={isOptionDisabled}
        disabledElements
        asUser
        fullWidth
        isMulti
        syncWithParams
      />
      {isOfferCatalog && (
        <div className={style.offerTypeWrapper}>
          <Select
            placeholder={'Typ oferty dostawcy'}
            options={supplierOfferTypeOptions}
            value={supplierOfferTypeOptions.find((option) => option.value === params.get('preferred_supplier')) || null}
            onChange={(option) => handlePreferredOfferChange(option?.value)}
            isClearable
          />
          <InfoWidget className={style.tooltip}>
            Zaznacz dostawców i wybierz typ oferty, aby wyświetlić produkty, posiadające podaną ofertę dla tych dostawców.
          </InfoWidget>
        </div>
      )}
    </>
  );

  return (
    <>
      <div className={cn(style.container, { [style.fromList]: listUID })}>
        <div className={style.topWrapper}>
          <Searchbar
            wrapperStyle={style.searchWrapper}
            params={params}
            syncWithParams
            fullWidth
            filters={
              (isMobile || !isOfferCatalog) && (
                <>
                  <SortByOptions
                    params={params}
                    placeholder={'Sortowanie'}
                    clearParamsKeys={['sort_by', 'sort_order', 'top']}
                    options={sortOptions}
                    wrapperStyle={style.sortSelect}
                  />
                  <SortByOptions
                    params={params}
                    placeholder={'TOP produkty'}
                    clearParamsKeys={['top']}
                    options={sortTopOptions}
                    wrapperStyle={style.sortSelect}
                  />
                  <SortByOptions
                    params={params}
                    placeholder={'Analityka'}
                    options={analyticsOptions}
                    clearParamsKeys={['type']}
                    wrapperStyle={style.sortSelect}
                  />
                  <span className={style.divider} />
                  {filters}
                </>
              )
            }
          />
          {hasFilters && (
            <Button
              className={style.clearFilters}
              label={'Wyczyść filtry'}
              onClick={clearFilters}
              gray
            />
          )}
        </div>

        {!isMobile && isOfferCatalog && (
          <div className={style.filters}>
            <SortByOptions
              params={params}
              placeholder={'Sortowanie'}
              clearParamsKeys={['sort_by', 'sort_order', 'top']}
              options={sortOptions}
              wrapperStyle={style.sortSelect}
            />
            <SortByOptions
              params={params}
              placeholder={'TOP produkty'}
              clearParamsKeys={['top']}
              options={sortTopOptions}
              wrapperStyle={style.sortSelect}
            />
            <SortByOptions
              params={params}
              placeholder={'Analityka'}
              options={analyticsOptions}
              clearParamsKeys={['type']}
              wrapperStyle={style.sortSelect}
            />
            {filters}
          </div>
        )}
      </div>
      <div className={style.switches}>
        <SwitchButton
          value={onlyWithoutOffers}
          setValue={setOnlyWithoutOffers}
          label={'Pokaż produkty bez ofert'}
        />
        {isAdmin && isOfferCatalog && (
          <SwitchButton
            value={showVolumesDetails}
            setValue={setShowVolumesDetails}
            label={'Pokaż wolumeny'}
          />
        )}
      </div>
    </>
  );
};

export default Filtering;
