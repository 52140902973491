import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Popover } from 'antd';
import classNames from 'classnames';
import { useAtom } from 'jotai';

import { ReactComponent as InvoiceIcon } from 'assets/icons/newSidebar/file-invoice.svg';
import {
  Button,
  ChangeValue,
  Icon,
  Menu,
  Modal,
  OrderOrValuationId,
  useIsAdmin,
  useIsMobile,
  useModalConfirm,
  usePermissions,
  useValidator,
  useWarehouse
} from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { WarehouseApi } from 'src/api';
import { userPermissions } from 'src/constants/enums';
import { tabletVerticalWidth } from 'src/constants/responsive';
import { selectedCompanyAtom } from 'src/features/Warehouse';
import { versionAtom } from 'src/features/Warehouse';
import { toDateNumber } from 'src/utils/dateTime';
import { getFormattedAmount, getSupplyTypeTag } from 'src/utils/helpers';

import MoveProductForm from '../../../../../MoveProductForm';
import ProductReleaseFromSupply from '../../../../../ProductReleaseFromSupply';

import style from './SingleSupply.module.scss';

const SingleSupply = ({ supply, refreshSupplies, refreshProductsList, product, subwarehouseId }) => {
  const [version, setVersion] = useAtom(versionAtom);
  const [canViewOrders] = usePermissions([userPermissions.orders.read]);
  const [canEditWarehouse] = usePermissions([userPermissions.warehouse.write]);
  const [canViewInvoices] = usePermissions([userPermissions.invoices.read]);
  const [selectedCompany] = useAtom(selectedCompanyAtom);

  const {
    id,
    current_quantity,
    expire_date,
    order_uid,
    price,
    quantity,
    order_public_id,
    order_invoices = [],
    supply_date,
    type,
    subwarehouse_name,
    subwarehouse_id,
    value
  } = supply;
  const isMobile = useIsMobile(tabletVerticalWidth);
  const isAdmin = useIsAdmin();
  const { subwarehouses } = useWarehouse();

  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();
  const [isMoveModalOpen, setIsMoveModalOpen] = useState(false);
  const [isReleaseFromSupplyOpen, setIsReleaseFromSupplyOpen] = useState(false);

  const validator = useValidator();

  const changePriceHandler = async (newPrice) => {
    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const params = {
      supply_id: id,
      price: newPrice
    };

    try {
      const { data } = await WarehouseApi.updateSupplyPrice(params);
      setVersion(version + 1);
      if (refreshSupplies) refreshSupplies(1, true);
      if (refreshProductsList) refreshProductsList();
      notifyCommon([data.message]);
    } catch (err) {
      notifyApiError([err.message]);
    }
  };

  const revokeSupply = async () => {
    try {
      await WarehouseApi.revokeSupply({ supply_id: id });
      if (refreshSupplies) refreshSupplies(1, true);
      if (refreshProductsList) refreshProductsList();
    } catch (err) {
      notifyApiError(err);
    }
  };

  const revokeSupplyConfirm = () => {
    handleOpenModalConfirm({
      message: 'Na pewno chcesz cofnąć dostawę tego produktu?',
      handleConfirm: revokeSupply,
      content: (
        <p>
          Cofnięta zostanie dostawa z dnia <strong>{toDateNumber(supply_date)}</strong> z ilością{' '}
          <strong>
            {current_quantity} {product?.unit_name || 'szt.'}
          </strong>
          .
        </p>
      )
    });
  };

  const invoicesPopoverElement = useMemo(() => {
    if (!canViewInvoices || order_invoices.length === 0) return null;
    return (
      <Popover
        content={
          <div className={style.orderInvoices}>
            {order_invoices.map((invoice, i) => (
              <Link
                key={`invoice-${invoice.id}-${i}`}
                to={`/invoices?search=${invoice.number}`}
              >
                {invoice.number}
              </Link>
            ))}
          </div>
        }
        placement={'bottomRight'}
        trigger={'click'}
      >
        <button className={style.invoiceButton}>
          <InvoiceIcon />
        </button>
      </Popover>
    );
  }, [canViewInvoices, order_invoices]);

  const actions = useMemo(
    () => [
      {
        title: 'Cofnij dostawę',
        icon: 'replacement',
        action: revokeSupplyConfirm,
        hidden: !canEditWarehouse
      },
      {
        title: 'Przesunięcie magazynowe',
        icon: 'move',
        action: () => setIsMoveModalOpen(true),
        hidden: !(canEditWarehouse && current_quantity > 0 && subwarehouses.length > 0)
      },

      {
        title: 'Wydanie z dostawy',
        icon: 'minus',
        action: () => setIsReleaseFromSupplyOpen(true),
        hidden: !(canEditWarehouse && current_quantity > 0)
      }
    ],
    [current_quantity, subwarehouses, canEditWarehouse]
  );

  return (
    <div
      className={classNames(style.container, {
        [style.hidePrices]: !canViewOrders,
        [style.hideSubwarehouse]: !subwarehouses.length
      })}
    >
      <div className={style.type}>
        {isMobile && <p>Typ dostawy:</p>}
        {getSupplyTypeTag({ type, className: style.tag })}
      </div>
      <div className={style.date}>
        {isMobile && <p>Data:</p>}
        {toDateNumber(supply_date)}
      </div>
      <div className={style.quantity}>
        {isMobile && <p>Dodana ilość:</p>}
        {quantity} {'szt.'}
      </div>
      <div className={style.inWarehouse}>
        {isMobile && <p>W magazynie:</p>}
        {current_quantity} {'szt.'}
      </div>
      {subwarehouses.length > 0 && (
        <div className={style.subwarehouse}>
          {isMobile && <p>Magazyn:</p>}
          {subwarehouse_name || 'Magazyn główny'}
        </div>
      )}
      {canViewOrders && (
        <div className={style.price}>
          {isMobile && <p>Cena za szt.:</p>}
          {isAdmin ? (
            <ChangeValue
              apiCallback={changePriceHandler}
              initialValue={price}
              type={'number'}
              label={'Zmień cenę.'}
              placement={'bottom'}
              unit={'zł'}
              hideArrows
              icon={
                <Icon
                  name={'edit'}
                  fill={'#888'}
                />
              }
            />
          ) : (
            getFormattedAmount(price)
          )}
        </div>
      )}
      {canViewOrders && (
        <div className={style.value}>
          {isMobile && <p>Wartość:</p>}
          {getFormattedAmount(value)}
        </div>
      )}
      <div className={style.expire}>
        {isMobile && <p>Data ważności:</p>}
        {expire_date ? toDateNumber(expire_date) : '-'}
      </div>
      <div className={style.order}>
        {isMobile && <p>Zamówienia:</p>}
        {order_public_id ? (
          <Link to={`/orders/${order_uid}`}>
            <OrderOrValuationId id={order_public_id} />
          </Link>
        ) : (
          '-'
        )}
      </div>

      <div className={style.actions}>
        {invoicesPopoverElement}
        <Menu
          actions={actions}
          className={style.menu}
          disabled={actions.every((item) => item.hidden)}
        />
      </div>

      {renderedModalConfirm}
      <Modal
        title='Przenieś dostawę produktu'
        visible={isMoveModalOpen}
        onClose={() => setIsMoveModalOpen(false)}
      >
        <MoveProductForm
          product={product}
          maxQuantityToMove={current_quantity}
          subwarehouseId={subwarehouse_id || -1}
          apiCallback={WarehouseApi.moveProductFromSupplyBetweenSubWarehouses}
          apiPayloadBase={{
            supply_id: id
          }}
          onMove={() => {
            setIsMoveModalOpen(false);
            if (refreshSupplies) refreshSupplies(1, true);
            if (refreshProductsList) refreshProductsList();
          }}
        />
      </Modal>
      <Modal
        title='Wydanie z dostawy'
        visible={isReleaseFromSupplyOpen}
        onClose={() => setIsReleaseFromSupplyOpen(false)}
      >
        <ProductReleaseFromSupply
          product={product}
          supply={supply}
          selectedCompany={selectedCompany}
          onRelease={() => {
            setIsReleaseFromSupplyOpen(false);
            if (refreshSupplies) refreshSupplies(1, true);
            if (refreshProductsList) refreshProductsList();
          }}
        />
      </Modal>
    </div>
  );
};

export default SingleSupply;
