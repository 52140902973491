import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import {
  Button,
  EmptyState,
  FilterByCompany,
  LoaderGlobal,
  PageHeader,
  PaginationNav,
  ProcessStepper,
  SeoHelmet,
  useCompany,
  useIsAdmin,
  useIsMobile,
  usePermissions,
  useRequestAbortController,
  useSearchParam
} from 'components';
import { headerTextPlaceholder } from 'components/layout/PageHeader/placeholders';
import { notifyApiError } from 'components/layout/Toasts';

import { ListsApi, OfferCatalogApi } from 'src/api';
import { OfferCatalogTypes, userPermissions } from 'src/constants/enums';
import { tabletHorizontalWidth } from 'src/constants/responsive';
import { orderProcessSteps } from 'src/constants/steps';
import pages from 'src/dictionaries/pages.json';
import {
  getCategoriesAfterSelectionChange,
  getCorrectFormOfResultsLabel,
  getParamsArray,
  getProducerColumns
} from 'src/utils/helpers';
import query from 'src/utils/query';

import ActiveFilters from './components/ActiveFilters';
import CatalogManagement from './components/CatalogManagement';
import ExportAllCatalogsSuppliersOffers from './components/ExportAllCatalogsSuppliersOffers';
import Filtering from './components/Filtering';
import HeaderContent from './components/HeaderContent';
import ProductsList from './components/ProductsList';
import ProductsWithoutPrice from './components/ProductsWithoutPrice';
import SelectionSummary from './components/SelectionSummary';
import { changeSelectedCompany, changeViewType } from './actions';

import 'react-alice-carousel/lib/scss/alice-carousel.scss';
import style from './CatalogAndCartController.module.scss';

const getIsDefinedView = (company, pageData) => company?.offer_catalog_for_corp || pageData?.offer_catalog_for_corp;

const CatalogAndCartController = (props) => {
  const { type = OfferCatalogTypes.catalog } = props;

  const params = query(props);
  const company = useCompany();
  const isAdmin = useIsAdmin();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { listUID } = useParams();
  const wasViewTypeUpdated = useRef(false);
  const clearSelection = useRef(!!location?.state?.clearSelection);
  const isTabletHorizontal = useIsMobile(tabletHorizontalWidth);

  const topParam = useSearchParam(params, 'top');
  const sortBy = useSearchParam(params, 'sort_by');
  const currentPage = useSearchParam(params, 'page');
  const querySearch = useSearchParam(params, 'search');
  const sortOrder = useSearchParam(params, 'sort_order');
  const selectedCompany = useSearchParam(params, 'company');
  const selectedProducers = useSearchParam(params, 'producer');
  const selectedSuppliers = useSearchParam(params, 'supplier');
  const selectedCategories = useSearchParam(params, 'category');
  const selectedType = useSearchParam(params, 'type');
  const preferredSupplier = useSearchParam(params, 'preferred_supplier');

  const [onlyWithoutOffers, setOnlyWithoutOffers] = useState(false);
  const [showVolumesDetails, setShowVolumesDetails] = useState(false);
  const [scrollPage, setScrollPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [columns, setColumns] = useState([]);
  const [pageData, setPageData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showRefPrice, setShowRefPrice] = useState(false);
  const [isQuietLoading, setIsQuietLoading] = useState(false);

  const isScrollPagination = isTabletHorizontal && !topParam;
  const [abortController, setNewController] = useRequestAbortController();
  const version = useSelector((state) => state.cartAndCatalog.version);
  const isDefinedView = useSelector((state) => state.cartAndCatalog.isDefinedView);
  const isCatalogView = useMemo(() => type === OfferCatalogTypes.catalog, [type]);
  const [canMenageCatalog] = usePermissions([userPermissions.offer_catalog.menage]);

  const getDataHandler = async (Api, signal, reset) => {
    const topPagination = { page: 1, perPage: +topParam };
    const pagination = { page: isScrollPagination ? scrollPage || 1 : currentPage || 1, perPage: perPage };

    const queryParams = {
      ...(sortBy && { sort_by: sortBy }),
      ...(listUID && { list_uid: listUID }),
      ...(topParam ? topPagination : pagination),
      ...(!!querySearch && { search: querySearch.toString() }),
      ...(sortOrder && { sort_order: sortOrder }),
      ...(isAdmin && { company_id: selectedCompany }),
      ...(selectedProducers && { producer_ids: getParamsArray(selectedProducers) }),
      ...(isDefinedView && { get_all_product_offers: +isDefinedView }),
      ...(selectedSuppliers && !preferredSupplier && { supplier_ids: getParamsArray(selectedSuppliers) }),
      ...(selectedCategories && { category_ids: getParamsArray(selectedCategories) }),
      ...(selectedType && { [selectedType]: 1 }),
      ...(onlyWithoutOffers && { only_without_offers: 1 }),
      ...(showVolumesDetails && { show_volumes_details: 1 }),
      ...(preferredSupplier && { preferred_supplier: preferredSupplier }),
      ...(selectedSuppliers && preferredSupplier && { preferred_supplier_ids: getParamsArray(selectedSuppliers) })
    };

    const { data: response } = await Api.getData(queryParams, signal);
    const { data, total, last_page } = response;

    if (topParam) {
      setPageData({ ...data, total: Math.min(total, +topParam), last_page: 1 });
    } else if (isScrollPagination && !reset) {
      setPageData((prev) => {
        const filterProductDuplicatesById = (products) => {
          const ids = new Set();
          return products.filter((product) => {
            if (ids.has(product.product_id)) return false;
            ids.add(product.product_id);
            return true;
          });
        };
        const newProducts = filterProductDuplicatesById([...(prev?.products || []), ...data.products]);

        return {
          ...data,
          total,
          last_page,
          ...(isScrollPagination && { products: newProducts })
        };
      });
    } else {
      if (reset && !scrollPage) setScrollPage(1);
      setPageData({
        ...data,
        total,
        last_page
      });
    }
  };

  const clearSelectionHandler = async (Api) => {
    if (abortController) abortController.abort();
    const signal = setNewController();

    const queryData = {
      list_uid: listUID,
      ...(isAdmin && { company_id: selectedCompany })
    };

    const apiCallback = isDefinedView ? Api.selectPreferred : Api.removeAllSelects;

    try {
      await apiCallback(queryData, signal);
      clearSelection.current = false;
    } catch (err) {
      notifyApiError(err);
    }
  };

  const refreshData = async (reset = true) => {
    const Api = isCatalogView ? OfferCatalogApi : ListsApi;
    if (isAdmin && !selectedCompany) return null;

    if (abortController) abortController.abort();
    const signal = setNewController();

    try {
      setIsQuietLoading(true);
      if (!isCatalogView && clearSelection.current) clearSelectionHandler(Api);
      await getDataHandler(Api, signal, reset);
    } catch (err) {
      notifyApiError(err);
      if (err?.message !== 'canceled') history.push('/dashboard');
    } finally {
      setIsQuietLoading(false);
    }
  };

  const scrollPaginationReset = () => {
    if (scrollPage !== 1) {
      // infinite loop prevention
      setScrollPage(null);
    }
  };

  const changeValuationSelections = (data) => {
    setPageData((prev) => ({
      ...prev,
      products: getCategoriesAfterSelectionChange(data, prev?.products)
    }));
  };

  const selectCompanyHandler = (value) => {
    dispatch(changeSelectedCompany(value));
    params.set('company', value);
  };

  useEffect(() => {
    dispatch(changeSelectedCompany(selectedCompany));
  }, [selectedCompany]);

  useEffect(() => {
    if (!wasViewTypeUpdated.current) {
      const isDefined = getIsDefinedView(company, pageData);
      if (isDefined !== undefined) dispatch(changeViewType(isDefined));
      wasViewTypeUpdated.current = true;
    }

    if (pageData) {
      setColumns(getProducerColumns(pageData));
    }
  }, [company, pageData]);

  useEffect(() => {
    const refreshDataHandler = async () => {
      try {
        setIsLoading(true);
        await refreshData();
      } catch (err) {
        notifyApiError(err);
      } finally {
        setIsLoading(false);
      }
    };

    refreshDataHandler();
  }, [type, selectedCompany]);

  useEffect(() => {
    if (version > 0) {
      scrollPaginationReset();
      refreshData();
    }
  }, [version]);

  useEffect(() => {
    if (isScrollPagination) {
      scrollPaginationReset();
    }
    refreshData();
  }, [
    type,
    sortBy,
    perPage,
    sortOrder,
    querySearch,
    currentPage,
    isDefinedView,
    selectedCompany,
    selectedProducers,
    selectedSuppliers,
    selectedCategories,
    selectedType,
    onlyWithoutOffers,
    showVolumesDetails,
    preferredSupplier
  ]);

  useEffect(() => {
    // scrollPage !== null infinite loop prevention
    if (isScrollPagination && scrollPage !== null) {
      refreshData(false);
    }
  }, [scrollPage]);

  useEffect(() => {
    setShowRefPrice(location.pathname === '/offer-catalog/analytics');
  }, [location.pathname]);

  const pageText = useMemo(
    () =>
      isQuietLoading
        ? headerTextPlaceholder()
        : listUID
        ? pageData?.list_title
          ? pageData?.list_title
          : 'Na podstawie listy'
        : getCorrectFormOfResultsLabel(pageData?.total || 0, ['produkt', 'produkty', 'produktów']),
    [isQuietLoading, listUID, pageData]
  );

  const pagination = (
    <PaginationNav
      className={style.pagination}
      params={params}
      pagesQty={pageData?.last_page || 0}
      setPerPage={setPerPage}
      defaultQty={perPage}
      hidePadding
    />
  );

  if (isLoading) {
    return (
      <>
        <div className={style.container}>
          <PageHeader
            textMaxWidth
            text={pageText}
            name={pages.offerCatalog[type].title}
          />
          {!isCatalogView && (
            <ProcessStepper
              steps={orderProcessSteps}
              current={1}
            />
          )}
        </div>
        <LoaderGlobal />
      </>
    );
  }

  if (isAdmin && !selectedCompany) {
    return (
      <>
        <PageHeader
          name={pages.offerCatalog[type].title}
          text={'Wybierz firmę'}
        />
        <div className={style.centerContent}>
          <EmptyState type={'selectCompany'} />
          <FilterByCompany
            onChange={selectCompanyHandler}
            value={selectedCompany}
          />
          <ExportAllCatalogsSuppliersOffers className={style.exportButton} />
        </div>
      </>
    );
  }

  if (!pageData) {
    return null;
  }

  return (
    <div className={style.container}>
      <SeoHelmet title={pages.offerCatalog[type].title} />
      <PageHeader
        textMaxWidth
        text={pageText}
        name={pages.offerCatalog[type].title}
        breakpointBelowTablet
      >
        <HeaderContent
          params={params}
          isLoading={isLoading}
          showRefPrice={showRefPrice}
          isCatalogView={isCatalogView}
          setShowRefPrice={setShowRefPrice}
          selectedCompany={selectedCompany}
        />
      </PageHeader>
      {!isCatalogView && (
        <ProcessStepper
          steps={orderProcessSteps}
          current={1}
        />
      )}
      <div className={style.contentWrapper}>
        {isCatalogView && canMenageCatalog && <CatalogManagement />}
        {!isCatalogView && (
          <>
            <SelectionSummary
              columns={columns}
              pageData={pageData}
              showRefPrice={showRefPrice}
              isCatalogView={isCatalogView}
              selectedCompany={selectedCompany}
            />
            <ProductsWithoutPrice
              listUID={listUID}
              isCatalogView={isCatalogView}
              selectedCompany={selectedCompany}
            />
          </>
        )}
        {!isTabletHorizontal && pagination}
        <Filtering
          params={params}
          selectedCompany={selectedCompany}
          onlyWithoutOffers={onlyWithoutOffers}
          setOnlyWithoutOffers={setOnlyWithoutOffers}
          showVolumesDetails={showVolumesDetails}
          setShowVolumesDetails={setShowVolumesDetails}
          isOfferCatalog={type === OfferCatalogTypes.catalog}
        />
        <ActiveFilters params={params} />
        <ProductsList
          params={params}
          status={pageData?.status}
          showRefPrice={showRefPrice}
          isCatalogView={isCatalogView}
          isMonthVolumesView={isAdmin && showVolumesDetails}
          productClasses={style.product}
          isQuietLoading={isQuietLoading}
          products={pageData?.products}
          selectedCompany={selectedCompany}
          changeValuationSelections={changeValuationSelections}
          pageData={pageData}
          scrollPagination={isScrollPagination}
          scrollPage={scrollPage || 1}
          setScrollPage={setScrollPage}
        />
        {!isTabletHorizontal && pagination}
      </div>
    </div>
  );
};

export default CatalogAndCartController;
