import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { useCompany, useIsAdmin } from 'components';
import ChangePrice from 'components/layout/changeMenu/ChangePrice';
import ChangeQuantity from 'components/layout/changeMenu/ChangeQuantity';
import { notifyApiError } from 'components/layout/Toasts';

import { OfferCatalogApi } from 'src/api';
import { getFormattedAmount } from 'src/utils/helpers';

import { refreshCartOrCatalog } from '../../actions';

import style from './RefPrice.module.scss';

const RefPrice = ({ price, quantity = 1, className, showRefPrice, selectedCompany, productId, product }) => {
  const isAdmin = useIsAdmin();
  const dispatch = useDispatch();
  const company = useCompany();
  const [isQtyActive, setIsQtyActive] = useState();
  const [isRefActive, setIsRefActive] = useState(false);

  const { month_volume, prod_volume_value, unit_name, prices } = product;

  const preferredOffer = useMemo(() => {
    return prices?.find((price) => price?.priority === 'first') || null;
  }, [product?.prices]);

  if (!showRefPrice) {
    return null;
  }

  const addPrice = async (newPrice) => {
    const queryData = {
      company_id: selectedCompany,
      product_id: productId,
      type: 'ref_price',
      price: +newPrice
    };

    try {
      await OfferCatalogApi.addPrice(queryData);
      dispatch(refreshCartOrCatalog());
    } catch (err) {
      notifyApiError(err);
    }
  };

  const addVolume = async (newVolume) => {
    const queryData = {
      ...(isAdmin && { company_id: selectedCompany }),
      product_id: productId,
      volume: newVolume
    };

    try {
      await OfferCatalogApi.addVolume(queryData);
      dispatch(refreshCartOrCatalog());
    } catch (err) {
      notifyApiError(err);
    }
  };

  const savings = () => {
    const primaryPrice = prices.find((price) => price.priority === 'first');
    if (primaryPrice && price) {
      const value = isAdmin
        ? Math.max(Math.max(price * quantity) - Math.max(primaryPrice.price, 0))
        : Math.max(Math.max(price * quantity, 0) - Math.max(primaryPrice.price, 0), 0);

      return <h4 className={classNames(style.value, { [style.isNegative]: value < 0 })}>{getFormattedAmount(value)}</h4>;
    } else {
      return 'Brak';
    }
  };

  return (
    <div className={classNames(style.container, className)}>
      <div className={style.box}>
        <p className={style.label}>Wartość:</p>
        <span className={style.value}>{prod_volume_value ? getFormattedAmount(prod_volume_value) : 'Brak'}</span>
      </div>
      <div className={style.box}>
        <p className={style.label}>Wolumen:</p>
        {isAdmin ? (
          <ChangeQuantity
            tagClassName={style.tag}
            quantity={month_volume || 0}
            min={month_volume || 0}
            unit={unit_name}
            setQuantity={addVolume}
            isActive={isQtyActive}
            displayValueFormatter={() =>
              month_volume === null
                ? 'Brak'
                : +month_volume === 0
                ? `0 ${unit_name}`
                : +month_volume > 0
                ? `${month_volume} ${unit_name}`
                : ''
            }
            setIsActive={setIsQtyActive}
            isOfferCatalogVolumeChange
            productId={productId}
            companyId={isAdmin ? selectedCompany : company.id}
          />
        ) : (
          <span className={style.value}>{!month_volume && month_volume !== 0 ? 'Brak' : `${month_volume} ${unit_name}`}</span>
        )}
      </div>
      <div className={style.box}>
        <p className={style.label}>Cena ref.:</p>
        {isAdmin ? (
          <ChangePrice
            tagClassName={style.tag}
            price={price}
            setPrice={addPrice}
            isActive={isRefActive}
            setIsActive={setIsRefActive}
            displayValueFormatter={() => (!price || +price === 0 ? 'Brak' : getFormattedAmount(price * quantity))}
            label={'Cena za szt.'}
          />
        ) : (
          <h4 className={style.value}>{!price || +price === 0 ? 'Brak' : getFormattedAmount(price * quantity)}</h4>
        )}
      </div>
      <div className={style.box}>
        <p className={style.label}>Oszczędność:</p>
        {savings()}
      </div>
    </div>
  );
};

export default RefPrice;
