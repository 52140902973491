import React, { useState } from 'react';
import { useAtom } from 'jotai';

import { Button, FilterBySubwarehouse, QtySelector, useIsAdmin, useValidator } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { selectedCompanyAtom } from '../..';

import style from './MoveProductForm.module.scss';

const MoveProductForm = ({ product, apiCallback, apiPayloadBase = {}, onMove, subwarehouseId, maxQuantityToMove }) => {
  const validator = useValidator();
  const isAdmin = useIsAdmin();

  const [selectedCompany] = useAtom(selectedCompanyAtom);
  const [selectedSubwarehouseId, setSelectedSubwarehouseId] = useState(null);
  const [quantity, setQuantity] = useState(maxQuantityToMove || 1);
  const [isLoading, setIsLoading] = useState(false);

  const handleMoveProduct = async () => {
    if (!validator.allValid()) {
      validator.showMessages();
      return;
    }

    const payload = {
      ...apiPayloadBase,
      quantity,
      to_subwarehouse_id: selectedSubwarehouseId,
      ...(isAdmin && { company_id: selectedCompany })
    };

    try {
      setIsLoading(true);
      await apiCallback(payload);
      if (onMove) onMove();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={style.wrapper}>
      <div className={style.row}>
        <FilterBySubwarehouse
          id={'move-product-target'}
          name={'move-product-target'}
          label={'Przenieś do'}
          placeholder={'Gdzie chcesz przenieść produkt?'}
          onChange={setSelectedSubwarehouseId}
          value={selectedSubwarehouseId}
          fullWidth
          filterSubwarehouseIds={[subwarehouseId]}
          selectedCompany={selectedCompany}
          validator={validator}
          rule={'required'}
        />
        <QtySelector
          label={'Przenieś ilość'}
          id={'move-product-qty'}
          name={'move-product-qty'}
          state={quantity}
          setState={setQuantity}
          suffix={product.unit_name}
          max={maxQuantityToMove}
          validator={validator}
          rule={`required|not_negative|max:${maxQuantityToMove},num`}
        />
      </div>
      <Button
        label={'Przenieś'}
        onClick={handleMoveProduct}
        isLoading={isLoading}
        disabled={quantity <= 0}
      />
    </div>
  );
};

export default MoveProductForm;
