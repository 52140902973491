import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';

import { Accordion, OrderProductThumbnail, useIsAdmin, useWindowSize } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { SupplierApi } from 'src/api';
import { orderProductTypes } from 'src/constants/enums';
import { stringToBase64 } from 'src/utils/helpers';

import style from '../../SingleCart.module.scss';
import shared from 'styles/Shared.module.scss';

const ProductsList = ({ products, history, supplierId, suborderUid, refreshDataCallback, notifications }) => {
  const windowSize = useWindowSize();
  const title = <h3 className={classNames(shared.tableCategoryTitle, shared.large, shared.noPadding)}>Lista produktów</h3>;
  const isAdmin = useIsAdmin();

  const getProductPricesChange = useCallback(
    (id) => {
      const events = history.filter((e) => ['PRODUCT_PRICE_CHANGED', 'PRODUCT_PRICE_CHANGED_BY_ADMIN'].includes(e.event));

      if (events.length > 0) {
        const eventsData = events.map((event) => {
          const { order_product_id, old_price } = JSON.parse(event.value);
          return {
            prevPrice: old_price,
            productId: order_product_id,
            date: event.created_at
          };
        });
        return eventsData?.find((e) => e.productId === id);
      } else {
        return null;
      }
    },
    [history, products]
  );

  const hasTags = useMemo(() => {
    return products?.some((p) => p.tags?.length > 0);
  }, [products]);

  const handleChangePrice = async (price, id) => {
    const query = {
      uid: suborderUid,
      products: [{ id, price }]
    };

    try {
      await SupplierApi.acceptNewPrice(query);
      notifyCommon(['Cena produktu została zmieniona.']);
      refreshDataCallback();
    } catch (err) {
      notifyApiError(err);
    }
  };

  return (
    <Accordion
      section
      title={title}
      id={`suborder-list-${stringToBase64(supplierId)}`}
      extraDeps={[products, notifications, history, windowSize.width]}
    >
      <div
        className={classNames(shared.tableHeader, style.header, {
          [style.withTags]: hasTags
        })}
      >
        <p>Lp.</p>
        <p>Nazwa</p>
        <p>Producent</p>
        <p>Ilość</p>
        <p>Cena za szt.</p>
        <p>Wartość</p>
        {hasTags && <p className={style.tagsTitle}>Tagi</p>}
        <p className={style.commentTitle}>Komentarz</p>
        <p>Status</p>
      </div>
      {products.map((product, index) => (
        <OrderProductThumbnail
          supplierId={supplierId}
          notifications={notifications}
          index={index + 1}
          type={orderProductTypes.user}
          key={product.id}
          product={product}
          addedToWarehouse={product?.added_to_warehouse || product?.warehouse_quantity > 0}
          priceChangeData={getProductPricesChange(product.id)}
          onPriceChange={isAdmin ? (price) => handleChangePrice(price, product.id) : undefined}
          showTags={hasTags}
          tabletVerticalBreakpoint
          refreshData={refreshDataCallback}
        />
      ))}
    </Accordion>
  );
};

export default ProductsList;
