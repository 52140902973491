import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { Select, useDepartments } from 'components';

import { getDepartments } from 'src/features/Departments/actions';
import { compareArrays } from 'src/utils/helpers';

import style from '../Filtering.module.scss';

const departmentToOption = (department) => {
  const { id, name } = department;
  return { label: name, value: Number(id) };
};

const FilterByDepartment = ({
  label,
  placeholder,
  params,
  isMulti,
  isSearchable = false,
  value,
  onChange,
  selectedCompany,
  validator,
  rule,
  fullWidth
}) => {
  const [selectedDepartment, setSelectedDepartment] = useState(isMulti ? [] : null);
  const dispatch = useDispatch();
  const myDepartments = useDepartments();

  const paramValue = params?.get('department') || value;

  const departmentOptions = useMemo(() => {
    return myDepartments.departments.map((item) => departmentToOption(item)) || [];
  }, [myDepartments.departments]);

  const setSingleValue = (department) => {
    const areValueTheSame = department?.value === +paramValue;

    if (onChange) {
      onChange(department?.value);
      return;
    }

    if (!areValueTheSame) {
      if (params.get('page')) {
        params.setFew([
          { key: 'department', value: department?.value },
          { key: 'page', value: '1' }
        ]);
      } else {
        params.set('department', department?.value);
      }
    }
  };

  const setMultiValue = (departments) => {
    const idsArray = departments?.map((sup) => sup.value);
    const areArrayTheSame = compareArrays(idsArray, paramValue);

    if (onChange) {
      onChange(idsArray);
      return;
    }

    if (!areArrayTheSame) {
      if (params.get('page')) {
        params.setFew([
          { key: 'department', value: idsArray },
          { key: 'page', value: '1' }
        ]);
      } else {
        params.set('department', idsArray);
      }
    }
  };

  useEffect(() => {
    if (paramValue) {
      if (isMulti) {
        const selected = departmentOptions.filter((option) => paramValue.includes(String(option.value)));
        setSelectedDepartment(selected);
      } else {
        const selected = departmentOptions.find((option) => option.value === +paramValue);
        setSelectedDepartment(selected);
      }
    }
  }, [departmentOptions]);

  useEffect(() => {
    if (!myDepartments.departments.length) {
      dispatch(getDepartments(selectedCompany));
    }
  }, [selectedCompany]);

  const onChangeHandler = (e) => {
    setSelectedDepartment(e);
    if (isMulti) {
      setMultiValue(e);
    } else {
      setSingleValue(e);
    }
  };

  return (
    <Select
      value={selectedDepartment}
      onChange={onChangeHandler}
      label={label}
      placeholder={placeholder || 'Jednostka organizacyjna'}
      options={departmentOptions}
      wrapperStyle={classNames(style.departmentSelect, { [style.fullWidth]: fullWidth })}
      isClearable
      isSearchable={isSearchable}
      isMulti={isMulti}
      validator={validator}
      rule={rule}
    />
  );
};

export default FilterByDepartment;
