import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { AsyncSelect } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { ProductsApi } from 'src/api';
import { compareArrays, formatProducerValue } from 'src/utils/helpers';

import style from '../Filtering.module.scss';

const FilterByProducer = ({
  params,
  fullWidth,
  paramKey = 'producer',
  valueKey = 'id',
  labelKey = 'producer_name',
  label,
  queryParams,
  isMulti,
  syncWithParams
}) => {
  const [isInitiallyLoaded, setIsInitiallyLoaded] = useState(false);
  const [selectedProducer, setSelectedProducer] = useState(isMulti ? [] : null);

  const paramValue = params.get(paramKey);

  const setSingleValue = (producer) => {
    const areValueTheSame = producer?.value === paramValue;

    if (!areValueTheSame) {
      if (params.get('page')) {
        params.setFew([
          { key: paramKey, value: producer?.value },
          { key: 'page', value: '1' }
        ]);
      } else {
        params.set(paramKey, producer?.value);
      }
    }
  };

  const setMultiValue = (producers) => {
    const idsArray = producers?.map((prod) => prod.value);
    const areArrayTheSame = compareArrays(idsArray, paramValue);

    if (!areArrayTheSame) {
      if (params.get('page')) {
        params.setFew([
          { key: paramKey, value: idsArray },
          { key: 'page', value: '1' }
        ]);
      } else {
        params.set(paramKey, idsArray);
      }
    }
  };

  const onChangeHandler = (producer) => {
    setSelectedProducer(producer);
    if (isMulti) {
      setMultiValue(producer);
    } else {
      setSingleValue(producer);
    }
  };

  const fetchProducer = (param = paramValue) => {
    const queryParams = {
      producer_id: param,
      perPage: 1,
      page: 1
    };

    return ProductsApi.getProducers(queryParams);
  };

  const getDataForElement = async () => {
    try {
      const { data } = await fetchProducer();
      setSelectedProducer(formatProducerValue(data.data[0]));
      setIsInitiallyLoaded(true);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const getDataForAllElements = async () => {
    try {
      const results = await Promise.all(paramValue.map((param) => fetchProducer(param)));
      setSelectedProducer(results.map((result) => formatProducerValue(result.data.data[0])));
      setIsInitiallyLoaded(true);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const syncDataWithParams = async () => {
    if (!paramValue) {
      setSelectedProducer(isMulti ? [] : null);
    } else {
      if (isMulti) {
        const wasElementDeleted = selectedProducer?.some((producer) => !paramValue.includes(String(producer.value)));

        if (wasElementDeleted) {
          setSelectedProducer((prev) => prev.filter((producer) => paramValue.includes(String(producer.value))));
        } else {
          const isArray = Array.isArray(paramValue);
          const paramArray = isArray ? paramValue : [paramValue];
          const missingElement = paramArray.find(
            (param) => !selectedProducer?.some((producer) => String(producer.value) === String(param))
          );
          if (missingElement) {
            try {
              const { data } = await fetchProducer(missingElement);
              setSelectedProducer((prev) => [...prev, formatProducerValue(data[0])]);
            } catch (err) {
              notifyApiError(err);
            }
          }
        }
      } else if (String(selectedProducer?.value) !== paramValue) {
        getDataForElement();
      }
    }
  };

  useEffect(() => {
    if (paramValue) {
      if (Array.isArray(paramValue)) getDataForAllElements();
      else getDataForElement();
    } else {
      setIsInitiallyLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (syncWithParams && isInitiallyLoaded) {
      syncDataWithParams();
    }
  }, [paramValue]);

  return (
    <AsyncSelect
      value={selectedProducer}
      onChange={onChangeHandler}
      apiCallback={ProductsApi.getProducers}
      valueKey={valueKey}
      labelKey={labelKey}
      placeholder={'Producent'}
      label={label}
      isClearable
      isMulti={isMulti}
      queryParams={{ pagination: 1, ...queryParams }}
      wrapperStyle={classNames(style.select, {
        [style.selected]: !!selectedProducer,
        [style.fullWidth]: fullWidth
      })}
    />
  );
};

export default FilterByProducer;
