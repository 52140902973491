import React from 'react';

import { Card, LabelWithValue } from 'components';

import { wholesalerOffer } from 'src/constants/enums';

import styles from './WholesalerDetails.module.scss';

const WholesalerDetails = ({ domain, nip, offer }) => {
  return (
    <Card className={styles.container}>
      <LabelWithValue
        label={'Numer NIP'}
        value={nip}
      />
      <LabelWithValue
        label={'Domena'}
        value={domain}
      />
      <LabelWithValue
        label={'Oferta'}
        value={offer ? wholesalerOffer[offer] : '-'}
      />
    </Card>
  );
};

export default WholesalerDetails;
