import { releaseTypes } from 'src/constants/enums';

export const productReleaseTypeOptions = [
  {
    label: 'Ręczne',
    value: 'MANUAL_RELEASE'
  },
  {
    label: 'Strata',
    value: 'WASTE_RELEASE'
  }
];
