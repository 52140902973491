import _ from 'lodash';

import { createApiAction } from 'src/api/actions';
import ApiClient from 'src/api/client';
import { SupplierRoutes } from 'src/api/routes';

import * as types from './types';

export const addGift = (gift) => ({
  type: types.ADD_GIFT,
  payload: {
    ...gift,
    giftId: _.uniqueId()
  }
});

export const setShippingPrice = (price) => ({
  type: types.SET_SHIPPING_PRICE,
  payload: price
});

export const setFreeShippingPrice = (price) => ({
  type: types.SET_FREE_SHIPPING_PRICE,
  payload: price
});

export const deleteGift = (id) => ({
  type: types.DELETE_GIFT,
  payload: id
});

export const togglePaymentMethod = (id) => ({
  type: types.TOGGLE_PAYMENT_METHOD,
  payload: id
});

export const toggleShipmentMethod = (id) => ({
  type: types.TOGGLE_SHIPMENT_METHOD,
  payload: id
});

export const addComment = (value) => ({
  type: types.ADD_COMMENT,
  payload: value
});

export const changePriceType = (price) => ({
  type: types.CHANGE_PRICE_TYPE,
  payload: price
});

export const setNewShipmentMethod = (method) => ({
  type: types.SET_NEW_SHIPMENT_METHODS,
  payload: method
});

export const deleteShipmentMethod = (id) => ({
  type: types.DELETE_SHIPMENT_METHODS,
  payload: id
});

export const clearSupplierFormStore = () => ({
  type: types.CLEAR_SUPPLIER_FORM_STORE
});

export const createProductsPricing = (data = []) => ({
  type: types.ADD_PRODUCTS_PRICING,
  payload: data
});
export const updateProductPricing = (data) => ({
  type: types.UPDATE_PRODUCT_PRICING,
  payload: data
});

export const setNewValidator = (data) => ({
  type: types.SET_NEW_VALIDATOR,
  payload: data
});

export const increaseNumberOfValidations = (data) => ({
  type: types.INCREMENT_NUMBER_OF_VALIDATIONS,
  payload: data
});

export const updateIsDisabled = (data) => ({
  type: types.UPDATE_IS_DISABLED,
  payload: data
});

export const setInitialFormData = () => ({
  type: types.SET_INITIAL_FORM_DATA
});

export const setIsDataSaved = (bool) => ({
  type: types.SET_IS_DATA_SAVED,
  payload: !!bool
});

export const updateProductInitialData = (product) => ({
  type: types.UPDATE_PRODUCT_INITIAL_DATA,
  payload: product
});

// TODO
// https://stackoverflow.com/questions/66967499/can-i-use-react-lazy-inside-a-function
// try this to fix "using before initialisation" bug
// \/

export class SupplierApi {
  static getForm(params) {
    return ApiClient.get(SupplierRoutes.GET_FORM, params);
  }
}

export const getFormData = createApiAction(
  SupplierApi.getForm,
  types.GET_FORM_DATA_REQUEST,
  types.GET_FORM_DATA_SUCCESS,
  types.GET_FORM_DATA_FAILURE,
  [401]
);
