import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { Icon, Menu, Modal, Tag, useAuthUser, useCompany, useModalConfirm, usePermissions } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { CompanyApi } from 'src/api';
import { userPermissions, userRoles } from 'src/constants/enums';
import { changeCompanyInvoicesVersion } from 'src/features/CompaniesList/actions';
import { refreshUserData } from 'src/features/onboarding/actions';
import { getFormattedPhoneNumber } from 'src/utils/helpers';

import InvoiceForm from './components/InvoiceForm';

import style from '../details.module.scss';

const InvoiceDetails = ({ details, inProfile = false, onClick, readOnly, companyId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();
  const dispatch = useDispatch();
  const editRef = useRef();
  const deleteRef = useRef();
  const user = useAuthUser();
  const company = useCompany();

  if (!details) {
    return null;
  }

  const { legal_form, name, phone, nip, regon, address, company_name, is_default, email } = details || {};
  const { street, building_number, apartment_number, zip_code, city } = address || {};

  const [canEdit] = usePermissions([userPermissions.company.admin]);

  const refreshData = async () => {
    if (companyId) await dispatch(changeCompanyInvoicesVersion());
    if (!companyId || (company && companyId === company?.id)) await dispatch(refreshUserData());
  };

  const setAsDefault = async (isDefault) => {
    const data = {
      ...details,
      ...(user?.role === userRoles.admin && { company_id: companyId }),
      invoice_data_id: details.id,
      is_default: isDefault
    };

    try {
      await CompanyApi.updateInvoiceDetails(data);
      notifyCommon(['Dane do faktury zostały ustawione jako domyślne.']);
      await refreshData();
    } catch (err) {
      notifyApiError(err);
    }
  };

  const deleteInvoiceDetails = async () => {
    try {
      await CompanyApi.deleteInvoiceDetails(details.id);
      notifyCommon(['Dane do faktury zostały usunięte.']);
      await refreshData();
    } catch (err) {
      notifyApiError(err);
    }
  };

  const handleDeleteInvoiceDetails = () => {
    handleOpenModalConfirm({
      message: 'Czy na pewno chcesz usunąć te dane do faktury?',
      handleConfirm: deleteInvoiceDetails
    });
  };

  const actions = [
    {
      title: details.is_default ? 'Usuń domyślne' : 'Ustaw jako domyślne',
      icon: 'ticket',
      action: () => setAsDefault(!details.is_default)
    },
    {
      title: 'Edytuj',
      icon: 'edit',
      action: () => setIsModalOpen(true)
    },
    {
      title: 'Usuń',
      icon: 'trash',
      color: 'red',
      action: handleDeleteInvoiceDetails
    }
  ];

  const handleClick = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  const handleOnClick = (e) => {
    if (
      readOnly ||
      e.target === editRef.current ||
      e.target.parentNode === editRef.current ||
      e.target === deleteRef.current ||
      e.target.parentNode === deleteRef.current
    ) {
      e.preventDefault();
      return;
    }

    if (onClick) {
      onClick();
    }
  };

  return (
    <>
      <div
        className={classNames(style.details, {
          [style.click]: !!onClick,
          [style.default]: is_default
        })}
        onClick={handleOnClick}
      >
        {!!is_default && (
          <Tag
            value={'Domyślne'}
            className={style.tag}
          />
        )}
        <div className={style.textWrapper}>
          <p className={style.label}>Forma prawna</p>
          <p className={style.text}>{legal_form}</p>
        </div>
        <div className={style.textWrapper}>
          <p className={style.label}>Nazwa firmy</p>
          <p className={style.text}>{name || company_name}</p>
        </div>
        <div className={style.textWrapper}>
          <p className={style.label}>Adres</p>
          <span>
            <p className={style.text}>
              ul. {street} {building_number}
              {apartment_number ? `/${apartment_number}` : null}
            </p>
            <p className={style.text}>
              {zip_code} {city}
            </p>
          </span>
        </div>
        <div className={style.textWrapper}>
          <p className={style.label}>NIP</p>
          <p className={style.text}>{nip}</p>
        </div>
        {!!regon && (
          <div className={style.textWrapper}>
            <p className={style.label}>REGON</p>
            <p className={style.text}>{regon}</p>
          </div>
        )}
        {!!email && (
          <div className={style.textWrapper}>
            <p className={style.label}>E-mail</p>
            <p className={style.text}>{email}</p>
          </div>
        )}
        <div className={style.textWrapper}>
          <p className={style.label}>Telefon</p>
          <p className={style.text}>{getFormattedPhoneNumber(phone)}</p>
        </div>

        {!readOnly &&
          canEdit &&
          (inProfile ? (
            <Menu
              actions={actions}
              className={style.menu}
            />
          ) : (
            <>
              <button
                className={style.edit}
                onClick={handleClick}
                ref={editRef}
              >
                <Icon
                  name={'edit'}
                  fill={'#6F767E'}
                />
              </button>
              <button
                className={style.delete}
                onClick={handleDeleteInvoiceDetails}
                ref={deleteRef}
              >
                <Icon
                  name={'trash'}
                  fill={'#ea6c64'}
                />
              </button>
            </>
          ))}
        <span className={style.tag}></span>
      </div>
      <Modal
        visible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={'Edytuj dane do faktury'}
      >
        <InvoiceForm
          invoiceDetails={details}
          closeModal={() => setIsModalOpen(false)}
          companyId={companyId}
        />
      </Modal>
      {renderedModalConfirm}
    </>
  );
};

export default InvoiceDetails;
