import React, { useState } from 'react';
import classnames from 'classnames';

import { Modal, ProductDetails, useIsAdmin, usePermissions } from 'components';

import { userPermissions } from 'src/constants/enums';

import AddNewPrice from '../../../AddNewPrice';
import OtherSuppliers from '../../../OtherSuppliers';
import ProductButtons from '../../../ProductButtons';
import ProductReplacementsButton from '../../../ProductReplacementsButton';
import SuppliersList from '../../../SuppliersList';
import ProductMenu from '../../components/ProductMenu';
import ProductMonthVolumes from '../../components/ProductMonthVolumes';

import style from '../../Product.module.scss';

const ProductWithVolumes = ({
  product,
  className,
  isCatalogView,
  recentlyOrdered,
  selectedCompany,
  showRefPrice = false,
  changeValuationSelections,
  isQuietLoading,
  isReplacementsList
}) => {
  const isAdmin = useIsAdmin();
  const { ref_price: refPrice, volumes } = product || {};
  const [isOpen, setIsOpen] = useState(false);

  const boxClasses = classnames(style.box, style.withVolumes);
  const [canMenageCatalog] = usePermissions([userPermissions.offer_catalog.menage]);

  return (
    <>
      <div className={boxClasses}>
        <ProductReplacementsButton
          product={product}
          isCatalogView={isCatalogView}
          selectedCompany={selectedCompany}
        />
        <ProductDetails
          className={className}
          product={product}
          showRefPrice={showRefPrice}
          orderedDate={recentlyOrdered}
          withoutSavings
          autoSize
          showTags={isCatalogView}
        />

        <div className={style.wrapper}>
          <span className={style.label}>Śr wol. roczny (OCR):</span>
          <p>
            {volumes?.product_ocr_avg_year_volume !== null ? volumes.product_ocr_avg_year_volume : '-'} {product?.unit_name}
          </p>
        </div>

        <div className={style.wrapper}>
          <span className={style.label}>Śr wol. roczny:</span>
          <p>
            {volumes?.product_order_avg_year_volume !== null ? volumes.product_order_avg_year_volume : '-'} {product?.unit_name}
          </p>
        </div>

        <ProductMonthVolumes volumes={volumes?.product_monthly_volumes} />

        {canMenageCatalog && (
          <div className={style.buttonsWrapper}>
            <div className={style.desktop}>
              <OtherSuppliers
                count={product.prices.length}
                onClick={() => setIsOpen(true)}
              />
              <AddNewPrice
                product={product}
                selectedCompany={selectedCompany}
              />
            </div>

            <div className={style.mobile}>
              <ProductButtons
                product={product}
                changeValuationSelections={changeValuationSelections}
                selectedCompany={selectedCompany}
                isQuietLoading={isQuietLoading}
              />
            </div>
          </div>
        )}

        <div className={style.menuButton}>
          <ProductMenu
            className={style.productMenu}
            selectedCompany={selectedCompany}
            product={product}
            isCatalogView={isCatalogView}
          />
        </div>

        <Modal
          visible={isOpen}
          onClose={() => setIsOpen(false)}
          title={'Wszystkie oferty'}
        >
          <SuppliersList
            product={product}
            changeValuationSelections={changeValuationSelections}
            selectedCompany={selectedCompany}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isQuietLoading={isQuietLoading}
          />
        </Modal>
      </div>
    </>
  );
};

export default ProductWithVolumes;
