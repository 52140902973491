import React, { useEffect, useRef, useState } from 'react';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import { useAtom } from 'jotai/index';
import moment from 'moment';

import {
  AsyncSelect,
  Button,
  EmptyState,
  FilterByDepartment,
  FilterBySubwarehouse,
  Input,
  QtySelector,
  SaveButton,
  Select,
  useDepartments,
  useIsAdmin,
  useValidator,
  useWarehouse
} from 'components';
import Scanner from 'components/layout/BarcodeScanner/components/Scanner';
import { notifyApiError, notifyCommon, notifyDanger } from 'components/layout/Toasts';

import { WarehouseApi } from 'src/api';
import { ReactComponent as ScannerIcon } from 'src/assets/icons/scanner-gun.svg';
import { selectedCompanyAtom } from 'src/features/Warehouse';

import { productReleaseTypeOptions } from './options';

import style from './ProductRelease.module.scss';

const defaultRelease = (product) => ({
  product: product ? { value: product.product_id, label: product.name, ...product } : null,
  quantity: 0
});

const ProductRelease = ({ refreshData, closeModal, products = [], subwarehouseId }) => {
  const isAdmin = useIsAdmin();
  const validator = useValidator();
  const containerRef = useRef(null);

  const [selectedCompany] = useAtom(selectedCompanyAtom);
  const { departments = [] } = useDepartments();
  const { subwarehouses } = useWarehouse();
  const hasSubwarehouses = subwarehouses.length > 0;

  const [isLoading, setIsLoading] = useState(false);
  const [isScannerOpen, setIsScannerOpen] = useState(false);
  const [isScrolledAfter, setIsScrolledAfter] = useState(false);

  const [releaseDate, setReleaseDate] = useState(moment().format('YYYY-MM-DD'));
  const [department, setDepartment] = useState(null);
  const [releaseProducts, setReleaseProducts] = useState(
    !hasSubwarehouses || (hasSubwarehouses && subwarehouseId) ? products.map((product) => defaultRelease(product)) : []
  );
  const [releaseType, setReleaseType] = useState(productReleaseTypeOptions[0]);
  const [selectedSubwarehouseId, setSelectedSubwarehouseId] = useState(subwarehouseId ? Number(subwarehouseId) : null);

  useEffect(() => {
    disableBodyScroll(containerRef.current);

    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  useEffect(() => {
    const modalElement = document.querySelector('#modal');
    if (modalElement && !isScrolledAfter) {
      modalElement.scrollTop = modalElement?.scrollHeight;
      setIsScrolledAfter(true);
    }
  }, [isScrolledAfter]);

  useEffect(() => {
    validator.purgeFields();
  }, [departments]);

  const deleteReleaseHandler = (index) => {
    setReleaseProducts((prev) => prev.filter((_, i) => i !== index));
    validator.purgeFields();
  };

  const changeProductReleaseData = (index, key, value) => {
    if (key === 'product' && releaseProducts.some((p) => p.product?.product_id === value?.product_id)) {
      notifyDanger(['Produkt znajduje się już na liście dostawy.']);
      return;
    }
    setReleaseProducts((prev) => {
      const newRelease = [...prev];
      newRelease[index] = { ...newRelease[index], [key]: value };
      return newRelease;
    });
  };

  const addReleaseHandler = () => {
    setReleaseProducts((prev) => [...prev, defaultRelease()]);
    setIsScrolledAfter(false);
  };

  const formSubmitHandler = async (e) => {
    e.preventDefault();

    if (releaseProducts.length === 0) {
      notifyDanger(['Dodaj przynajmniej jeden produkt do wydania.']);
      return;
    }

    if (!validator.allValid()) {
      validator.showMessages();
      return null;
    }

    const payload = {
      ...(isAdmin && { company_id: selectedCompany }),
      ...(department && { department_id: department }),
      ...(selectedSubwarehouseId && { subwarehouse_id: selectedSubwarehouseId }),
      release_date: releaseDate,
      release_type: releaseType.value,
      products: releaseProducts.map((product) => ({
        product_id: product.product.product_id,
        quantity: product.quantity
      }))
    };

    try {
      setIsLoading(true);
      await WarehouseApi.addReleaseArray(payload);
      if (refreshData) refreshData();
      if (closeModal) closeModal();
      notifyCommon(['Wydano z magazynu wybrane produkty.']);
    } catch (error) {
      notifyApiError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onScanHandler = async (ean, index) => {
    const queryParams = {
      page: 1,
      perPage: 1,
      ean,
      ...(isAdmin && { company_id: selectedCompany })
    };

    try {
      const { data } = await WarehouseApi.getProducts(queryParams);
      const product = data.data[0];
      if (product) {
        if (releaseProducts.some((p) => p.product?.product_id === product.product_id && p.product?.ean === product?.ean)) {
          notifyDanger(['Produkt znajduje się już na liście dostawy.']);
          return;
        }
        changeProductReleaseData(index, 'product', { value: product.product_id, label: product.name, ...product });
      } else {
        notifyCommon([`Nie znaleziono produktu. Kod EAN: ${ean}`]);
      }
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsScannerOpen(false);
    }
  };

  return (
    <div
      className={style.container}
      ref={containerRef}
    >
      <div className={style.settings}>
        <Input
          value={releaseDate}
          onChange={(e) => setReleaseDate(e.target.value)}
          wrapperStyle={style.quantityInput}
          validator={validator}
          label='Data wydania'
          name='expired_date'
          id='expired_date'
          rule='required'
          type='date'
        />
        <Select
          options={productReleaseTypeOptions}
          value={releaseType}
          onChange={setReleaseType}
          placeholder={'Wybierz typ wydania...'}
          label={'Typ wydania'}
          isLoading={isLoading}
          validator={validator}
          rule='required'
        />
        {hasSubwarehouses && (
          <FilterBySubwarehouse
            id={'subwarehouse'}
            name={'subwarehouse'}
            label={'Magazyn'}
            placeholder={'Wybierz magazyn'}
            value={selectedSubwarehouseId}
            onChange={(id) => {
              setSelectedSubwarehouseId(id);
              setReleaseProducts([defaultRelease()]);
            }}
            fullWidth
            selectedCompany={selectedCompany}
            validator={validator}
            rule={'required'}
          />
        )}
        {departments.length > 0 && (
          <FilterByDepartment
            value={department}
            onChange={setDepartment}
            label={'Jednostka organizacyjna'}
            placeholder={'Wybierz jednostkę...'}
            validator={validator}
            rule='required'
            fullWidth
          />
        )}
      </div>

      <div className={style.list}>
        {releaseProducts.length === 0 && (
          <EmptyState
            type={'productRelease'}
            tiny
          />
        )}
        {releaseProducts.map((release, i) => (
          <div
            className={style.release}
            key={`release-${i}`}
          >
            {isScannerOpen && (
              <Scanner
                onScan={(ean) => onScanHandler(ean, i)}
                closeScanner={() => setIsScannerOpen(false)}
              />
            )}
            <Button
              onClick={() => setIsScannerOpen(true)}
              label={<ScannerIcon className={style.icon} />}
              className={style.buttonScanner}
              disabled={!!release.product}
              type={'button'}
            />
            <AsyncSelect
              onChange={(val) => changeProductReleaseData(i, 'product', val)}
              apiCallback={WarehouseApi.getProducts}
              placeholder='Wybierz...'
              validator={validator}
              valueKey='product_id'
              value={release.product}
              queryParams={{
                ...(isAdmin && { company_id: selectedCompany }),
                ...(selectedSubwarehouseId && { subwarehouse_id: selectedSubwarehouseId })
              }}
              id={`select-product-${i}`}
              rule='required'
              labelKey='name'
              label='Produkt'
              productSearch
              isClearable
            />
            <div className={style.quantityBox}>
              <QtySelector
                setState={(val) => changeProductReleaseData(i, 'quantity', val)}
                wrapperStyle={style.quantityInput}
                rule={`required|only_positive${release.product ? `|max:${release.product?.quantity},num` : ''}`}
                state={release.quantity}
                validator={validator}
                label='Ilość'
                hideValueWhenZero
                id={`quantity-${i}`}
                suffix={'szt.'}
              />
              <p className={style.currentQuantity}>
                / {release.product ? release.product.quantity : '?'} {'szt.'}
              </p>
            </div>
            <SaveButton
              wrapperStyle={style.deleteButton}
              type={'delete'}
              onClick={() => deleteReleaseHandler(i)}
              isLoading={isLoading}
              disabled={releaseProducts.length === 1}
            />
          </div>
        ))}
      </div>
      <div className={style.buttons}>
        <Button
          label={'Dodaj kolejny produkt'}
          isLoading={isLoading}
          onClick={addReleaseHandler}
          gray
          disabled={isLoading || (hasSubwarehouses && !selectedSubwarehouseId)}
        />
        <Button
          label={'Zapisz wydanie produktów'}
          isLoading={isLoading}
          onClick={formSubmitHandler}
        />
      </div>
    </div>
  );
};

export default ProductRelease;
