import React from 'react';
import classNames from 'classnames';

import { useIsMobile } from 'components';

import style from './OrderListProductDepartment.module.scss';

const OrderListProductDepartment = ({ department, unit_name }) => {
  const { name, quantity } = department;

  const isMobile = useIsMobile(767);

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <p className={style.text}>{name}</p>
      </div>
      <div className={classNames(style.wrapper, style.quantity)}>
        {isMobile && <p className={style.text}>Ilość: </p>}
        {quantity} {unit_name}
      </div>
    </div>
  );
};

export default OrderListProductDepartment;
