import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { EmptyState, Tag, useIsAdmin } from 'components';
import ThumbnailPlaceholderSmall from 'components/layout/ThumbnailPlaceholderSmall';

import { ReplacementsApi } from 'src/api';
import { notifyApiError } from 'src/components/layout/Toasts';
import Section from 'src/features/Dashboard/components/Section';
import { toDate, toTime } from 'src/utils/dateTime';
import { getRoleColor, getUserRoleLabel } from 'src/utils/helpers';

import style from './NewReplacementsGroups.module.scss';

const DASHBOARD_ELEMENT_ID = 100;

const NewReplacementsGroups = ({ config }) => {
  const isAdmin = useIsAdmin();
  const canBeDisplayed = useMemo(() => isAdmin && config?.elements?.includes(DASHBOARD_ELEMENT_ID), [isAdmin, config]);

  const [isLoading, setIsLoading] = useState(false);
  const [pageData, setPageData] = useState([]);

  const getData = async () => {
    const params = {
      page: 1,
      perPage: 3
    };

    try {
      setIsLoading(true);
      const { data } = await ReplacementsApi.getNewGroups(params);
      setPageData(data.data || []);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (canBeDisplayed) getData();
  }, [canBeDisplayed]);

  if (!canBeDisplayed) {
    return null;
  }
  return (
    <Section
      title={'Nowe połączenia zamienników'}
      link={'/products-replacements'}
      id={'products-replacements'}
      className={style.lastInvoicesSection}
    >
      {pageData?.length > 0 ? (
        <div className={style.container}>
          {pageData.map(({ id, name, created_at, user_name, user_role }) => (
            <div
              key={`new-group-${id}`}
              className={style.item}
            >
              <Link
                to={{ pathname: '/products-replacements', search: `?search=${name}` }}
                className={style.linkWrapper}
              >
                <span className={style.title}>{name}</span>
              </Link>

              <div className={style.date}>{`${toDate(created_at)}, ${toTime(created_at)}`}</div>

              <div className={style.user}>
                <Tag
                  color={getRoleColor(user_role)}
                  value={getUserRoleLabel(user_role)}
                  className={style.role}
                />
                {user_name || 'Użytkownik'}
              </div>
            </div>
          ))}
        </div>
      ) : isLoading ? (
        <ThumbnailPlaceholderSmall
          quantity={2}
          small
        />
      ) : (
        <div className={style.wrapper}>
          <EmptyState type={'noNewReplacementsGroups'} />
        </div>
      )}
    </Section>
  );
};

export default NewReplacementsGroups;
