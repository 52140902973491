import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { atom, useSetAtom } from 'jotai';

import {
  Button,
  Card,
  EmptyState,
  FilterByCompany,
  Modal,
  PageHeader,
  PaginationNav,
  SeoHelmet,
  useIsAdmin,
  useModalConfirm,
  usePermissions,
  useSearchParam
} from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { WarehouseApi } from 'src/api';
import minusIcon from 'src/assets/icons/minus.svg';
import { userPermissions } from 'src/constants/enums';
import { EXCEL_FILE_TYPE } from 'src/constants/misc';
import pages from 'src/dictionaries/pages.json';
import SubwarehousesList from 'src/features/Profile/features/WarehouseSettings/components/SubwarehousesList';
import ImportExportModal from 'src/features/Warehouse/new/ImportExportModal';
import ProductsList from 'src/features/Warehouse/new/ProductsList';
import WarehouseHistory from 'src/features/Warehouse/new/WarehouseHistory/WarehouseHistory';
import WarehouseSummary from 'src/features/Warehouse/new/WarehouseSummary';
import { downloadFile, getDecodeBase64File, getParamsArray } from 'src/utils/helpers';
import query from 'src/utils/query';

import { getDepartments } from '../Departments/actions';
import Filtering from './components/Filtering';
import ProductModal from './components/ProductModal';
import ExportRaportModal from './new/ExportRaportModal';
import { getViewSettings } from './actions';

import style from './Warehouse.module.scss';

export const modalTypes = {
  addNew: 'add_new',
  increase: 'increase',
  decrease: 'decrease'
};

export const versionAtom = atom(null);
export const selectedCompanyAtom = atom(undefined);

const Warehouse = (props) => {
  const params = query(props);
  const isAdmin = useIsAdmin();
  const dispatch = useDispatch();

  const [canEditWarehouse] = usePermissions([userPermissions.warehouse.write]);

  const setVersion = useSetAtom(versionAtom);
  const setSelectedCompany = useSetAtom(selectedCompanyAtom);

  const [pageQty, setPageQty] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [products, setProducts] = useState([]);
  const [modalType, setModalType] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isImportExportOpen, setImportExportOpen] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [isExportRaportOpen, setIsExportRaportOpen] = useState(false);

  const ean = useSearchParam(params, 'ean');
  const filter = useSearchParam(params, 'filter');
  const search = useSearchParam(params, 'search');
  const sortBy = useSearchParam(params, 'sort_by') || 'value';
  const currentPage = useSearchParam(params, 'page');
  const category = useSearchParam(params, 'category');
  const tag = useSearchParam(params, 'tag');
  const sortOrder = useSearchParam(params, 'sort_order') || 'DESC';
  const selectedCompany = useSearchParam(params, 'company');
  const subwarehouse = useSearchParam(params, 'subwarehouse');

  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();

  const getData = async () => {
    if (isAdmin && !selectedCompany) return null;

    const queryParams = {
      page: currentPage || 1,
      perPage: perPage,
      ...(!!ean && { ean }),
      ...(!!search && { search }),
      ...(category && { category_id: category }),
      ...(tag && { tag_ids: getParamsArray(tag) }),
      ...(isAdmin && { company_id: selectedCompany }),
      ...(sortOrder && { sort_order: sortOrder }),
      ...(sortBy && { sort_by: sortBy }),
      ...(filter && { [filter]: 1 }),
      ...(subwarehouse && { subwarehouse_id: subwarehouse })
    };

    try {
      const { data } = await WarehouseApi.getProducts(queryParams);
      setProducts(data.data);
      setPageQty(data?.last_page);
      setVersion((prev) => prev + 1);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const getDataWithLoading = async () => {
    if (isAdmin && !selectedCompany) return null;
    setIsLoading(true);
    await getData();
    dispatch(getViewSettings(selectedCompany));
    dispatch(getDepartments(selectedCompany));
    setIsLoading(false);
  };

  useEffect(() => {
    getDataWithLoading();
  }, [search, currentPage, perPage, category, tag, sortOrder, sortBy, ean, selectedCompany, filter, subwarehouse]);

  useEffect(() => {
    setSelectedCompany(selectedCompany);
  }, [selectedCompany]);

  const handleOpenModal = (type) => {
    setModalType(type);
    setIsModalOpen(true);
  };

  const downloadHandler = async (type) => {
    const date = dayjs().format('DD.MM.YYYY, HH:mm');

    const queryParams = { file_format: type, ...(isAdmin && { company_id: selectedCompany }) };

    try {
      const { data } = await WarehouseApi.exportWarehouseHistory(queryParams);
      const blob = type === 'CSV' ? new Blob([data], { type: 'text/csv' }) : getDecodeBase64File(data, EXCEL_FILE_TYPE);
      downloadFile(blob, 'historia_magazynu_' + date + '.' + type.toLowerCase());
    } catch (err) {
      notifyApiError(err);
    }
  };

  const handleRecountProductsData = async () => {
    try {
      const params = {
        ...(isAdmin && { company_id: selectedCompany })
      };
      const { data } = await WarehouseApi.recountProductsData(params);
      notifyCommon([data.message], { title: 'Sukces!' });
      setVersion((prev) => prev + 1);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const headerActions = [
    {
      title: 'Pobierz historię',
      icon: 'download',
      action: () => downloadHandler('XLSX')
    },
    {
      title: 'Pobierz raport',
      icon: 'download',
      action: () => setIsExportRaportOpen(true)
    },
    {
      title: 'Przelicz ponownie dane produktów',
      icon: 'repeat',
      action: () =>
        handleOpenModalConfirm({
          message: 'Czy na pewno chcesz przeliczyć ponownie dane produktów w magazynie?',
          handleConfirm: handleRecountProductsData
        })
    },
    {
      title: 'Inwentaryzacja',
      icon: 'edit',
      color: 'blue',
      action: () => setImportExportOpen(true)
    },
    {
      title: 'Ustawienia magazynu',
      icon: 'setting',
      action: () => setIsSettingsModalOpen(true)
    }
  ];

  if (isAdmin && !selectedCompany) {
    return (
      <>
        <PageHeader
          name={pages.warehouse.title}
          text={'Wybierz firmę'}
        />
        <Card className={style.centerContent}>
          <EmptyState type={'selectCompanyWarehouse'} />
          <FilterByCompany
            value={selectedCompany}
            params={params}
          />
        </Card>
      </>
    );
  }

  return (
    <>
      <SeoHelmet title={pages.warehouse.title} />
      <PageHeader
        name={pages.warehouse.title}
        className={style.header}
        actions={headerActions}
        breakpointBelowTablet
      >
        {isAdmin && (
          <FilterByCompany
            value={selectedCompany}
            params={params}
            className={style.adminSelect}
          />
        )}
        {canEditWarehouse && (
          <Button
            label='Dodaj produkt'
            onClick={() => handleOpenModal(modalTypes.addNew)}
            empty
          />
        )}
        <WarehouseHistory />
        {canEditWarehouse && (
          <Button
            label='Dostawa'
            iconName={'add'}
            onClick={() => handleOpenModal(modalTypes.increase)}
            gray
          />
        )}
        {canEditWarehouse && (
          <Button
            label='Wydanie'
            icon={minusIcon}
            onClick={() => handleOpenModal(modalTypes.decrease)}
          />
        )}
      </PageHeader>
      <div className={style.container}>
        <WarehouseSummary subwarehouseId={subwarehouse} />
        <Filtering params={params} />
        <ProductModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          modalType={modalType}
          refreshData={getData}
          subwarehouseId={subwarehouse}
        />
        <ImportExportModal
          isOpen={isImportExportOpen}
          setIsOpen={setImportExportOpen}
          refreshData={getData}
        />
        <ExportRaportModal
          isOpen={isExportRaportOpen}
          setIsOpen={setIsExportRaportOpen}
          selectedCompany={selectedCompany}
        />
        <ProductsList
          params={params}
          products={products}
          subwarehouseId={subwarehouse}
          isLoading={isLoading}
          refreshData={getData}
        />
        <PaginationNav
          params={params}
          pagesQty={pageQty}
          setPerPage={setPerPage}
          defaultQty={perPage}
        />
      </div>
      <Modal
        visible={isSettingsModalOpen}
        onClose={() => setIsSettingsModalOpen(false)}
        title='Ustawienia'
      >
        <SubwarehousesList selectedCompany={selectedCompany} />
      </Modal>
      {renderedModalConfirm}
    </>
  );
};

export default Warehouse;
