import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import { Button, Card, Input, ProcessStepper, Tag, useValidator } from 'components';

import { OrdersApi } from 'src/api';
import { notifyApiError, notifyDanger } from 'src/components/layout/Toasts';
import { getOrderTrackingCurrentStep } from 'src/utils/helpers';

import style from './AddOrderTracking.module.scss';

const AddOrderTracking = ({ data, uid, refreshDataCallback }) => {
  const validator = useValidator();

  const [isLoading, setIsLoading] = useState(false);
  const [initialTrackingNumber] = useState(data.trackingnumber || '');
  const [trackingNumber, setTrackingNumber] = useState(data.trackingnumber || '');
  const [trackingData, setTrackingData] = useState();

  const hasTracking = !!data?.trackingnumber;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    try {
      setIsLoading(true);
      const { data } = await OrdersApi.addShipmentNumber({
        order_uid: uid,
        shipment_number: trackingNumber
      });

      // eslint-disable-next-line quotes
      if (data?.message.includes("don't need to repeat registration")) {
        throw new Error('already_exists');
      } else if (data?.message.includes('Carrier cannot be detected.')) {
        throw new Error('carrier_not_detected');
      }

      await refreshDataCallback();

      // 17Track has latency in updating tracking data
      setTimeout(() => {
        getTrackingData(trackingNumber);
      }, 13000);

      validator.hideMessages();
    } catch (err) {
      if (err?.message === 'already_exists') {
        notifyDanger(['Podany numer listu przewozowego został już wykorzystany.']);
      } else if (err?.message === 'carrier_not_detected') {
        notifyDanger(['Nie udało się wykryć przewoźnika.']);
      } else {
        notifyApiError(err);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const deleteTracking = async () => {
    if (!data?.trackingnumber) return;

    setIsLoading(true);
    try {
      await OrdersApi.deleteShipmentNumber(uid);
      setTrackingData(null);
      setTrackingNumber('');
      await refreshDataCallback();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const getTrackingData = async (trackingNum) => {
    if (!trackingNum) return;

    const params = {
      shipment_number: trackingNum
    };

    try {
      const { data } = await OrdersApi.getShipmentDetails(params);
      setTrackingData({
        carrier: data?.carrier_name,
        trackingUrl: data?.carrier_url,
        data: data?.data
      });
    } catch (err) {
      notifyApiError(err);
      setTrackingData(null);
    }
  };

  useEffect(() => {
    if (initialTrackingNumber) {
      getTrackingData(data.trackingnumber);
    } else {
      setTimeout(() => {
        getTrackingData(data.trackingnumber);
      }, [3000]);
    }
  }, [data.trackingnumber]);

  const stepperData = useMemo(() => {
    if (!trackingData?.data) return null;
    const stepper = getOrderTrackingCurrentStep(trackingData.data);
    return stepper;
  }, [trackingData]);

  const orderTrackingTitle = <h2 className={style.title}>Śledzenie przesyłki</h2>;

  const trackingForm = (
    <form
      className={style.form}
      onSubmit={handleSubmit}
    >
      <Input
        name={'tracking-number'}
        value={trackingNumber}
        onChange={(e) => setTrackingNumber(e.target.value)}
        placeholder={'6231918009432'}
        label={'Numer listu przewozowego'}
        id={'tracking-number'}
        validator={validator}
        rule={'required'}
      />

      <Button
        label={'Dodaj śledzenie przesyłki'}
        isLoading={isLoading}
        type={'submit'}
        disabled={!trackingNumber}
      />
    </form>
  );

  return (
    <Card className={style.wrapper}>
      <div className={style.cardHeader}>
        {orderTrackingTitle}
        {hasTracking && (
          <Button
            label={'Usuń śledzenie przesyłki'}
            onClick={deleteTracking}
            isLoading={isLoading}
            empty
          />
        )}
      </div>

      {hasTracking ? (
        <div className={classNames(style.tracking, { [style.withStepper]: !!stepperData })}>
          {!!stepperData && (
            <ProcessStepper
              {...stepperData}
              smaller
              direction={'vertical'}
            />
          )}

          <div className={style.box}>
            <div className={style.column}>
              <p className={style.label}>Przewoźnik:</p>
              {trackingData?.carrier ? <p className={style.value}>{trackingData.carrier}</p> : <Tag value={'Nieznany'} />}
            </div>
            <div className={style.column}>
              <p className={style.label}>Numer listu przewozowego:</p>
              {data.trackingnumber ? <p className={style.value}>{data.trackingnumber}</p> : <Tag value={'Nie załączono'} />}
            </div>
            <div className={style.column}>
              <p className={style.label}>Link do śledzenia przesyłki:</p>
              {trackingData?.trackingUrl ? (
                <a
                  className={classNames(style.value, style.link)}
                  href={trackingData.trackingUrl}
                  target={'_blank'}
                  rel='noreferrer'
                >
                  {trackingData.trackingUrl}
                </a>
              ) : (
                <Tag value={'Nie załączono'} />
              )}
            </div>
          </div>
        </div>
      ) : (
        trackingForm
      )}
    </Card>
  );
};

export default AddOrderTracking;
