import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';

import { Card, LabelWithValue, useIsAdmin, usePermissions } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { WarehouseApi } from 'src/api';
import { userPermissions } from 'src/constants/enums';
import { selectedCompanyAtom, versionAtom } from 'src/features/Warehouse';
import { getFormattedAmount } from 'src/utils/helpers';

import style from './WarehouseSummary.module.scss';

const WarehouseSummary = ({ subwarehouseId }) => {
  const isAdmin = useIsAdmin();
  const [canViewOrders] = usePermissions([userPermissions.orders.read]);

  const [version] = useAtom(versionAtom);
  const [selectedCompany] = useAtom(selectedCompanyAtom);

  const [summary, setSummary] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const getData = async () => {
    if (isAdmin && !selectedCompany) return null;

    const params = {
      ...(isAdmin && { company_id: selectedCompany }),
      ...(subwarehouseId && { subwarehouse_id: subwarehouseId })
    };

    try {
      setIsLoading(true);
      const { data } = await WarehouseApi.getSummary(params);
      setSummary(data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [version, selectedCompany, subwarehouseId]);

  return (
    <Card className={style.container}>
      {canViewOrders && (
        <LabelWithValue
          label={'Szacunkowa wartość magazynu'}
          value={getFormattedAmount(summary?.total)}
          isLoading={isLoading}
        />
      )}
      <LabelWithValue
        label={'Liczba produktów'}
        value={summary?.products_count}
        isLoading={isLoading}
      />
      <LabelWithValue
        label={'Po terminie ważności'}
        value={summary?.expired_products}
        isLoading={isLoading}
        dangerValue={summary?.expired_products === 0}
        dangerWhenFalsy
      />
      <LabelWithValue
        label={'Bliski termin'}
        value={summary?.near_Expired_products}
        isLoading={isLoading}
        dangerValue={summary?.expired_products === 0}
        dangerWhenFalsy
      />
      <LabelWithValue
        label={'Przekroczono stan minimalny'}
        value={summary?.out_of_stock_products}
        dangerValue={summary?.out_of_stock_products === 0}
        dangerWhenFalsy
        isLoading={isLoading}
      />
    </Card>
  );
};

export default WarehouseSummary;
