import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Button,
  EmptyState,
  Loader,
  Modal,
  PaginationNav,
  ProfileSection as Section,
  Searchbar,
  Tag,
  useIsAdmin,
  useSearchParam
} from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { WarehouseApi } from 'src/api';
import { getSubwarehouses } from 'src/features/Warehouse/actions';
import { handleNumeralWords } from 'src/utils/helpers';
import query from 'src/utils/query';

import NewSubwarehouseForm from './components/NewSubwarehouseForm';
import SubwarehouseThumbnail from './components/SubwarehouseThumbnail';

import style from './WarehouseSettings.module.scss';

const WarehouseSettings = ({ selectedCompany, ...props }) => {
  const params = query(props);
  const isAdmin = useIsAdmin();
  const dispatch = useDispatch();

  const [selectedElement, setSelectedElement] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [subwarehouses, setSubwarehouses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [lastPage, setLastPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);

  const page = useSearchParam(params, 'page') || 1;
  const search = useSearchParam(params, 'search');

  const getData = async () => {
    const params = { page, perPage, search, ...(isAdmin && selectedCompany && { company_id: selectedCompany }) };

    try {
      const { data } = await WarehouseApi.getSubWarehouses(params);
      setLastPage(data.last_page);
      setSubwarehouses(data.data);
      setTotal(data.total);
    } catch (err) {
      notifyApiError(err);
    }
  };

  useEffect(() => {
    const getDataHandler = async () => {
      setIsLoading(true);
      await getData();
      setIsLoading(false);
    };

    getDataHandler();
  }, [search, page, perPage]);

  useEffect(() => {
    return () => {
      params.remove('page');
    };
  }, []);

  const addButton = (
    <>
      <Searchbar params={params} />
      <Button
        label={'Dodaj podmagazyn'}
        onClick={() => setIsModalOpen(true)}
      />
    </>
  );

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedElement(null);
  };

  const editElementHandler = (element) => {
    setIsModalOpen(true);
    setSelectedElement(element);
  };

  const refreshAfterDeleteHandler = () => {
    params.remove('page');
    dispatch(getSubwarehouses(selectedCompany));
    return getData();
  };

  return (
    <Section
      isLoading={isLoading}
      title={'Ustawienia magazynu'}
      text={<Tag value={`${total} ${handleNumeralWords(['pozycja', 'pozycje', 'pozycji'], total)}`} />}
      button={addButton}
    >
      <Modal
        title={selectedElement?.id ? `Edytuj podmagazyn "${selectedElement?.name}"` : 'Dodaj podmagazyn'}
        visible={isModalOpen}
        onClose={closeModal}
      >
        <NewSubwarehouseForm
          onClose={closeModal}
          refreshData={getData}
          id={selectedElement?.id}
          currentName={selectedElement?.name}
        />
      </Modal>
      <div className={style.container}>
        {isLoading ? (
          <Loader />
        ) : subwarehouses.length > 0 ? (
          subwarehouses.map((subwarehouse) => (
            <SubwarehouseThumbnail
              refreshData={refreshAfterDeleteHandler}
              onEdit={() => editElementHandler(subwarehouse)}
              subwarehouse={subwarehouse}
              key={subwarehouse.id}
            />
          ))
        ) : (
          <EmptyState
            type={'subwarehouse'}
            tiny
          />
        )}
        <PaginationNav
          params={params}
          pagesQty={lastPage}
          setPerPage={setPerPage}
          defaultQty={perPage}
          hidePadding
          hideSelect
        />
      </div>
    </Section>
  );
};

export default WarehouseSettings;
