import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Card } from 'components';

import { availabilities } from 'src/constants/enums';

import { getSelectedId, useSupplierFormValidationForceUpdate } from '../../../../helpers';
import AddShipment from '../../../AddShipment';
import ShippingSelection from '../../../ShippingSelection';

import style from '../../OrderDetails.module.scss';
import shared from 'styles/Shared.module.scss';

const Shipments = ({ uid, disabled }) => {
  useSupplierFormValidationForceUpdate();
  const categories = useSelector((store) => store.supplierForm.categories);
  const shipments = useSelector((store) => store.supplierForm.form.shipments);
  const validator = useSelector((state) => state.supplierForm.validator);

  const selectedShipmentsId = getSelectedId(shipments);

  const areFieldsRequired = useMemo(() => {
    const isEveryProductUnavailable = categories?.every((category) =>
      category?.items?.every((product) => product?.availability === availabilities.notAvailable)
    );

    return !isEveryProductUnavailable;
  }, [categories]);

  useEffect(() => {
    validator?.purgeFields();
  }, [areFieldsRequired]);

  return (
    <Card
      className={shared.defaultWrapper}
      id={'shipments'}
    >
      <h2 className={style.title}>Sposób wysyłki</h2>
      {shipments.map((method) => (
        <ShippingSelection
          key={method.id}
          uid={uid}
          shippingMethod={method}
          disabled={disabled}
          showError={areFieldsRequired ? !!validator?.message('shipments', selectedShipmentsId, 'required') : false}
        />
      ))}
      <span className={style.errorMessage}>
        {areFieldsRequired && validator?.message('shipments', selectedShipmentsId, 'required')}
      </span>
      <AddShipment uid={uid} />
    </Card>
  );
};

export default Shipments;
