import { priceTypes } from 'src/features/SupplierForm/components/ValuationHeader';

import * as types from '../actions/types';
import { getDefaultPriceType, getFilteredGifts } from '../helpers';

const defaultForm = {
  comment: '',
  gifts: [],
  payments: [],
  shipments: [],
  shipping_price: 0,
  free_shipping_price: 0,
  wasPriceTypeChanged: false,
  price_type: priceTypes[0]
};

export const initialState = {
  categories: [],
  oldProductPrices: [],
  isDisabled: true,
  validator: null,
  isDataSaved: false,
  numberOfValidations: 0,
  products_pricing: [],
  initialForm: defaultForm,
  form: defaultForm
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_IS_DATA_SAVED:
      return {
        ...state,
        isDataSaved: action.payload
      };
    case types.UPDATE_IS_DISABLED:
      return {
        ...state,
        isDisabled: action.payload
      };
    case types.INCREMENT_NUMBER_OF_VALIDATIONS:
      return {
        ...state,
        numberOfValidations: state.numberOfValidations + 1
      };
    case types.SET_NEW_VALIDATOR:
      return {
        ...state,
        validator: action.payload
      };
    case types.ADD_GIFT:
      return {
        ...state,
        form: {
          ...state.form,
          gifts: [...state.form.gifts, action.payload]
        }
      };
    case types.SET_SHIPPING_PRICE:
      return {
        ...state,
        form: {
          ...state.form,
          shipping_price: action.payload
        }
      };
    case types.SET_FREE_SHIPPING_PRICE:
      return {
        ...state,
        form: {
          ...state.form,
          free_shipping_price: action.payload
        }
      };
    case types.DELETE_GIFT: {
      return {
        ...state,
        form: {
          ...state.form,
          gifts: getFilteredGifts(state.form.gifts, action)
        }
      };
    }
    case types.SET_NEW_SHIPMENT_METHODS:
      return {
        ...state,
        form: {
          ...state.form,
          shipments: action.payload
        }
      };
    case types.DELETE_SHIPMENT_METHODS:
      return {
        ...state,
        form: {
          ...state.form,
          shipments: state.form.shipments.filter((ship) => ship.id !== action.payload)
        }
      };
    case types.TOGGLE_PAYMENT_METHOD:
      return {
        ...state,
        form: {
          ...state.form,
          payments: state.form.payments.map((payment) =>
            payment.id === action.payload ? { ...payment, selected: !payment.selected } : payment
          )
        }
      };
    case types.TOGGLE_SHIPMENT_METHOD:
      return {
        ...state,
        form: {
          ...state.form,
          shipments: state.form.shipments.map((shipment) =>
            shipment.id === action.payload ? { ...shipment, selected: !shipment.selected } : shipment
          )
        }
      };
    case types.ADD_COMMENT: {
      return {
        ...state,
        form: {
          ...state.form,
          comment: action.payload
        }
      };
    }
    case types.GET_FORM_DATA_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case types.GET_FORM_DATA_SUCCESS: {
      const { comment, gifts, payments, shipments, shipping_price, free_shipping_price, price_type } = action.data;

      const formData = {
        comment,
        gifts,
        payments,
        shipments,
        shipping_price,
        free_shipping_price,
        price_type: getDefaultPriceType(price_type)
      };

      return {
        ...state,
        ...action.data,
        isLoading: false,
        initialForm: formData,
        form: formData
      };
    }
    case types.SET_INITIAL_FORM_DATA: {
      return {
        ...state,
        initialForm: state.form
      };
    }
    case types.GET_FORM_DATA_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }
    case types.CHANGE_PRICE_TYPE: {
      return {
        ...state,
        form: {
          ...state.form,
          wasPriceTypeChanged: true,
          price_type: action.payload
        }
      };
    }
    case types.ADD_PRODUCTS_PRICING: {
      const products = action.payload.filter((prod) => +prod.price > 0);
      return {
        ...state,
        products_pricing: products.map(({ id, price, quantity }) => ({ id, price, quantity }))
      };
    }
    case types.UPDATE_PRODUCT_PRICING: {
      const { id, price, quantity } = action.payload;
      const restOfProducts = state.products_pricing.filter((prod) => prod.id !== id);
      return {
        ...state,
        products_pricing: +price > 0 ? [...restOfProducts, { id, price, quantity }] : [...restOfProducts]
      };
    }
    case types.UPDATE_PRODUCT_INITIAL_DATA: {
      const { id, ...product } = action.payload;

      const categories = state.categories.map((category) => {
        return {
          ...category,
          items: category.items.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                ...product
              };
            }
            return item;
          })
        };
      });

      return {
        ...state,
        categories
      };
    }
    case types.CLEAR_SUPPLIER_FORM_STORE: {
      return initialState;
    }
    default:
      return state;
  }
};
