import React, { useMemo, useState } from 'react';
import classnames from 'classnames';

import { ReactComponent as PlusIcon } from 'assets/icons/plus-blue.svg';
import {
  CompanyDetails,
  InvoiceDetails,
  InvoiceForm,
  Modal,
  ProfileSection as Section,
  useCompany,
  usePermissions
} from 'components';

import { userPermissions } from 'src/constants/enums';

import style from './Company.module.scss';

const Company = ({ inProfile, company }) => {
  const userCompany = useCompany();
  const [isInvoiceFormOpen, setIsInvoiceFormOpen] = useState(false);

  const [canEdit] = usePermissions([userPermissions.company.admin]);
  const companySource = useMemo(() => company || userCompany, [company, userCompany]);
  const defaultInvoiceDetails = useMemo(
    () => companySource.invoices_details.find((details) => details.is_default),
    [companySource]
  );

  const addNewInvoiceDetailsButton = canEdit && (
    <button
      className={style.addNew}
      onClick={() => setIsInvoiceFormOpen(true)}
    >
      <PlusIcon />
      Dodaj nowe dane
    </button>
  );

  return (
    <>
      <Section title={'Dane oddziału'}>
        <div className={classnames(style.wrapper, { [style.single]: !inProfile })}>
          <CompanyDetails details={companySource} />
        </div>
      </Section>

      <Section title={'Domyślne dane do faktury'}>
        <div className={style.wrapper}>
          {defaultInvoiceDetails ? (
            <InvoiceDetails
              inProfile
              details={defaultInvoiceDetails}
              companyId={company?.id}
            />
          ) : (
            addNewInvoiceDetailsButton
          )}
        </div>
      </Section>

      <Section title={'Dane do faktury'}>
        <div className={style.wrapper}>
          {companySource.invoices_details.map((details) => (
            <InvoiceDetails
              inProfile
              key={details.id}
              details={details}
              companyId={company?.id}
            />
          ))}
          {addNewInvoiceDetailsButton}
        </div>
      </Section>

      <Modal
        visible={isInvoiceFormOpen}
        onClose={() => setIsInvoiceFormOpen(false)}
        title={'Dodaj dane do faktury'}
      >
        <InvoiceForm
          closeModal={() => setIsInvoiceFormOpen(false)}
          companyId={company?.id}
        />
      </Modal>
    </>
  );
};

export default Company;
