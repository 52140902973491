import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, EmptyState, Loader, Modal, PaginationNav, SearchWithFilters, useIsAdmin, usePermissions } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { WarehouseApi } from 'src/api';
import { userPermissions } from 'src/constants/enums';
import { getSubwarehouses } from 'src/features/Warehouse/actions';

import NewSubwarehouseForm from '../NewSubwarehouseForm';
import SubwarehouseThumbnail from '../SubwarehouseThumbnail';

import style from './SubwarehousesList.module.scss';

const SubwarehousesList = ({ selectedCompany, params, inProfile }) => {
  const isAdmin = useIsAdmin();
  const dispatch = useDispatch();
  const [isCompanyAdmin] = usePermissions([userPermissions.company.admin]);

  const [selectedElement, setSelectedElement] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [subwarehouses, setSubwarehouses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [lastPage, setLastPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [searchState, setSearchState] = useState('');
  const [pageState, setPageState] = useState(1);

  const page = params ? params.get('page') : pageState;
  const search = params ? params.get('search') : searchState;

  const getData = async () => {
    const query = {
      page: params ? params.get('page') : page,
      perPage,
      ...(search.length > 0 && { search }),
      ...(isAdmin && selectedCompany && { company_id: selectedCompany })
    };

    try {
      const { data } = await WarehouseApi.getSubWarehouses(query);
      setLastPage(data.last_page);
      setSubwarehouses(data.data);
    } catch (err) {
      notifyApiError(err);
    }
  };

  useEffect(() => {
    const getDataHandler = async () => {
      setIsLoading(true);
      await getData();
      setIsLoading(false);
    };

    getDataHandler();
  }, [search, page, perPage]);

  useEffect(() => {
    return () => {
      if (params) params.remove('page');
    };
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedElement(null);
  };

  const editElementHandler = (element) => {
    setIsModalOpen(true);
    setSelectedElement(element);
  };

  const refreshAfterDeleteHandler = () => {
    if (params) params.remove('page');
    else setPageState(1);
    dispatch(getSubwarehouses(selectedCompany));
    return getData();
  };

  return (
    <div className={style.container}>
      {!inProfile && (
        <div className={style.header}>
          <SearchWithFilters
            state={searchState}
            setState={setSearchState}
            placeholder={'Wyszukaj...'}
          />
          {isCompanyAdmin && (
            <Button
              label={'Dodaj podmagazyn'}
              onClick={() => setIsModalOpen(true)}
            />
          )}
        </div>
      )}

      <Modal
        title={selectedElement?.id ? `Edytuj podmagazyn "${selectedElement?.name}"` : 'Dodaj podmagazyn'}
        visible={isModalOpen}
        onClose={closeModal}
      >
        <NewSubwarehouseForm
          onClose={closeModal}
          refreshData={getData}
          id={selectedElement?.id}
          currentName={selectedElement?.name}
          selectedCompany={selectedCompany}
        />
      </Modal>
      <div className={style.list}>
        {isLoading ? (
          <Loader />
        ) : subwarehouses.length > 0 ? (
          subwarehouses.map((subwarehouse) => (
            <SubwarehouseThumbnail
              refreshData={refreshAfterDeleteHandler}
              onEdit={() => editElementHandler(subwarehouse)}
              subwarehouse={subwarehouse}
              key={subwarehouse.id}
            />
          ))
        ) : (
          <EmptyState
            type={'subwarehouse'}
            tiny
          />
        )}
        <PaginationNav
          params={params}
          page={!params ? pageState : undefined}
          setPage={!params ? setPageState : undefined}
          pagesQty={lastPage}
          setPerPage={setPerPage}
          defaultQty={perPage}
          hidePadding
          hideSelect
        />
      </div>
    </div>
  );
};

export default SubwarehousesList;
