import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import {
  AllOrdersSumBar,
  Button,
  LoaderGlobal,
  OrderSuppliersTable,
  PageHeader,
  ProcessStepper,
  SeoHelmet,
  useCompany,
  useIsAdmin,
  usePermissions
} from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ListsApi } from 'src/api';
import { userPermissions } from 'src/constants/enums';
import { getDepartments } from 'src/features/Departments/actions';
import { scrollToElementAndReturnPromise } from 'src/utils/helpers';

import { orderProcessSteps } from '../../constants/steps';
import Address from './components/Address';
import OrdersList from './components/OrdersList';
import { clearCartOrderDetails } from './actions';

import style from './CartOrder.module.scss';

const CartOrder = () => {
  const history = useHistory();
  const company = useCompany();
  const isAdmin = useIsAdmin();
  const dispatch = useDispatch();
  const { listUID } = useParams();
  const addressesWrapperRef = useRef();

  const [pageData, setPageData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [shippingAddressId, setShippingAddressId] = useState();
  const [invoiceDetailsId, setInvoiceDetailsId] = useState();

  const version = useSelector((state) => state.cartOrder.version);
  const orderDetails = useSelector((state) => state.cartOrder.orderDetails);
  const [canCreateOrders] = usePermissions([userPermissions.orders.create]);
  const addressesVersion = useSelector((state) => state.companiesList.addressesVersion);
  const invoicesVersion = useSelector((state) => state.companiesList.invoicesVersion);

  const getData = async () => {
    const queryParams = { ...(listUID && { list_uid: listUID }) };

    try {
      const { data } = await ListsApi.resolveOrderData(queryParams);
      setPageData(data);
    } catch (err) {
      notifyApiError(err);
    }
  };

  useEffect(() => {
    const getInitialData = async () => {
      setIsLoading(true);
      await getData();
      dispatch(getDepartments());
      setIsLoading(false);
    };

    dispatch(clearCartOrderDetails());
    getInitialData();

    return () => {
      dispatch(clearCartOrderDetails());
    };
  }, []);

  useEffect(() => {
    if (version > 0 || addressesVersion > 0 || invoicesVersion > 0) getData();
  }, [version, addressesVersion, invoicesVersion]);

  const createOrder = async (needApproval) => {
    if (!shippingAddressId) {
      notifyCommon(['Wybierz adres wysyłki zamówienia.']);
      if (addressesWrapperRef?.current) {
        scrollToElementAndReturnPromise(addressesWrapperRef.current).then(() => addressesWrapperRef.current?.focus());
      }
      return;
    }

    if (!invoiceDetailsId) {
      notifyCommon(['Wybierz dane do faktury.']);
      if (addressesWrapperRef?.current) {
        scrollToElementAndReturnPromise(addressesWrapperRef.current).then(() => addressesWrapperRef.current?.focus());
      }
      return;
    }

    const data = {
      need_approval: company?.is_order_approval_enabled || needApproval,
      shipping_address_id: shippingAddressId,
      invoice_address_id: invoiceDetailsId,
      orderData: orderDetails,
      list_uid: listUID
    };

    setIsLoading(true);
    try {
      const response = await ListsApi.createOrder(data);
      history.push({
        pathname: `/lists/${listUID}/cart/proceed-order/thanks`,
        state: response.data
      });
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <>
        <PageHeader name={'Realizacja zamówienia'} />
        <ProcessStepper
          steps={orderProcessSteps}
          current={2}
        />
        <LoaderGlobal />
      </>
    );
  }

  return (
    <>
      <SeoHelmet title={'Realizacja zamówienia'} />
      <PageHeader name={'Realizacja zamówienia'} />
      <ProcessStepper
        steps={orderProcessSteps}
        current={2}
      />
      <div className={style.container}>
        <OrderSuppliersTable order={pageData} />
        <Address
          ref={addressesWrapperRef}
          setShippingAddressId={setShippingAddressId}
          setInvoiceDetailsId={setInvoiceDetailsId}
          company={pageData?.company}
        />

        <form
          className={style.form}
          onSubmit={(e) => e.preventDefault()}
        >
          <OrdersList suppliers={pageData?.suppliers} />
          <AllOrdersSumBar
            suppliersNumber={pageData?.suppliers?.length}
            shipPrice={pageData?.shipping_sum}
            totalSum={pageData?.total_sum}
            status={pageData?.status}
          >
            {canCreateOrders && (
              <>
                {isAdmin && (
                  <Button
                    label={'Akceptacja zamówienia'}
                    onClick={() => createOrder(true)}
                    disabled={pageData?.suppliers?.length === 0}
                    gray
                  />
                )}
                <Button
                  label={'Potwierdź zamówienie'}
                  onClick={() => createOrder()}
                  disabled={pageData?.suppliers?.length === 0}
                />
              </>
            )}
          </AllOrdersSumBar>
        </form>
      </div>
    </>
  );
};

export default CartOrder;
