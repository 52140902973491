import { combineReducers } from 'redux';

import sidebarReducer from '../components/layout/Sidebar/reducers';
import CartOrderReducer from '../features/CartOrder/reducers';
import CartAndCatalogReducer from '../features/CatalogAndCartController/reducers';
import categoriesReducer from '../features/CategoriesManager/reducers';
import collectionsReducer from '../features/Collections/reducers';
import collectionViewReducer from '../features/CollectionView/reducers';
import companiesListReducer from '../features/CompaniesList/reducers';
import departmentsReducer from '../features/Departments/reducers';
import expensesReducer from '../features/Expenses/reducers';
import invoicesReducer from '../features/Invoices/reducers';
import listsReducer from '../features/Lists/reducers';
import listsViewReducer from '../features/ListView/reducers';
import maintenanceReducer from '../features/Maintenance/reducers';
import notificationsReducer from '../features/NotificationsStore/reducer';
import authReducer from '../features/onboarding/reducers';
import ordersReducer from '../features/Orders/reducers';
import orderViewReducer from '../features/OrdersView/reducers';
import priceListsReducer from '../features/PriceLists/reducers';
import producersReducer from '../features/Producers/reducers';
import replacementsReducer from '../features/ProductReplacementsManagement/reducers';
import productsBaseReducer from '../features/ProductsBase/reducers';
import variationsReducer from '../features/ProductVariationsManagement/reducers';
import servicesReducer from '../features/Services/reducers';
import supplierFormReducer from '../features/SupplierForm/reducers';
import suppliersReducer from '../features/Suppliers/reducers';
import suppliersManagementReducer from '../features/SuppliersManagement/reducers';
import tagsReducer from '../features/TagsManagement/reducers';
import usersReducer from '../features/Users/reducers';
import valuationViewReducer from '../features/ValuationView/reducers';
import warehouseReducer from '../features/Warehouse/reducers';
import wholesalersReducer from '../features/Wholesalers/reducers';

const rootReducer = combineReducers({
  auth: authReducer,
  users: usersReducer,
  lists: listsReducer,
  orders: ordersReducer,
  sidebar: sidebarReducer,
  invoices: invoicesReducer,
  expenses: expensesReducer,
  services: servicesReducer,
  listsView: listsViewReducer,
  suppliers: suppliersReducer,
  orderView: orderViewReducer,
  producers: producersReducer,
  cartOrder: CartOrderReducer,
  categories: categoriesReducer,
  collections: collectionsReducer,
  wholesalers: wholesalersReducer,
  supplierForm: supplierFormReducer,
  productsBase: productsBaseReducer,
  valuationView: valuationViewReducer,
  notifications: notificationsReducer,
  companiesList: companiesListReducer,
  cartAndCatalog: CartAndCatalogReducer,
  collectionView: collectionViewReducer,
  suppliersManagement: suppliersManagementReducer,
  tags: tagsReducer,
  replacements: replacementsReducer,
  variations: variationsReducer,
  departments: departmentsReducer,
  warehouse: warehouseReducer,
  maintenance: maintenanceReducer,
  priceLists: priceListsReducer
});

export default rootReducer;
