import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { Select, useIsAdmin, useWarehouse } from 'components';

import { getSubwarehouses } from 'src/features/Warehouse/actions';
import { compareArrays } from 'src/utils/helpers';

import style from '../Filtering.module.scss';

const defaultOptions = [
  {
    value: -1,
    label: 'Magazyn główny'
  }
];

const formatSubwarehouseValue = (data) => ({
  value: data.id,
  label: data.name
});

const FilterBySubwarehouse = ({
  id,
  name,
  params,
  fullWidth,
  isMulti,
  className,
  label,
  placeholder = 'Wybierz magazyn',
  onChange,
  value,
  selectedCompany,
  filterSubwarehouseIds = [],
  validator,
  rule
}) => {
  const isAdmin = useIsAdmin();
  const dispatch = useDispatch();
  const { subwarehouses } = useWarehouse();
  const paramValue = params?.get('subwarehouse') || value;
  const [selectedSubwarehouse, setSelectedSubwarehouse] = useState(isMulti ? [] : null);

  const handleUpdateValue = (value, options) => {
    if (!value) return null;
    if (Array.isArray(value)) {
      const selectedSubwarehouses = options.filter((option) => value.includes(option.value));
      setSelectedSubwarehouse(selectedSubwarehouses);
    } else {
      const selectedSubwarehouse = options.find((option) => option.value === value);
      setSelectedSubwarehouse(selectedSubwarehouse);
    }
  };

  useEffect(() => {
    if (isAdmin && !selectedCompany) return;
    if (!subwarehouses.length) {
      dispatch(getSubwarehouses(selectedCompany));
    }
  }, [selectedCompany]);

  const options = useMemo(() => {
    const items = [...defaultOptions, ...subwarehouses.map(formatSubwarehouseValue)];
    return items.filter((item) => !filterSubwarehouseIds.includes(item.value));
  }, [subwarehouses, filterSubwarehouseIds]);

  const setSingleValue = (subwarehouse) => {
    const areValueTheSame = subwarehouse?.value === paramValue;

    if (onChange) {
      onChange(subwarehouse?.value);
      return;
    }

    if (!areValueTheSame) {
      if (params.get('page')) {
        params.setFew([
          { key: 'subwarehouse', value: subwarehouse?.value },
          { key: 'page', value: '1' }
        ]);
      } else {
        params.set('subwarehouse', subwarehouse?.value);
      }
    }
  };

  const setMultiValue = (companies) => {
    const idsArray = companies?.map((comp) => comp.value);
    const areArrayTheSame = compareArrays(idsArray, paramValue);

    if (onChange) {
      onChange(idsArray);
      return;
    }

    if (!areArrayTheSame) {
      if (params.get('page')) {
        params.setFew([
          { key: 'subwarehouse', value: idsArray },
          { key: 'page', value: '1' }
        ]);
      } else {
        params.set('subwarehouse', idsArray);
      }
    }
  };

  const onChangeHandler = (e) => {
    setSelectedSubwarehouse(e);
    if (isMulti) {
      setMultiValue(e);
    } else {
      setSingleValue(e);
    }
  };

  useEffect(() => {
    if (options.length && paramValue) handleUpdateValue(paramValue, options);
  }, [value]);

  return (
    <Select
      name={name}
      id={id}
      label={label}
      placeholder={placeholder}
      options={options}
      value={selectedSubwarehouse}
      onChange={onChangeHandler}
      isClearable={false}
      isMulti={isMulti}
      validator={validator}
      rule={rule}
      fullWidth={fullWidth}
      className={className}
    />
  );
};

export default FilterBySubwarehouse;
