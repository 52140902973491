import * as types from './types';

export const changeCompaniesVersion = () => ({
  type: types.CHANGE_COMPANIES_VERSION
});

export const changeCompanyAddressesVersion = () => ({
  type: types.CHANGE_COMPANY_ADDRESSES_VERSION
});

export const changeCompanyInvoicesVersion = () => ({
  type: types.CHANGE_COMPANY_INVOICES_VERSION
});
