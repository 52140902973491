import React, { useState } from 'react';

import { AsyncSelect, Button, Input, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ProductsApi, WholesalersApi } from 'src/api';

import styles from './WholesalerAddProductForm.module.scss';

const WholesalerAddProductForm = ({ wholesalerId, closeModal }) => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productNumber, setProductNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const validator = useValidator();

  const addProductHandler = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const params = {
      wholesaler_id: wholesalerId,
      product_id: selectedProduct.id,
      wh_product_number: productNumber
    };

    try {
      setIsLoading(true);
      const { data } = await WholesalersApi.addOrUpdateProduct(params);
      if (closeModal) closeModal();
      notifyCommon([`${data.message}`]);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      className={styles.container}
      onSubmit={addProductHandler}
    >
      <AsyncSelect
        value={selectedProduct}
        onChange={setSelectedProduct}
        apiCallback={ProductsApi.getProducts}
        valueKey={'id'}
        labelKey={'name'}
        label={'Produkt'}
        placeholder={'Produkt'}
        validator={validator}
        rule={'required'}
        productSearch
        isClearable
      />
      <Input
        id='product_number'
        name='product_number'
        label='ID w hurtownii'
        value={productNumber}
        onChange={(e) => setProductNumber(e.target.value)}
        validator={validator}
      />
      <Button
        label={'Dodaj'}
        isLoading={isLoading}
        className={styles.button}
      />
    </form>
  );
};

export default WholesalerAddProductForm;
