import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Popover } from 'antd';
import classNames from 'classnames';

import { InfoWidget, Menu, Tag, useAuthUser, useIsMobile } from 'components';
import ChangePrice from 'components/layout/changeMenu/ChangePrice';
import ChangeQuantity from 'components/layout/changeMenu/ChangeQuantity';
import CopyToClipboard from 'components/layout/CopyToClipboard';
import ProductThumbnailImage from 'components/layout/ProductThumbnailImage';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { SupplierApi } from 'src/api';
import { ReactComponent as WarningIcon } from 'src/assets/icons/warning.svg';
import replacementThumbnail from 'src/assets/img/replacement-thumbnail.png';
import { orderProductTypes, orderStatuses, userRoles } from 'src/constants/enums';
import { getFormattedAmount } from 'src/utils/helpers';

import OrderListProductDepartment from '../OrderListProductDepartment';

import style from './OrderListProduct.module.scss';
import shared from 'styles/Shared.module.scss';

const OrderListProduct = (props) => {
  const {
    product,
    type,
    hideStatusColumn,
    hideCommentColumn,
    children,
    refreshDataCallback,
    hideProductActions,
    index,
    displayOver,
    showTags = false,
    orderStatus,
    showDepartments = false
  } = props;
  const {
    name,
    producer,
    unit_name,
    qty: quantityShort,
    quantity,
    price,
    price_for_one,
    thumbnail,
    image,
    status,
    wh_product_number,
    type: productType,
    departments = [],
    tags = []
  } = product || {};

  const params = useParams();
  const user = useAuthUser();
  const isMobile = useIsMobile(1400);
  const [isQtyActive, setIsQtyActive] = useState(false);
  const [isPriceActive, setIsPriceActive] = useState(false);

  const qty = useMemo(() => quantityShort || quantity, [quantityShort, quantity]);

  const newQuantityProducts = useSelector((state) => state.valuationView.newQuantityProducts);
  const newQuantity = useMemo(
    () => newQuantityProducts.find((item) => item.id === product.id)?.new_quantity,
    [newQuantityProducts]
  );

  const newPriceProducts = useSelector((state) => state.valuationView.newPriceProducts);
  const newPrice = useMemo(() => newPriceProducts?.find((item) => item.id === product.id)?.new_price, [newPriceProducts]);

  const wasQtyChange = useMemo(() => newQuantity && +newQuantity !== qty, [newQuantity, qty]);
  const wasPriceChange = useMemo(() => newQuantity && +newQuantity !== qty, [newQuantity, qty]);

  const containerClasses = classNames(style.wrapper, style.productName, {
    [style.hideStatusColumn]: hideStatusColumn
  });

  const productName = (
    <p className={classNames(style.text, { [style.trim]: !isMobile })}>
      {type === 'replacement' && (
        <span className={classNames(style.info, style.replacement)}>
          <WarningIcon />
          Zamiennik
        </span>
      )}
      {type === 'offer' && <span className={classNames(style.info, style.offer)}>Oferta dostawcy</span>}
      {name}
    </p>
  );

  const handleChangeQty = async (quantity) => {
    const query = {
      uid: params.uid,
      products: [{ id: product.id, quantity }]
    };

    try {
      await SupplierApi.acceptProductQuantity(query);
      notifyCommon(['Liczba produktu została zmieniona.']);
      refreshDataCallback();
    } catch (err) {
      notifyApiError(err);
    }
  };

  const handleChangePrice = async (price) => {
    const query = {
      uid: params.uid,
      products: [{ id: product.id, price }]
    };

    try {
      await SupplierApi.acceptNewPrice(query);
      notifyCommon(['Cena produktu została zmieniona.']);
      refreshDataCallback();
    } catch (err) {
      notifyApiError(err);
    }
  };

  const popoverContent = useMemo(() => {
    if (!showTags) return null;
    if (!tags) return null;

    return (
      <div className={shared.popoverWrapper}>
        {tags.map((tag) => (
          <Tag
            key={`ProductDetailsTag-${tag.id}`}
            value={tag.name}
            color={tag.color}
          />
        ))}
      </div>
    );
  }, [tags, showTags]);

  const actions = hideProductActions
    ? []
    : [
        {
          title: 'Zmień ilość',
          icon: 'design',
          action: () => setTimeout(() => setIsQtyActive(true), 50),
          roles: ['admin']
        },
        {
          title: 'Zmień cenę',
          color: 'red',
          icon: 'edit',
          action: () => setTimeout(() => setIsPriceActive(true), 50)
        }
      ];

  return (
    <div
      className={classNames(style.container, {
        [style.cancelled]: status === 'CANCELLED',
        [style.replacement]: productType === 'replacement',
        [style.offer]: productType === 'offer',
        [style.offer]: productType === 'offer'
      })}
    >
      <div
        className={classNames(style.item, {
          [style.hideCommentColumn]: hideCommentColumn,
          [style.cancelled]: status === 'CANCELLED',
          [style.replacement]: productType === 'replacement',
          [style.offer]: productType === 'offer',
          [style.offer]: productType === 'offer',
          [style.withMenu]:
            !!actions.length &&
            type === orderProductTypes.supplier &&
            (status === orderStatuses.pending || orderStatus === orderStatuses.pending),
          [style.withTags]: showTags
        })}
      >
        <div className={classNames(style.wrapper, style.index)}>
          <p>{index}.</p>
        </div>
        <div className={style.wrapper}>
          {isMobile && <p>Kod hurtownii: </p>}
          <CopyToClipboard text={wh_product_number} />
        </div>
        <div className={classNames(containerClasses, style.productName)}>
          <ProductThumbnailImage
            thumbnail={type === 'replacement' ? replacementThumbnail : thumbnail}
            image={type === 'replacement' ? null : image}
            title={name}
            id={product.product_id}
            clickable={false}
          />
          {productName}
        </div>
        <div className={classNames(style.wrapper, style.producer)}>
          {isMobile && <p>Producent: </p>}
          <p className={style.text}>{producer}</p>
        </div>
        <div className={classNames(style.wrapper, style.qty)}>
          {isMobile && <p>Ilość: </p>}
          {user?.role === userRoles.admin ? (
            <ChangeQuantity
              wasQtyChange={wasQtyChange}
              quantity={newQuantity || qty}
              unit={unit_name}
              setQuantity={handleChangeQty}
              isActive={isQtyActive}
              setIsActive={setIsQtyActive}
              displayOver={displayOver}
            />
          ) : (
            <p className={style.text}>{`${newQuantity || qty} ${unit_name ? unit_name : ''}`}</p>
          )}
        </div>
        <div className={classNames(style.wrapper, style.forOne)}>
          {isMobile && <p>Cena za szt.: </p>}
          <ChangePrice
            wasPriceChange={wasPriceChange}
            price={newPrice || price_for_one}
            setPrice={handleChangePrice}
            isActive={isPriceActive}
            setIsActive={setIsPriceActive}
            displayOver={displayOver}
          />
        </div>
        <div className={classNames(style.wrapper, style.sum)}>
          {isMobile && <p>Wartość: </p>}
          <p className={style.text}>{getFormattedAmount(price)}</p>
        </div>
        {showTags && (
          <div className={classNames(style.wrapper, style.tags)}>
            {!tags || (tags?.length === 0 && <p className={style.text}>-</p>)}
            {tags?.length > 0 && (
              <Popover
                key={`ProductDetailsTagPopover-${product.product_id}`}
                content={popoverContent}
                placement={'bottomRight'}
              >
                <Tag
                  value={tags[0].name}
                  color={tags[0].color}
                  className={style.tag}
                />
              </Popover>
            )}
          </div>
        )}
        {(!!children || product.comment) && (
          <div className={classNames(style.wrapper, style.withChildren)}>
            {!!product.comment && (
              <InfoWidget>
                <p>{product.comment}</p>
              </InfoWidget>
            )}
            {children}
          </div>
        )}
        {!!actions?.length && type === orderProductTypes.supplier && status === orderStatuses.pending && (
          <Menu
            actions={actions}
            className={style.menu}
            displayOver={displayOver}
          />
        )}
      </div>
      {showDepartments && departments.length > 0 && (
        <div className={style.departments}>
          {departments.map((department, i) => (
            <OrderListProductDepartment
              product={product}
              department={department}
              unit_name={unit_name}
              key={i}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default OrderListProduct;
