import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Input, useIsAdmin, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { WarehouseApi } from 'src/api';
import { getSubwarehouses } from 'src/features/Warehouse/actions';

import style from './NewSubwarehouseForm.module.scss';

const NewSubwarehouseForm = ({ id, currentName, onClose, refreshData, selectedCompany }) => {
  const isAdmin = useIsAdmin();
  const validator = useValidator();
  const dispatch = useDispatch();

  const [name, setName] = useState(currentName);
  const [isLoading, setIsLoading] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const data = {
      name,
      ...(id && { subwarehouse_id: id }),
      ...(isAdmin && { company_id: selectedCompany })
    };

    try {
      setIsLoading(true);
      await WarehouseApi.addOrUpdateSubWarehouse(data);
      if (refreshData) await refreshData();
      dispatch(getSubwarehouses(selectedCompany));
      notifyCommon([`Podmagazyn "${name}" został ${id ? 'zapisany' : 'dodany'}.`]);
      if (onClose) onClose();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      className={style.container}
      onSubmit={submitHandler}
    >
      <Input
        id='name'
        name='name'
        label='Nazwa'
        value={name}
        onChange={(e) => setName(e.target.value)}
        validator={validator}
        rule={'required'}
      />
      <Button
        type={'submit'}
        label={id ? 'Zapisz' : 'Dodaj'}
        isLoading={isLoading}
      />
    </form>
  );
};

export default NewSubwarehouseForm;
