import React, { useMemo } from 'react';

import { Select } from 'components';

const SortByOptions = ({ params, options = [], clearParamsKeys, ...rest }) => {
  const topValue = params.get('top');
  const sortByValue = params.get('sort_by');
  const sortOrderValue = params.get('sort_order');
  const typeValue = params.get('type');

  const selectValue = useMemo(() => {
    const shouldHaveSortBy = options.some((opt) => opt?.sort_by);
    const shouldHaveSortOrder = options.some((opt) => opt?.sort_order);
    const shouldHaveTop = options.some((opt) => opt?.top);
    const shouldHaveType = options.some((opt) => opt?.type);
    const option = options.find(
      (opt) =>
        (shouldHaveSortBy ? opt?.sort_by === sortByValue : true) &&
        (shouldHaveSortOrder ? opt?.sort_order === sortOrderValue : true) &&
        (shouldHaveTop ? opt?.top == topValue : true) &&
        (shouldHaveType ? opt?.type == typeValue : true)
    );

    return option || null;
  }, [options, topValue, sortByValue, sortOrderValue, typeValue]);

  const handleChange = (option) => {
    if (option) {
      const hasPage = !!params.get('page');
      const newParams = [];
      if (hasPage) newParams.push({ key: 'page', value: '1' });
      if (option?.sort_by) newParams.push({ key: 'sort_by', value: option?.sort_by });
      if (option?.sort_order) newParams.push({ key: 'sort_order', value: option?.sort_order });
      if (option?.top) newParams.push({ key: 'top', value: option?.top });
      if (option?.type) newParams.push({ key: 'type', value: option?.type });

      params.setFew(newParams);
    } else {
      if (clearParamsKeys) {
        clearParamsKeys.forEach((key) => {
          params.remove(key);
        });
      } else {
        params.remove('sort_by');
        params.remove('sort_order');
        params.remove('top');
        params.remove('type');
      }
    }
  };

  return (
    <Select
      options={options}
      value={selectValue}
      onChange={handleChange}
      isSearchable={false}
      isClearable
      {...rest}
    />
  );
};

export default SortByOptions;
