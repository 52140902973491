import React, { useEffect, useState } from 'react';
import Loader from 'react-loading-skeleton';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { AsyncSelect, Button, Card, DateRangePicker, EmptyState, PaginationNav, Select } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { AdminApi, ProductsApi, StatisticsApi, UserApi, VariationsApi, WholesalersApi } from 'src/api';
import { EXCEL_FILE_TYPE } from 'src/constants/misc';
import { downloadFile, getDecodeBase64File, getFormattedAmount } from 'src/utils/helpers';
import { handleNumeralWords } from 'src/utils/helpers';

import SelectVariationsModal from '../../../../components/layout/selectionModals/SelectVariationsModal';
import ProductsSaleSummaryThumbnail from './components/ProductsSaleSummaryThumbnail';

import styles from './ProductsSaleSummary.module.scss';
import shared from 'styles/Shared.module.scss';

const statusesOptions = [
  { label: 'Wersja robocza', value: 'DRAFT' },
  { label: 'Oczekuje na akceptację', value: 'WAITING_FOR_APPROVAL' },
  { label: 'Oczekuje na potwierdzenie', value: 'PENDING' },
  { label: 'W realizacji', value: 'PROCESSING' },
  { label: 'Wysłano', value: 'SENT' },
  { label: 'Zrealizowano', value: 'COMPLETED' },
  { label: 'Anulowano', value: 'CANCELLED' }
];

const PricesChangeSummary = () => {
  const [data, setData] = useState([]);
  const [summary, setSummary] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [pageQty, setPageQty] = useState(1);
  const [perPage, setPerPage] = useState(15);

  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedWholesalers, setSelectedWholesalers] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [selectedVariations, setSelectedVariations] = useState([]);
  const [productStatus, setProductStatus] = useState(null);

  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isCSVLoading, setIsCSVLoading] = useState(false);
  const [isXLSXLoading, setIsXLSXLoading] = useState(false);

  const date = dayjs().format('DD.MM.YYYY, HH:mm');

  const getData = async () => {
    const params = {
      page: currentPage,
      perPage,
      ...(startDate && endDate && { date_from: dayjs(startDate).tz().format(), date_to: dayjs(endDate).tz().format() }),
      ...(selectedCompanies.length > 0 && { company_ids: selectedCompanies.map((company) => company.id) }),
      ...(selectedWholesalers.length > 0 && { wholesaler_ids: selectedWholesalers.map((wholesaler) => wholesaler.id) }),
      ...(selectedProducts.length > 0 && { product_ids: selectedProducts.map((product) => product.id) }),
      ...(selectedSuppliers.length > 0 && { supplier_ids: selectedSuppliers.map((supplier) => supplier.id) }),
      ...(selectedVariations.length > 0 && { variation_group_ids: selectedVariations.map((variation) => variation.id) }),
      ...(productStatus && { status_filter: productStatus.value })
    };

    try {
      setIsLoading(true);
      const { data } = await StatisticsApi.getSalePerProductStatistics(params);
      setPageQty(data.last_page);
      setData(data.data);
      setSummary(data.summary);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadCSV = async (data) => {
    const blob = new Blob([data], { type: 'text/csv' });
    downloadFile(blob, `sprzedaz-per-produkt-${date}` + '.csv');
  };
  const downloadXLSX = async (data) => {
    const blob = getDecodeBase64File(data, EXCEL_FILE_TYPE);
    downloadFile(blob, `sprzedaz-per-produkt-${date}` + '.xlsx');
  };

  const downloadHandler = async (file_format) => {
    const params = {
      ...(startDate && endDate && { date_from: dayjs(startDate).tz().format(), date_to: dayjs(endDate).tz().format() }),
      ...(selectedCompanies.length > 0 && { company_ids: selectedCompanies.map((company) => company.id) }),
      ...(selectedWholesalers.length > 0 && { wholesaler_ids: selectedWholesalers.map((wholesaler) => wholesaler.id) }),
      ...(selectedProducts.length > 0 && { product_ids: selectedProducts.map((product) => product.id) }),
      ...(selectedSuppliers.length > 0 && { supplier_ids: selectedSuppliers.map((supplier) => supplier.id) }),
      ...(selectedVariations.length > 0 && { variation_group_ids: selectedVariations.map((variation) => variation.id) }),
      ...(productStatus && { status_filter: productStatus.value }),
      file_format
    };

    try {
      file_format === 'CSV' ? setIsCSVLoading(true) : setIsXLSXLoading(true);
      const { data } = await StatisticsApi.exportSalePerProductStatistics(params);
      file_format === 'CSV' ? downloadCSV(data) : downloadXLSX(data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      file_format === 'CSV' ? setIsCSVLoading(false) : setIsXLSXLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [
    currentPage,
    startDate,
    endDate,
    selectedCompanies,
    selectedWholesalers,
    selectedProducts,
    selectedSuppliers,
    selectedVariations,
    productStatus
  ]);

  useEffect(() => {
    if (currentPage === 1) return;
    setCurrentPage(1);
  }, [startDate, endDate, selectedCompanies, selectedWholesalers, selectedProducts, selectedSuppliers, productStatus]);

  return (
    <Card className={styles.container}>
      <div className={styles.moduleHeader}>
        <h2 className={styles.title}>Analityka sprzedaży produktów</h2>
        <div className={styles.exportingButtons}>
          <Button
            label='Eksport *.csv'
            isLoading={isCSVLoading}
            onClick={() => downloadHandler('CSV')}
          />
          <Button
            label='Eksport *.xlsx'
            isLoading={isXLSXLoading}
            onClick={() => downloadHandler('XLSX')}
          />
        </div>
      </div>
      <div className={styles.filtering}>
        <DateRangePicker
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
        <AsyncSelect
          id={'select-product'}
          value={selectedProducts}
          onChange={setSelectedProducts}
          apiCallback={ProductsApi.getProducts}
          valueKey={'id'}
          labelKey={'name'}
          placeholder={'Produkt'}
          productSearch
          isClearable
          isMulti
        />
        <AsyncSelect
          value={selectedCompanies}
          onChange={setSelectedCompanies}
          apiCallback={AdminApi.getCompanies}
          queryParams={{ show_deactivated: 0 }}
          valueKey={'id'}
          labelKey={'name'}
          placeholder={'Firma'}
          isClearable
          isMulti
          id={'company-select'}
          name={'company-select'}
        />
        <AsyncSelect
          apiCallback={WholesalersApi.getWholesalers}
          onChange={setSelectedWholesalers}
          value={selectedWholesalers}
          placeholder={'Hurtownia'}
          labelKey={'name'}
          valueKey={'id'}
          name={'wholesaler-select'}
          id={'wholesaler-select'}
          isClearable
          isMulti
        />
        <AsyncSelect
          value={selectedSuppliers}
          onChange={setSelectedSuppliers}
          apiCallback={UserApi.getDistributors}
          valueKey={'id'}
          labelKey={'name'}
          placeholder={'Dostawca'}
          queryParams={{ pagination: 1, perPage: 10 }}
          name={'supplier-select'}
          id={'supplier-select'}
          isClearable
          isMulti
        />
        <Select
          options={statusesOptions}
          isSearchable={false}
          isClearable
          value={productStatus}
          onChange={setProductStatus}
          placeholder={'Status'}
          id={'status'}
          name={'status'}
        />
        {/* <AsyncSelect
          value={selectedVariations}
          onChange={setSelectedVariations}
          apiCallback={VariationsApi.getGroupsNames}
          valueKey={'id'}
          labelKey={'name'}
          placeholder={'Wariant'}
          queryParams={{ pagination: 1, perPage: 10 }}
          name={'variation-select'}
          id={'variation-select'}
          isClearable
          isMulti
        /> */}
        <SelectVariationsModal
          selected={selectedVariations}
          setSelected={setSelectedVariations}
          element={
            <Button
              gray
              label={
                !selectedVariations.length
                  ? 'Wybierz warianty'
                  : `Warianty (${selectedVariations.length} ${handleNumeralWords(
                      ['wybrany', 'wybrane', 'wybranych'],
                      selectedVariations.length
                    )})`
              }
            />
          }
        />
      </div>
      {isLoading ? (
        <Loader
          count={15}
          height={40}
          containerClassName={styles.loader}
        />
      ) : data.length < 1 ? (
        <EmptyState
          tiny
          type={'filtersEmpty'}
          className={styles.empty}
        />
      ) : (
        <div className={classNames(shared.tableWrapper, styles.table)}>
          <div className={classNames(shared.tableHeader, styles.grid)}>
            <p>Organizacja</p>
            <p>Produkt</p>
            <p>Data sprzedaży</p>
            <p>Cena</p>
            <p>Ilość</p>
            <p>Wartość</p>
            <p>Status</p>
            <p>Dostawca</p>
            <p>Hurtownia</p>
          </div>
          <div className={shared.tableCategory}>
            {data.map((item, i) => (
              <ProductsSaleSummaryThumbnail
                key={`${item.product_id}${i}`}
                item={item}
              />
            ))}
            <div className={classNames(styles.grid, styles.tableSummary)}>
              <p>
                <strong>Suma:</strong>
              </p>
              <p></p>
              <p></p>
              <p></p>
              <p>
                <strong>{summary.quantity_sum}</strong>
              </p>
              <p>
                <strong>{getFormattedAmount(summary.value_sum)}</strong>
              </p>
              <p></p>
              <p></p>
              <p></p>
            </div>
          </div>
          <PaginationNav
            setPage={setCurrentPage}
            pagesQty={pageQty}
            setPerPage={setPerPage}
            defaultQty={perPage}
            page={currentPage}
            className={styles.pagination}
            hideSelect
          />
        </div>
      )}
    </Card>
  );
};

export default PricesChangeSummary;
