import React, { useEffect, useRef, useState } from 'react';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import { useAtom } from 'jotai/index';
import moment from 'moment';

import InvoiceIcon from 'assets/icons/newSidebar/file-invoice.svg';
import {
  AsyncSelect,
  Button,
  CollectionInfo,
  FilterBySubwarehouse,
  Input,
  Modal,
  QtySelector,
  SaveButton,
  useIsAdmin,
  useValidator,
  useWarehouse
} from 'components';
import Scanner from 'components/layout/BarcodeScanner/components/Scanner';
import { notifyApiError, notifyCommon, notifyDanger } from 'components/layout/Toasts';

import { InvoicesApi, UserApi, WarehouseApi } from 'src/api';
import { ReactComponent as ScannerIcon } from 'src/assets/icons/scanner-gun.svg';
import InvoiceForm from 'src/features/Invoices/components/InvoiceForm';
import { selectedCompanyAtom } from 'src/features/Warehouse';

import SupplyInvoiceThumbnail from './components/SupplyInvoiceThumbnail';

import style from './ProductSupply.module.scss';

const defaultSupply = (product) => ({
  expireDate: null,
  product: product ? { value: product.product_id, label: product.name, ...product } : null,
  quantity: 0,
  price: 0
});

const ProductSupply = ({ refreshData, closeModal, products, subwarehouseId }) => {
  const isAdmin = useIsAdmin();
  const dateInputRef = useRef();
  const validator = useValidator();
  const { subwarehouses } = useWarehouse();
  const containerRef = useRef(null);

  const [selectedCompany] = useAtom(selectedCompanyAtom);

  const [version, setVersion] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isScannerOpen, setIsScannerOpen] = useState(false);
  const [isInvoiceAddOpen, setIsInvoiceAddOpen] = useState(false);
  const [isScrolledAfter, setIsScrolledAfter] = useState(false);

  const [supplyProducts, setSupplyProducts] = useState(products.map((product) => defaultSupply(product)));
  const [supplyDate, setSupplyDate] = useState(moment().format('YYYY-MM-DD'));
  const [selectedSubwarehouseId, setSelectedSubwarehouseId] = useState(subwarehouseId ? Number(subwarehouseId) : null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [invoice, setInvoice] = useState(null);

  const changeProductSupplyData = (index, key, value) => {
    if (key === 'product' && supplyProducts.some((product) => product.product?.product_id === value?.product_id)) {
      notifyDanger(['Produkt znajduje się już na liście dostawy.']);
      return;
    }
    setSupplyProducts((prev) => {
      const newSupply = [...prev];
      newSupply[index] = { ...newSupply[index], [key]: value };
      return newSupply;
    });
  };

  const addSupplyHandler = () => {
    setSupplyProducts((prev) => [...prev, defaultSupply()]);
    setIsScrolledAfter(false);
  };

  const deleteSupplyHandler = (index) => {
    setSupplyProducts((prev) => prev.filter((_, i) => i !== index));
    validator.purgeFields();
  };

  const onInvoiceSave = async () => {
    try {
      const { data } = await InvoicesApi.getInvoices({
        page: 1,
        perPage: 1
      });
      const invoice = data.data[0];
      if (invoice && !selectedSupplier) {
        setSelectedSupplier({ value: invoice.supplier_id, label: invoice.supplier_name });
      }
      setInvoice(invoice || null);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const formSubmitHandler = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      validator.showMessages();
      return null;
    }

    const payload = {
      ...(selectedSubwarehouseId && { subwarehouse_id: selectedSubwarehouseId }),
      ...(isAdmin && { company_id: selectedCompany }),
      ...(invoice && { invoice_id: invoice.id }),
      supply_date: supplyDate,
      supplier_id: selectedSupplier.value,
      products: supplyProducts.map((product) => ({
        product_id: product.product.product_id,
        quantity: product.quantity,
        price: product.price,
        expire_date: product.expireDate
      }))
    };

    try {
      setIsLoading(true);
      await WarehouseApi.addManualSupplyArray(payload);
      if (refreshData) refreshData();
      if (closeModal) closeModal();
      notifyCommon(['Dostawa została dodana.']);
    } catch (error) {
      notifyApiError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle scroll to bottom of the modal after adding new product and not when delete
  useEffect(() => {
    const modalElement = document.querySelector('#modal');
    if (modalElement && !isScrolledAfter) {
      modalElement.scrollTop = modalElement?.scrollHeight;
      setIsScrolledAfter(true);
    }
  }, [isScrolledAfter]);

  useEffect(() => {
    disableBodyScroll(containerRef.current);

    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  const onScanHandler = async (ean, index) => {
    const queryParams = {
      page: 1,
      perPage: 1,
      ean,
      ...(isAdmin && { company_id: selectedCompany })
    };

    try {
      const { data } = await WarehouseApi.getProducts(queryParams);
      const product = data.data[0];
      if (product) {
        if (supplyProducts.some((p) => p.product?.product_id === product.product_id && p.product?.ean === product?.ean)) {
          notifyDanger(['Produkt znajduje się już na liście dostawy.']);
          return;
        }
        changeProductSupplyData(index, 'product', { value: product.product_id, label: product.name, ...product });
      } else {
        notifyCommon([`Nie znaleziono produktu. Kod EAN: ${ean}`]);
      }
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsScannerOpen(false);
    }
  };

  return (
    <div
      className={style.container}
      ref={containerRef}
    >
      <div className={style.settings}>
        <Input
          type='date'
          id='supply_date'
          name='supply_date'
          value={supplyDate}
          onChange={setSupplyDate}
          validator={validator}
          rule={'required'}
          label={'Data dostawy'}
          wrapperStyle={style.dateInput}
        />

        {subwarehouses.length > 0 && (
          <FilterBySubwarehouse
            id={'subwarehouse'}
            name={'subwarehouse'}
            label={'Magazyn'}
            placeholder={'Wybierz magazyn'}
            value={selectedSubwarehouseId}
            onChange={setSelectedSubwarehouseId}
            fullWidth
            selectedCompany={selectedCompany}
            validator={validator}
            rule={'required'}
          />
        )}
        <div className={style.supplierBox}>
          <AsyncSelect
            value={selectedSupplier}
            onChange={setSelectedSupplier}
            apiCallback={UserApi.getDistributors}
            valueKey={'id'}
            labelKey={'name'}
            label={'Dostawca'}
            placeholder={'Dostawca'}
            queryParams={{ pagination: 1, ...(isAdmin && { company_id: selectedCompany }) }}
            validator={validator}
            rule={'required'}
            isClearable
          />
          <Button
            className={style.invoiceButton}
            icon={InvoiceIcon}
            onClick={() => setIsInvoiceAddOpen(true)}
            disabled={!!invoice}
          />
        </div>
      </div>

      {invoice && (
        <div className={style.invoice}>
          <SupplyInvoiceThumbnail
            invoice={invoice}
            onDelete={() => setInvoice(null)}
          />
        </div>
      )}

      <div className={style.list}>
        {supplyProducts.map((supply, i) => (
          <div
            className={style.supply}
            key={`supply-item-${i}`}
          >
            <div className={style.productInput}>
              {isScannerOpen && (
                <Scanner
                  onScan={(ean) => onScanHandler(ean, i)}
                  closeScanner={() => setIsScannerOpen(false)}
                />
              )}
              <Button
                onClick={() => setIsScannerOpen(true)}
                label={<ScannerIcon className={style.icon} />}
                className={style.buttonScanner}
                disabled={!!supply.product}
                type={'button'}
              />
              <AsyncSelect
                apiCallback={WarehouseApi.getProducts}
                onChange={(val) => changeProductSupplyData(i, 'product', val)}
                queryParams={{ version, ...(isAdmin && { company_id: selectedCompany }) }}
                valueKey={'product_id'}
                placeholder={'Produkt'}
                id={`select-product-${i}`}
                validator={validator}
                value={supply.product}
                rule={'required'}
                labelKey={'name'}
                label={'Produkt'}
                productSearch
                isClearable
              />
              <SaveButton
                wrapperStyle={style.deleteButton}
                type={'delete'}
                onClick={() => deleteSupplyHandler(i)}
                isLoading={isLoading}
                disabled={supplyProducts.length === 1}
              />
            </div>

            <div className={style.wrapper}>
              <div className={style.quantityBox}>
                <QtySelector
                  label={'Ilość'}
                  setState={(val) => changeProductSupplyData(i, 'quantity', val)}
                  wrapperStyle={style.quantityInput}
                  state={supply.quantity}
                  hideValueWhenZero
                  id={`quantity-${i}`}
                  suffix={supply.product?.unit_name || 'szt.'}
                  validator={validator}
                  rule={'required|only_positive'}
                />
                {supply.product?.unit_name && supply.product?.unit_name !== 'szt.' && (
                  <CollectionInfo
                    className={style.info}
                    collectionAmount={supply.product?.collection_amount}
                    unitName={supply.product?.unit_name}
                    color={'gray'}
                  />
                )}
              </div>
              <QtySelector
                label={'Cena brutto'}
                setState={(val) => changeProductSupplyData(i, 'price', val)}
                wrapperStyle={style.quantityInput}
                state={supply.price}
                hideValueWhenZero
                validator={validator}
                rule={'required|only_positive'}
                id={`price-${i}`}
                suffix={'zł'}
                priceInput
                hideArrows
              />
              <Input
                type='date'
                id={`expired_date-${i}`}
                name={`expired_date-${i}`}
                value={supply.expireDate || ''}
                ref={dateInputRef}
                onChange={(e) => changeProductSupplyData(i, 'expireDate', e.target.value)}
                label={'Termin ważności'}
                wrapperStyle={style.dateInput}
              />
            </div>

            {/* <div className={style.supplyInfo}>

            </div> */}
          </div>
        ))}
      </div>

      <div className={style.buttons}>
        <Button
          label={'Dodaj kolejny produkt'}
          isLoading={isLoading}
          onClick={addSupplyHandler}
          gray
        />
        <Button
          label={'Zapisz dostawę produktów'}
          isLoading={isLoading}
          onClick={formSubmitHandler}
        />
      </div>

      <Modal
        visible={isInvoiceAddOpen}
        onClose={() => setIsInvoiceAddOpen(false)}
        title={'Dodaj fakturę'}
      >
        <InvoiceForm
          closeModal={() => setIsInvoiceAddOpen(false)}
          onSave={onInvoiceSave}
        />
      </Modal>
    </div>
  );
};

export default ProductSupply;
