import * as types from '../actions/types';

export const defaultViewSettings = {
  columns: ['out_of_stock_quantity', 'quantity', 'price', 'value', 'expire_date', 'comment', 'tags']
};

export const initialState = {
  isLoading: false,
  subwarehouses: [],
  view: defaultViewSettings
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Warehouse - subwarehouses
    case types.GET_SUBWAREHOUSES_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case types.GET_SUBWAREHOUSES_SUCCESS: {
      const { data: subwarehouses } = action.data;

      return {
        ...state,
        isLoading: false,
        subwarehouses
      };
    }
    case types.GET_SUBWAREHOUSES_FAILURE:
      return {
        ...state,
        isLoading: false
      };

    // Warehouse - view settings
    case types.GET_VIEW_SETTINGS_SUCCESS: {
      const { view_settings } = action.data;
      return {
        ...state,
        view: view_settings || defaultViewSettings
      };
    }
    case types.GET_VIEW_SETTINGS_FAILURE:
      return {
        ...state,
        view: defaultViewSettings
      };
    default:
      return state;
  }
};
