import React, { useLayoutEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { PublicHeader, useIsUserAuth } from 'components';

import ConfirmOrder from 'src/features/orderConfirmations/containers/ConfirmOrder';

import SupplierRegister from '../onboarding/containers/SupplierRegister';

import shared from 'styles/Shared.module.scss';

const ConfirmOrderPage = () => {
  const isAuth = useIsUserAuth();
  const history = useHistory();
  const params = useParams();

  useLayoutEffect(() => {
    if (isAuth) {
      history.push(`/supplier/orders/${params?.uid}`);
    }
  }, [isAuth]);

  return (
    <>
      <PublicHeader />
      <div className={shared.publicContainer}>
        <ConfirmOrder />
      </div>
    </>
  );
};

export default ConfirmOrderPage;
