import React, { useState } from 'react';
import dayjs from 'dayjs';

import { Button, Modal, useIsAdmin } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { StatisticsApi } from 'src/api';
import { EXCEL_FILE_TYPE } from 'src/constants/misc';
import { downloadFile, getDecodeBase64File } from 'src/utils/helpers';

import style from './ExportAllCatalogsSuppliersOffers.module.scss';

const fileFormats = ['CSV', 'XLSX'];
const buttonsLabels = { CSV: 'Pobierz w formacie .csv', XLSX: 'Pobierz w formacie .xlsx' };

const ExportAllCatalogsSuppliersOffers = ({ formats = fileFormats, labels = buttonsLabels, className }) => {
  const isAdmin = useIsAdmin();
  const [isOpen, setIsOpen] = useState();
  const [isCSVLoading, setIsCSVLoading] = useState(false);
  const [isXLSXLoading, setIsXLSXLoading] = useState(false);

  const date = dayjs().format('DD.MM.YYYY');

  const downloadCSV = async (data) => {
    const blob = new Blob([data], { type: 'text/csv' });
    const filename = `eksport_ofert_${date}`;
    downloadFile(blob, filename + '.csv');
  };
  const downloadXLSX = async (data) => {
    const blob = getDecodeBase64File(data, EXCEL_FILE_TYPE);
    const filename = `eksport_ofert_${date}`;
    downloadFile(blob, filename + '.xlsx');
  };

  const downloadHandler = async (file_format) => {
    const queryData = {
      file_format
    };

    try {
      file_format === 'CSV' ? setIsCSVLoading(true) : setIsXLSXLoading(true);
      const { data } = await StatisticsApi.exportAllCatalogsSuppliersOffers(queryData);
      file_format === 'CSV' ? downloadCSV(data) : downloadXLSX(data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      file_format === 'CSV' ? setIsCSVLoading(false) : setIsXLSXLoading(false);
    }
  };

  if (!isAdmin) return null;
  return (
    <>
      <Button
        className={className}
        label={'Eksportuj oferty ze wszystkich katalogów firm'}
        onClick={() => setIsOpen(true)}
      />
      <Modal
        visible={isOpen}
        onClose={() => setIsOpen(false)}
        title={'Eksportuj oferty ze wszystkich katalogów firm'}
      >
        <div className={style.container}>
          {formats.map((f) => (
            <Button
              key={f}
              label={labels[f]}
              isLoading={f === 'CSV' ? isCSVLoading : isXLSXLoading}
              onClick={() => downloadHandler(f)}
            />
          ))}
        </div>
      </Modal>
    </>
  );
};

export default ExportAllCatalogsSuppliersOffers;
