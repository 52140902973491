import React, { useState } from 'react';

import { Modal, PageHeader, SeoHelmet, useAuthUser, useIsAdmin } from 'components';

import { AdminDashboardConfig, defaultDashboardConfig } from 'src/constants/dashboardElements';
import { userRoles } from 'src/constants/enums';
import pages from 'src/dictionaries/pages.json';

import DashboardConfigure from '../../components/DashboardConfigure';
import ExpensesByMonth from '../../components/ExpensesByMonth';
import ExpensesCategoriesChart from '../../components/ExpensesCategoriesChart';
import LastInvoices from '../../components/LastInvoices';
import LastOrders from '../../components/LastOrders';
import NewReplacementsGroups from '../../components/NewReplacementsGroups';
import Notifications from '../../components/Notifications';
import QuickAccess from '../../components/QuickAccess';
import SavingsChart from '../../components/SavingsChart';
import SystemMessages from '../../components/SystemMessages';
import DevTestingPlayground from './components/DevTestingPlayground';

import style from '../../Dashboard.module.scss';

const UserDashboard = () => {
  const user = useAuthUser();
  const isAdmin = useIsAdmin();
  const config = user.role === userRoles.admin ? AdminDashboardConfig : user?.dashboard_config || defaultDashboardConfig;
  const [isVisible, setIsVisible] = useState(false);

  const actions = [
    {
      title: 'Modyfikuj',
      icon: 'edit',
      action: () => setIsVisible(true)
    }
  ];

  const closeHandler = () => setIsVisible(false);

  return (
    <>
      {isAdmin && import.meta.env.VITE_NODE_ENV === 'development' && <DevTestingPlayground />}
      <SeoHelmet title={pages.dashboard.title} />
      <PageHeader
        name={pages.dashboard.title}
        className={style.header}
        actions={isAdmin ? undefined : actions}
      />
      <div className={style.container}>
        <SystemMessages />
        <QuickAccess config={config.quickAccess} />
        <div className={style.box}>
          <LastOrders config={config.orders} />
          <ExpensesByMonth config={config.analytics} />
          <ExpensesCategoriesChart config={config.analytics} />
          <SavingsChart config={config.analytics} />
          <Notifications config={config.notifications} />
          <LastInvoices config={config.invoices} />
          <NewReplacementsGroups config={config.replacements} />
        </div>
      </div>
      <Modal
        visible={isVisible}
        onClose={closeHandler}
        title={'Modyfikuj zawartość dashboardu'}
      >
        <DashboardConfigure onClose={closeHandler} />
      </Modal>
    </>
  );
};

export default UserDashboard;
