import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useAtom } from 'jotai';

import { useIsAdmin, useWarehouse } from 'components';

import { selectedCompanyAtom } from 'src/features/Warehouse';

import { getSubwarehouses } from '../../actions';

import style from './WarehouseTabs.module.scss';

const defaultTabs = [
  {
    id: null,
    name: 'Podsumowanie'
  },
  {
    id: -1,
    name: 'Magazyn główny'
  }
];

const WarehouseTabs = ({ params }) => {
  const isAdmin = useIsAdmin();
  const dispatch = useDispatch();
  const { subwarehouses } = useWarehouse();

  const [selectedCompany] = useAtom(selectedCompanyAtom);

  const current = params.get('subwarehouse') || null;

  const changeTab = (id) => {
    params.set('subwarehouse', id);
  };

  useEffect(() => {
    if (isAdmin && !selectedCompany) return;
    dispatch(getSubwarehouses(selectedCompany));
  }, [selectedCompany]);

  const tabs = useMemo(() => {
    const subwarehouseTabs = subwarehouses.map((subwarehouse) => ({
      id: subwarehouse.id,
      name: subwarehouse.name
    }));

    return [...defaultTabs, ...subwarehouseTabs];
  }, [defaultTabs, subwarehouses]);

  if (tabs.length <= 2) return null;
  return (
    <div className={style.tabs}>
      {tabs.map((tab, i) => (
        <button
          key={`tab-${tab.id}-${i}`}
          className={classNames(style.tab, {
            [style.active]: current == tab.id
          })}
          onClick={() => changeTab(tab.id)}
        >
          {tab.name}
        </button>
      ))}
    </div>
  );
};

export default WarehouseTabs;
