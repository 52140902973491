import React, { useEffect, useMemo, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';
import { useAtom } from 'jotai';

import {
  Icon,
  Modal,
  SwitchButton,
  Table,
  useCompany,
  useIsAdmin,
  useIsMobile,
  useLocalStorage,
  useManageMany,
  usePermissions,
  useWarehouse
} from 'components';
import AddProductToList from 'components/layout/AddProductToList';

import { userPermissions } from 'src/constants/enums';
import { tabletHorizontalWidth } from 'src/constants/responsive';
import { selectedCompanyAtom } from 'src/features/Warehouse';
import ProductThumbnail from 'src/features/Warehouse/new/ProductThumbnail';
import { handleNumeralWords } from 'src/utils/helpers';

import ProductListSettings from '../ProductListSettings';
import WarehouseTabs from '../WarehouseTabs';
import { warehouseColumnsConfig } from './config';
import { warehouseCheckboxColumnConfig } from './config';
import { warehouseNameColumnConfig } from './config';

import style from './ProductsList.module.scss';

const getTableHeaderColumns = (columns) => {
  return columns.map((column) => ({
    name: column.name,
    parameterName: column.parameterName
  }));
};

const ProductList = ({ products = [], params, subwarehouseId, isLoading, refreshData }) => {
  const { view } = useWarehouse();
  const isAdmin = useIsAdmin();
  const company = useCompany();
  const [canEditWarehouse] = usePermissions([userPermissions.warehouse.write]);

  const [selectedCompany] = useAtom(selectedCompanyAtom);
  const isTabletHorizontal = useIsMobile(tabletHorizontalWidth);
  const [isScrollTranslated, setIsScrollTranslated] = useLocalStorage('warehouse_scroll_translate', false);

  const containerRef = useRef(null);
  const rightTableRef = useRef(null);

  const [isStickyLeft, setIsStickyLeft] = useState(false);
  const [isStickyRight, setIsStickyRight] = useState(false);
  const [isAddToListModalOpen, setIsAddToListModalOpen] = useState(false);
  const [isListSettingsModalOpen, setIsListSettingsModalOpen] = useState(false);

  const { BulkManagementModule, selectedElements, renderSelectAllCheckbox, renderSingleCheckbox, resetHandler } = useManageMany({
    allElements: products.map((prod) => ({ ...prod, id: prod.product_id })),
    options: [{ label: 'Dodaj wybrane do listy', value: '0', onClick: () => setIsAddToListModalOpen(true) }]
  });

  const selectAllElement = renderSelectAllCheckbox({ content: '', reverse: false });

  const nameColumns = useMemo(() => {
    if (selectAllElement) return [warehouseCheckboxColumnConfig(selectAllElement), warehouseNameColumnConfig];
    return [warehouseNameColumnConfig];
  }, [selectAllElement]);

  const viewColumns = useMemo(() => {
    return view.columns
      .map((column) => warehouseColumnsConfig.find((c) => c.id === column))
      .filter(Boolean)
      .filter((column) => {
        const checkIsAllowed = (permission) => isAdmin || (company && company.permissions.includes(permission));
        const canView = column.permissions.length === 0 || column.permissions.some((perm) => checkIsAllowed(perm));
        return canView;
      })
      .sort((a, b) => a.order - b.order);
  }, [isAdmin, company, view]);

  const tableNameHeaderColumns = useMemo(() => getTableHeaderColumns(nameColumns), [nameColumns]);
  const tableHeaderColumns = useMemo(() => getTableHeaderColumns(viewColumns), [viewColumns]);

  const nameGridStyle = useMemo(() => nameColumns.map((column) => column.width).join(' '), [nameColumns]);
  const gridStyle = useMemo(() => viewColumns.map((column) => column.width).join(' '), [viewColumns]);

  useEffect(() => {
    const checkIfSticky = () => {
      const containerScrollLeft = containerRef.current.scrollLeft;
      const containerScrollRight = containerRef.current.scrollWidth - containerRef.current.clientWidth;

      setIsStickyLeft(containerScrollLeft > 3);
      setIsStickyRight(containerScrollRight - containerScrollLeft > 3);
    };

    const transformScroll = (e) => {
      if (containerRef.current && isScrollTranslated) {
        e.preventDefault();
        containerRef.current.scrollLeft += e.deltaY;
      }
    };

    containerRef.current?.addEventListener('scroll', checkIfSticky);
    rightTableRef.current?.addEventListener('wheel', transformScroll);

    return () => {
      containerRef.current?.removeEventListener('scroll', checkIfSticky);
      rightTableRef.current?.removeEventListener('wheel', transformScroll);
    };
  }, [isScrollTranslated]);

  const tableContent = (onlyName = false, onlyMenu = false) => (
    <>
      {isLoading ? (
        <Skeleton
          height={55}
          count={10}
          style={
            onlyName ? { borderRadius: '10px 0 0 10px' } : onlyMenu ? { borderRadius: '0 10px 10px 0' } : { borderRadius: '0' }
          }
        />
      ) : (
        products.map((product) => (
          <ProductThumbnail
            refreshData={refreshData}
            product={product}
            subwarehouseId={subwarehouseId}
            key={product.id}
            columns={onlyName ? nameColumns : viewColumns}
            gridStyle={onlyName ? nameGridStyle : gridStyle}
            onlyName={onlyName}
            onlyMenu={onlyMenu}
          >
            {renderSingleCheckbox(product.product_id)}
          </ProductThumbnail>
        ))
      )}
    </>
  );

  return (
    <>
      <div className={style.container}>
        <div className={style.box}>
          {BulkManagementModule}
          {!isTabletHorizontal && (
            <SwitchButton
              value={isScrollTranslated}
              setValue={setIsScrollTranslated}
              label={'Horyzontalne przewijanie listy'}
              reverse
            />
          )}
          {canEditWarehouse && (
            <button
              onClick={() => setIsListSettingsModalOpen(true)}
              className={style.settings}
            >
              <Icon
                name='setting'
                size={24}
              />
            </button>
          )}
        </div>

        <div className={style.main}>
          <WarehouseTabs params={params} />
          <div
            className={style.tableWrapper}
            ref={containerRef}
          >
            <Table
              params={params}
              columns={tableNameHeaderColumns}
              className={classNames(style.tableLeft, { [style.shadow]: isStickyLeft })}
              headerClasses={style.tableHeader}
              headerStyle={{ gridTemplateColumns: nameGridStyle }}
            >
              {tableContent(true, false)}
            </Table>
            <Table
              params={params}
              columns={tableHeaderColumns}
              className={style.tableCenter}
              headerClasses={style.tableHeader}
              headerStyle={{ gridTemplateColumns: gridStyle }}
            >
              {tableContent(false, false)}
            </Table>
            <Table
              params={params}
              columns={[{ name: '‎' }]}
              className={classNames(style.tableRight, { [style.shadow]: isStickyRight })}
              headerClasses={style.tableHeader}
              headerStyle={{ gridTemplateColumns: '56px' }}
            >
              {tableContent(false, true)}
            </Table>
          </div>
        </div>

        {BulkManagementModule}
      </div>

      <AddProductToList
        title={`Dodaj ${selectedElements.length} ${handleNumeralWords(
          ['produkt', 'produkty', 'produktów'],
          selectedElements.length
        )} do wybranej listy`}
        selectedProducts={selectedElements}
        visible={isAddToListModalOpen}
        products={products}
        onClose={() => setIsAddToListModalOpen(false)}
        onSuccess={resetHandler}
        showProductsInput
      />

      <Modal
        title='Ustawienia listy produktów'
        visible={isListSettingsModalOpen}
        onClose={() => setIsListSettingsModalOpen(false)}
      >
        <ProductListSettings
          onSave={() => setIsListSettingsModalOpen(false)}
          onCancel={() => setIsListSettingsModalOpen(false)}
        />
      </Modal>
    </>
  );
};

export default ProductList;
