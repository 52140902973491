import { WarehouseApi } from 'src/api';
import { createApiAction } from 'src/api/actions';

import * as types from './types';

export const getDepartments = createApiAction(
  (companyId) =>
    WarehouseApi.getDepartments({
      page: 1,
      perPage: 1000,
      ...(companyId && {
        company_id: companyId
      })
    }),
  types.GET_DEPARTMENTS_REQUEST,
  types.GET_DEPARTMENTS_SUCCESS,
  types.GET_DEPARTMENTS_FAILURE,
  [404]
);

export const refreshDepartments = createApiAction(
  (companyId) =>
    WarehouseApi.getDepartments({
      page: 1,
      perPage: 1000,
      ...(companyId && {
        company_id: companyId
      })
    }),
  types.REFRESH_DEPARTMENTS_REQUEST,
  types.REFRESH_DEPARTMENTS_SUCCESS,
  types.REFRESH_DEPARTMENTS_FAILURE,
  [404]
);
