import React from 'react';

import styles from './AnalyticsSummaryTable.module.scss';

const AnalyticsSummaryTable = ({
  all_products,
  products_only_with_ref_price,
  products_only_with_volume,
  products_with_ref_and_volume,
  products_without_ref_and_volume
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.tableHeader}>
        <p className={styles.title}>Wszystkie produkty</p>
        <p className={styles.title}>Produkty z ceną ref. i wolumenem</p>
        <p className={styles.title}>Produkty tylko z ceną ref.</p>
        <p className={styles.title}>Produkty tylko z wolumenem</p>
        <p className={styles.title}>Produkty bez ceny ref. i wolumenu</p>
      </div>
      <div className={styles.table}>
        <p>{all_products}</p>
        <p>{products_with_ref_and_volume}</p>
        <p>{products_only_with_ref_price}</p>
        <p>{products_only_with_volume}</p>
        <p>{products_without_ref_and_volume}</p>
      </div>
    </div>
  );
};

export default AnalyticsSummaryTable;
