import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { Menu, OrderOrValuationId, useIsMobile, useModalConfirm, usePermissions } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { WarehouseApi } from 'src/api';
import { ReactComponent as DownIcon } from 'src/assets/icons/angle-down.svg';
import { userPermissions } from 'src/constants/enums';
import { tabletVerticalWidth } from 'src/constants/responsive';
import { toDateNumber } from 'src/utils/dateTime';
import { getFormattedAmount, getReleaseTypeTag } from 'src/utils/helpers';

import style from './SingleRelease.module.scss';

const SingleRelease = ({ release, refreshReleases, refreshProductsList, product }) => {
  const { id, department_name, user_name, quantity, release_date, release_type, details = [], value } = release;
  const isMobile = useIsMobile(tabletVerticalWidth);
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);
  const [canViewOrders] = usePermissions([userPermissions.orders.read]);
  const [canEditWarehouse] = usePermissions([userPermissions.warehouse.write]);

  const revokeRelease = async () => {
    try {
      await WarehouseApi.revokeRelease({ release_id: id });
      if (refreshReleases) refreshReleases(1, true);
      if (refreshProductsList) refreshProductsList();
    } catch (err) {
      notifyApiError(err);
    }
  };

  const revokeReleaseConfirm = () => {
    handleOpenModalConfirm({
      message: 'Na pewno chcesz cofnąć wydanie tego produktu?',
      handleConfirm: revokeRelease,
      content: (
        <p>
          Cofnięte zostanie wydanie z dnia <strong>{toDateNumber(release_date)}</strong> z ilością{' '}
          <strong>
            {quantity} {'szt.'}
          </strong>
          .
        </p>
      )
    });
  };

  const actions = useMemo(
    () => [
      {
        title: 'Cofnij wydanie',
        icon: 'replacement',
        action: revokeReleaseConfirm,
        hidden: !canEditWarehouse
      }
    ],
    [canEditWarehouse]
  );

  const head = (
    <div className={classNames(style.head, { [style.hidePrices]: !canViewOrders, [style.hideArrow]: details.length === 0 })}>
      <div className={style.type}>
        {isMobile && <p>Typ wydania:</p>}
        {getReleaseTypeTag({ type: release_type, className: style.tag })}
      </div>
      <div className={style.date}>
        {isMobile && <p>Data:</p>}
        {toDateNumber(release_date)}
      </div>
      <div className={style.quantity}>
        {isMobile && <p>Wydana ilość:</p>}
        {quantity} szt.
      </div>
      {canViewOrders && (
        <div className={style.value}>
          {isMobile && <p>Wartość:</p>}
          {getFormattedAmount(value)}
        </div>
      )}
      <div className={style.department}>
        {isMobile && <p>Wydano dla:</p>}
        {department_name ?? '-'}
      </div>
      <div className={style.user}>
        {isMobile && <p>Wydano przez:</p>}
        {user_name ?? '-'}
      </div>
      <Menu
        actions={actions}
        className={style.menu}
        disabled={actions.every((action) => action.hidden)}
      />
      {details.length > 0 && (
        <DownIcon
          className={classNames(style.arrowIcon, { [style.active]: isDetailsVisible })}
          onClick={() => setIsDetailsVisible((prev) => !prev)}
        />
      )}
    </div>
  );

  const body = (
    <div className={style.body}>
      <div className={style.details}>
        {details?.map((detail, i) => (
          <div
            key={i}
            className={style.item}
          >
            <div className={style.data}>
              <p>Numer zamówienia:</p>
              <Link to={`/orders/${detail.order_uid}`}>
                <OrderOrValuationId id={detail.order_public_id} />
              </Link>
            </div>
            <div className={style.data}>
              <p>Dostawca:</p>
              <span>{detail.supplier_name}</span>
            </div>
            <div className={style.data}>
              <p>Wydana ilość:</p>
              <span>{detail.released_quantity}</span>
            </div>
            {canViewOrders && (
              <div className={style.data}>
                <p>Wartość:</p>
                <span>{getFormattedAmount(detail.released_value)}</span>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className={style.container}>
      {head}
      {isDetailsVisible && details.length > 0 && body}
      {renderedModalConfirm}
    </div>
  );
};

export default SingleRelease;
