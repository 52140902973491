import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import { Button, useIsAdmin } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { OfferCatalogApi } from 'src/api';

import styles from './VolumesForm.module.scss';

const VolumesForm = ({ handleSave, companyId, productId, isSaveLoading, setVolumeState, children }) => {
  const isAdmin = useIsAdmin();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    product_ocr_avg_year_volume: null,
    product_ocr_avg_year_volume_exact: null,
    product_order_avg_year_volume: null,
    product_order_avg_year_volume_exact: null,
    product_avg_year_volume: null
  });

  const averageOcrVolume = useMemo(() => data?.product_ocr_avg_year_volume || null, [data]);
  const averageOrdersVolume = useMemo(() => data?.product_order_avg_year_volume || null, [data]);
  const averageYearVolume = useMemo(() => data?.product_avg_year_volume || null, [data]);

  useEffect(() => {
    const getData = async () => {
      const params = {
        company_id: Number(companyId),
        product_id: productId
      };

      try {
        setIsLoading(true);
        const { data } = await OfferCatalogApi.getAvgMonthlyVolumesForProduct(params);
        setData(data);
      } catch (err) {
        notifyApiError(err);
      } finally {
        setIsLoading(false);
      }
    };

    getData();
  }, []);

  return (
    <div className={styles.container}>
      {isAdmin && (
        <div className={classNames(styles.column, styles.averageCount)}>
          <h4 className={styles.title}>OCR</h4>
          <p className={styles.dataText}>
            <span>Średni wolumen roczny:</span>
            <br />
            <span className={styles.value}>{averageOcrVolume !== null ? averageOcrVolume : '-'}</span>
          </p>
          <Button
            className={styles.button}
            disabled={!averageOcrVolume || isLoading}
            label={averageOcrVolume !== null ? `Przypisz OCR (${averageOcrVolume})` : 'Brak danych'}
            onClick={() => handleSave(averageOcrVolume)}
            isLoading={isSaveLoading}
          />
        </div>
      )}

      {isAdmin && (
        <div className={classNames(styles.column, styles.averageCount)}>
          <h4 className={styles.title}>Zamówienia</h4>
          <p className={styles.dataText}>
            <span>Średni wolumen roczny:</span>
            <br />
            <span className={styles.value}>{averageOrdersVolume !== null ? averageOrdersVolume : '-'}</span>
          </p>
          <Button
            className={styles.button}
            disabled={!averageOrdersVolume || isLoading}
            label={averageOrdersVolume !== null ? `Przypisz zam... (${averageOrdersVolume})` : 'Brak danych'}
            onClick={() => handleSave(averageOrdersVolume)}
            isLoading={isSaveLoading}
          />
        </div>
      )}

      {isAdmin && (
        <div className={classNames(styles.column, styles.averageCount)}>
          <h4 className={styles.title}>OCR i zamówienia</h4>
          <p className={styles.dataText}>
            <span>Średni wolumen roczny:</span>
            <br />
            <span className={styles.value}>{averageYearVolume !== null ? averageYearVolume : '-'}</span>
          </p>
          <Button
            className={styles.button}
            label={averageYearVolume !== null ? `Przypisz śr... (${averageYearVolume})` : 'Brak danych'}
            disabled={!averageYearVolume || isLoading}
            onClick={() => {
              setVolumeState(data.average);
              handleSave(averageYearVolume);
            }}
            isLoading={isSaveLoading}
          />
        </div>
      )}

      <div className={styles.column}>
        <h4 className={styles.title}>Ilość</h4>
        {children}
      </div>
    </div>
  );
};

export default VolumesForm;
