import React, { useState } from 'react';
import moment from 'moment';

import { Button, FilterByDepartment, Input, QtySelector, useDepartments, useIsAdmin, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { WarehouseApi } from '../../../../api';

import style from './ProductReleaseFromSupply.module.scss';

const ProductReleaseFromSupply = ({ product, supply, selectedCompany, onRelease }) => {
  const isAdmin = useIsAdmin();
  const validator = useValidator();
  const { departments = [] } = useDepartments();

  const [isLoading, setIsLoading] = useState(false);
  const [quantity, setQuantity] = useState(supply.current_quantity);
  const [releaseDate, setReleaseDate] = useState(moment().format('YYYY-MM-DD'));
  const [department, setDepartment] = useState(null);

  const handleReleaseProduct = async () => {
    if (!validator.allValid()) {
      validator.showMessages();
      return;
    }

    const payload = {
      product_id: product.id,
      supply_id: supply.id,
      quantity,
      release_date: releaseDate,
      ...(department && { department_id: department.value }),
      ...(isAdmin && { company_id: selectedCompany })
    };

    try {
      setIsLoading(true);
      await WarehouseApi.addReleaseFromSupply(payload);
      notifyCommon([`Wydano produkt z dostawy w ilości ${quantity} szt.`]);
      if (onRelease) onRelease();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={style.container}>
      <div className={style.row}>
        <Input
          value={releaseDate}
          onChange={setReleaseDate}
          wrapperStyle={style.releaseDate}
          validator={validator}
          label='Data wydania'
          name='expired_date'
          id='expired_date'
          rule='required'
          type='date'
        />
        <QtySelector
          label={'Ilość'}
          wrapperStyle={style.qtySelector}
          id={'release-product-qty'}
          name={'release-product-qty'}
          state={quantity}
          setState={setQuantity}
          suffix={'szt.'}
          max={supply.current_quantity}
          validator={validator}
          rule={`required|not_negative|max:${supply.current_quantity},num`}
        />
      </div>
      {departments.length > 0 && (
        <FilterByDepartment
          value={department}
          onChange={setDepartment}
          label={'Jednostka organizacyjna'}
          placeholder={'Wybierz jednostkę...'}
          validator={validator}
          rule='required'
          fullWidth
        />
      )}

      <Button
        className={style.button}
        label={'Zapisz wydanie'}
        onClick={handleReleaseProduct}
        isLoading={isLoading}
      />
    </div>
  );
};

export default ProductReleaseFromSupply;
