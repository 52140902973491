import { notifyApiError } from 'components/layout/Toasts';

import { WarehouseApi } from 'src/api';
import { createApiAction } from 'src/api/actions';
import ApiClient from 'src/api/client';

import { WarehouseRoutes } from '../../../api/routes';
import * as types from './types';

export const getSubwarehouses = createApiAction(
  (companyId) =>
    WarehouseApi.getSubWarehouses({
      page: 1,
      perPage: 10000,
      ...(companyId && {
        company_id: companyId
      })
    }),
  types.GET_SUBWAREHOUSES_REQUEST,
  types.GET_SUBWAREHOUSES_SUCCESS,
  types.GET_SUBWAREHOUSES_FAILURE,
  [401]
);

export const getViewSettings = createApiAction(
  (companyId) =>
    WarehouseApi.getViewSettings({
      ...(companyId && {
        company_id: companyId
      })
    }),
  types.GET_VIEW_SETTINGS_REQUEST,
  types.GET_VIEW_SETTINGS_SUCCESS,
  types.GET_VIEW_SETTINGS_FAILURE,
  [401]
);
