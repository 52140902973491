import React, { useEffect, useRef, useState } from 'react';
import { Tabs } from 'antd';

import { LabelWithValue, useIsAdmin, usePermissions } from 'components';
import ProductDetailsSection from 'components/layout/ProductThumbnailImage/components/ProductDetails/components/ProductDetailsSection';
import Skeleton from 'components/layout/ProductThumbnailImage/components/ProductDetails/components/Skeleton';
import { notifyApiError } from 'components/layout/Toasts';

import { ProductsApi } from 'src/api';
import { userPermissions } from 'src/constants/enums';
import ProductFormAdmin from 'src/features/ProductsBase/components/ProductForms/components/ProductFormAdmin';
import Releases from 'src/features/Warehouse/new/ProductDetails/components/Releases';
import Supplies from 'src/features/Warehouse/new/ProductDetails/components/Supplies';
import { getFormattedAmount } from 'src/utils/helpers';

import style from './ProductDetails.module.scss';

const ProductDetails = ({ productData, refreshData, subwarehouseId }) => {
  const [canViewOrders] = usePermissions([userPermissions.orders.read]);
  const { product_id, id, image, quantity, newest_price, product_comment } = productData;
  const [isLoading, setIsLoading] = useState(false);
  const [product, setProduct] = useState();
  const containerRef = useRef(null);
  const [isModalProductFromOpen, setIsModalProductFromOpen] = useState(false);
  const isAdmin = useIsAdmin();

  const getProductData = async (silent = false) => {
    const query = {
      ...(subwarehouseId && { subwarehouse_id: subwarehouseId })
    };

    try {
      !silent && setIsLoading(true);
      const { data } = await ProductsApi.productDetails(product_id, query);
      setProduct(data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      !silent && setIsLoading(false);
    }
  };

  const refreshAllData = () => {
    getProductData(true);
    refreshData && refreshData();
  };

  useEffect(() => {
    getProductData();
  }, []);

  const items = [
    {
      key: '1',
      label: 'Dostawy produktu',
      children: (
        <Supplies
          refreshCallback={refreshAllData}
          warehouseProdId={id}
          product={product}
          subwarehouseId={subwarehouseId}
        />
      )
    },
    {
      key: '2',
      label: 'Wydania z magazynu',
      children: (
        <Releases
          refreshCallback={refreshAllData}
          warehouseProdId={id}
          product={product}
          subwarehouseId={subwarehouseId}
        />
      )
    }
  ];

  return (
    <div
      className={style.container}
      ref={containerRef}
    >
      <div className={style.header}>
        {isLoading ? (
          <Skeleton />
        ) : (
          <ProductDetailsSection
            refreshCallback={getProductData}
            refreshPageDataCallback={refreshData}
            product={product}
            image={image}
            detailsStyle={style.details}
          >
            <div className={style.row}>
              {canViewOrders && (
                <LabelWithValue
                  label={'Szacowana wartość w magazynie'}
                  value={getFormattedAmount(Math.max(newest_price, 0) * Math.max(quantity, 0))}
                />
              )}
              <LabelWithValue
                label={'Aktualny stan magazynowy'}
                value={quantity}
              />
            </div>
            <LabelWithValue
              label={'komentarz do produktu:'}
              value={product_comment ?? '-'}
            />
            {isAdmin && <a onClick={() => setIsModalProductFromOpen(true)}>Edytuj dane produktu</a>}
          </ProductDetailsSection>
        )}
      </div>
      <div className={style.wrapper}>
        <Tabs
          defaultActiveKey='1'
          items={items}
        />
      </div>
      {isAdmin && (
        <ProductFormAdmin
          title='Edytuj produkt'
          product_id={product_id}
          visible={isModalProductFromOpen}
          type='edit-product'
          onClose={() => setIsModalProductFromOpen(false)}
          refreshCallback={getProductData}
          subwarehouseId={subwarehouseId}
        />
      )}
    </div>
  );
};

export default ProductDetails;
