import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { Button, Input, PhoneInput, Select, useCompany, useIsAdmin, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { CompanyApi } from 'src/api';
import { legalForms } from 'src/constants/misc';
import { changeCompanyInvoicesVersion } from 'src/features/CompaniesList/actions';
import { refreshUserData } from 'src/features/onboarding/actions';

import style from '../../../details.module.scss';

const defaultAddress = {
  street: '',
  building_number: '',
  apartment_number: '',
  zip_code: '',
  city: ''
};

const InvoiceForm = ({ invoiceDetails, closeModal, companyId }) => {
  const company = useCompany();
  const isAdmin = useIsAdmin();
  const dispatch = useDispatch();
  const validator = useValidator();
  const [isLoading, setIsLoading] = useState(false);

  const [address, setAddress] = useState(invoiceDetails?.address || defaultAddress);
  const [name, setName] = useState(invoiceDetails?.name || '');
  const [legalForm, setLegalForm] = useState(null);
  const [phone, setPhone] = useState(invoiceDetails?.phone || '');
  const [nip, setNip] = useState(invoiceDetails?.nip || '');
  const [regon, setRegon] = useState(invoiceDetails?.regon || '');
  const [email, setEmail] = useState(invoiceDetails?.email || '');

  const handleAddressChange = (e) => {
    setAddress((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const saveFormData = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      validator.showMessages();
      return null;
    }

    const hasOtherInvoicesDetails = !!company?.invoices_details?.length > 0;
    const hasDefaultInvoiceDetails = !!company?.invoices_details?.find((details) => details.is_default);

    const data = {
      ...(invoiceDetails && { invoice_data_id: invoiceDetails.id }),
      legal_form: legalForm?.value,
      name,
      nip,
      phone,
      email,
      address,
      regon,
      is_default: !hasOtherInvoicesDetails || !hasDefaultInvoiceDetails,
      ...(invoiceDetails?.is_default && { is_default: invoiceDetails.is_default }),
      ...(!!companyId && { company_id: companyId })
    };

    try {
      setIsLoading(true);
      await CompanyApi.updateInvoiceDetails(data);
      notifyCommon(['Dane zostały zapisane.']);
      if (companyId) await dispatch(changeCompanyInvoicesVersion());
      if (!companyId || (company && companyId === company?.id)) await dispatch(refreshUserData());
      closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const legalFormOptions = useMemo(
    () =>
      legalForms.map((form) => ({
        value: form.name,
        label: `${form.name} (${form.title})`
      })),
    [legalForms]
  );

  useEffect(() => {
    if (!legalForm) {
      setLegalForm(legalFormOptions.find((option) => option.value === invoiceDetails?.legal_form));
    }
  }, [invoiceDetails?.legal_form]);

  return (
    <div className={style.container}>
      <Input
        id='name'
        name='name'
        label='Nazwa firmy'
        value={name}
        onChange={(e) => setName(e.target.value)}
        validator={validator}
        rule={'required'}
      />
      <div className={style.wrapper}>
        <Input
          id='nip'
          name='nip'
          label='NIP'
          value={nip}
          onChange={(e) => setNip(e.target.value)}
          validator={validator}
          rule={'required'}
        />
        <Input
          id='regon'
          name='regon'
          label='REGON'
          value={regon}
          onChange={(e) => setRegon(e.target.value)}
          validator={validator}
        />
      </div>
      <div className={style.wrapper}>
        <Select
          options={legalFormOptions}
          id='legal_form'
          name='legal_form'
          label='Forma prawna'
          placeholder={'Wybierz...'}
          value={legalForm}
          onChange={setLegalForm}
          validator={validator}
          rule={'required'}
          isSearchable={false}
        />
        <PhoneInput
          id='phone'
          name='phone'
          label='Telefon'
          value={phone}
          onChange={setPhone}
          validator={validator}
          rule={'required|phone'}
        />
      </div>
      <div className={classNames(style.wrapper, style.street)}>
        <Input
          id='street'
          name='street'
          label='Ulica'
          value={address.street}
          onChange={handleAddressChange}
          validator={validator}
          rule={'required'}
        />
        <Input
          id='building_number'
          name='building_number'
          label='Numer'
          value={address.building_number}
          onChange={handleAddressChange}
          validator={validator}
          rule={'required'}
        />
        <Input
          id='apartment_number'
          name='apartment_number'
          label='Lokal'
          value={address.apartment_number}
          onChange={handleAddressChange}
        />
      </div>
      <div className={classNames(style.wrapper, style.zipCode)}>
        <Input
          id='zip_code'
          name='zip_code'
          label='Kod pocztowy'
          value={address['zip_code']}
          onChange={handleAddressChange}
          validator={validator}
          rule={'required'}
        />
        <Input
          id='city'
          name='city'
          label='Miejscowość'
          value={address.city}
          onChange={handleAddressChange}
          validator={validator}
          rule={'required'}
        />
      </div>
      <Input
        id='email'
        name='email'
        label='E-mail'
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        validator={validator}
        rule={'email'}
      />
      <Button
        label={'Zapisz'}
        className={style.button}
        onClick={saveFormData}
        isLoading={isLoading}
      />
    </div>
  );
};

export default InvoiceForm;
