import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import style from './InputWrapper.module.scss';

const InputWrapper = ({
  id,
  label,
  description,
  children,
  wrapperStyle,
  hideLabel = false,
  errorMessage,
  setShowError,
  rule
}) => {
  // Rule 'required' does not allow to pass 0 as a value, so we need to check for 'only_positive_or_zero' as well
  const isRequired = useMemo(() => rule?.includes('required') || rule?.includes('only_positive_or_zero'), [rule]);

  useEffect(() => {
    if (setShowError) {
      setShowError(!!errorMessage);
    }
  }, [errorMessage]);

  return (
    <div className={classNames(wrapperStyle, style.container)}>
      {label && (
        <div className={style.wrapper}>
          <label
            htmlFor={id}
            className={classNames({
              [style.label]: !hideLabel,
              [style.visuallyHidden]: hideLabel
            })}
          >
            {label}
            {isRequired && !hideLabel && <span className={style.required}>&nbsp;*</span>}
          </label>
        </div>
      )}
      {description && <p className={style.descriptionField}>{description}</p>}
      {children}
      {errorMessage && <div className={style.errorMessage}>{errorMessage}</div>}
    </div>
  );
};

export const getWrapperProps = (props) => {
  const { id, validator, rule, value, date, checked, extraError } = props;
  const [_id] = useState(id || '');

  let errorMessage;
  if (validator && rule) {
    errorMessage = validator.message(_id, value || checked || date, rule);
  }

  if (extraError) errorMessage = extraError;

  return {
    id: props.id,
    label: props.label,
    hideLabel: props.hideLabel,
    description: props.description,
    validator: props.validator,
    rule: props.rule,
    wrapperStyle: props.wrapperStyle,
    errorMessage: errorMessage,
    hasError: !!errorMessage
  };
};

export default InputWrapper;
