import React, { useEffect, useState } from 'react';

import {
  Checkbox,
  Icon,
  Modal,
  PaginationScroll,
  SearchInput,
  useIsAdmin,
  useIsMobile,
  useRequestAbortController
} from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { VariationsApi } from 'src/api';
import { handleNumeralWords } from 'src/utils/helpers';

import style from '../SelectionModals.module.scss';

const QUERY_LIMIT = 25;

const SelectVariationsModal = ({ selected, setSelected, element }) => {
  const [abortController, setNewController] = useRequestAbortController();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [variations, setVariations] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [querySearch, setQuerySearch] = useState('');
  const [wasDataCleared, setWasDataCleared] = useState(false);

  const handleSelect = (variation) => {
    setSelected((prev) => {
      if (prev.map((i) => i.id).includes(variation.id)) {
        return prev.filter((item) => item.id !== variation.id);
      } else {
        return [...prev, variation];
      }
    });
  };

  const getData = async (page, clearData) => {
    const params = {
      page: page,
      perPage: QUERY_LIMIT,
      search: querySearch || undefined
      // category_id: selectedCategory?.value,
      // producer_id: selectedProducer?.value,
      // ...(selectedCategory && { category_ids: [selectedCategory.value] }),
      // ...(onlyProductsWithOffers.value && { company_id: company?.id }),
      // ...(isAdmin && companyId && { company_id: companyId })
    };

    if (abortController) abortController.abort();
    const signal = setNewController();

    // const ApiCallback = onlyProductsWithOffers.value ? ListsApi.getData : ProductsApi.getProducts;

    try {
      setIsLoading(true);
      const { data } = await VariationsApi.getGroupsNames(params, signal);
      const arr = data?.data || [];

      if (clearData || arr.length === 0) {
        setWasDataCleared(true);
        setVariations(arr);
        setHasMore(arr.length < data.total);
      } else {
        setWasDataCleared(false);
        setVariations((prev) => [...prev, ...arr]);
        setHasMore(arr.length + variations.length < data.total);
      }
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isModalVisible && variations.length === 0) {
      getData(1, true);
      setPage(1);
    }
  }, [isModalVisible]);

  useEffect(() => {
    let searchDelay;

    searchDelay = setTimeout(() => {
      getData(1, true);
      setPage(1);
    }, 400);

    return () => clearTimeout(searchDelay);
  }, [querySearch]);

  const loadMoreResults = () => {
    if (!isLoading) {
      getData(page + 1);
      setPage(page + 1);
    }
  };

  return (
    <>
      <div
        className={style.element}
        onClick={() => setIsModalVisible(true)}
      >
        {element}
      </div>

      <Modal
        title='Wybierz warianty'
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
      >
        <div className={style.container}>
          <SearchInput
            state={querySearch}
            setState={setQuerySearch}
            className={style.input}
            inputClasses={style.input}
            placeholder={'Wyszukaj warianty...'}
            alwaysExpand
            fullWidth
          />

          <div className={style.header}>
            <span className={style.count}>
              {`${selected.length} ${handleNumeralWords(['zaznaczony', 'zaznaczone', 'zaznaczonych'], selected.length)}`}
            </span>

            {selected.length > 0 && (
              <button
                className={style.clearButton}
                onClick={() => setSelected([])}
              >
                Wyczyść
              </button>
            )}
          </div>

          <PaginationScroll
            data={variations}
            hasMore={hasMore}
            page={page}
            loadMoreResults={loadMoreResults}
            isFilteringVisible={true}
            wasDataCleared={wasDataCleared}
            height={500}
          >
            <div className={style.list}>
              {variations.map((item, i) => (
                <div
                  className={style.item}
                  key={`variation-${i}-${item.id}`}
                >
                  <Checkbox
                    className={style.checkbox}
                    onChange={() => handleSelect(item)}
                    value={selected.map((i) => i.id).includes(item.id)}
                    content={item.name}
                  />
                </div>
              ))}
            </div>
          </PaginationScroll>
        </div>
      </Modal>
    </>
  );
};

export default SelectVariationsModal;
