import React, { forwardRef, useMemo } from 'react';
import classnames from 'classnames';

import InputWrapper, { getWrapperProps } from 'components/layout/InputWrapper';

import style from './Textarea.module.scss';

const Textarea = forwardRef((props, ref) => {
  const {
    disabled,
    placeholder,
    isSaved,
    value,
    setValue,
    resizeDisabled = true,
    singleLine,
    onInput,
    hideBorder,
    ...rest
  } = props;

  const wrapperProps = getWrapperProps(props);

  const textareaClassNames = classnames(style.textArea, {
    [style.error]: Boolean(wrapperProps.errorMessage),
    [style.hideBorder]: hideBorder,
    [style.singleLine]: singleLine,
    [style.disabled]: disabled,
    [style.saved]: isSaved,
    [style.resizeDisabled]: resizeDisabled
  });

  const excludedRest = useMemo(() => {
    Object.keys(rest)
      .filter((objKey) => objKey !== 'wrapperStyle')
      .reduce((newObj, key) => {
        newObj[key] = rest[key];
        return newObj;
      }, {});
  }, [props]);

  return (
    <InputWrapper {...wrapperProps}>
      <textarea
        onInput={onInput}
        ref={ref}
        disabled={disabled}
        className={textareaClassNames}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={placeholder}
        {...excludedRest}
      />
    </InputWrapper>
  );
});

export default Textarea;
