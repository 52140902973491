import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { InfoWidget } from 'components';

import { priceTypes } from 'src/constants/enums';

import Product from '../Product';
import CategoryProductsWrapper from './components/CategoryProductsWrapper';

import style from './ProductsTable.module.scss';
import shared from 'styles/Shared.module.scss';

const ProductsTable = ({ inPublicPage }) => {
  const categories = useSelector((state) => state.supplierForm.categories);
  const priceType = useSelector((state) => state.supplierForm.form.price_type);

  const getProducts = useCallback((products = []) => {
    return products.map((item) => (
      <Product
        productData={item}
        key={String(item.id + item.name)}
      />
    ));
  }, []);

  const categoriesElements = useMemo(() => {
    const arr = [];

    categories.forEach((cat, i) => {
      const products = getProducts(cat?.items);

      if (products.length) {
        arr.push(
          <CategoryProductsWrapper
            name={cat.name}
            key={cat.name + i}
          >
            {products}
          </CategoryProductsWrapper>
        );
      }
    });

    return arr;
  }, [categories]);

  const headerClasses = classNames(shared.tableHeader, style.header, {
    [style.headerNetto]: priceType.value === priceTypes.netto,
    [style.publicSticky]: inPublicPage
  });

  if (categoriesElements.length === 0) {
    return null;
  }

  return (
    <section className={classNames(shared.tableWrapper, style.container)}>
      <h2 className={classNames(style.title)}>Produkty do wyceny</h2>
      <div className={headerClasses}>
        <p>Nazwa</p>
        <p>Cena {priceType.label}</p>
        {priceType.value === priceTypes.netto && <p>Stawka VAT</p>}
        <p className={style.value}>Ilość</p>
        <p className={style.value}>Wartość</p>
        <p>Dostępność</p>
      </div>
      {categoriesElements}
    </section>
  );
};

export default ProductsTable;
