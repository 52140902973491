import React, { useMemo, useState } from 'react';
import cn from 'classnames';
import { useAtom } from 'jotai/index';

import {
  ChangeValue,
  CommentModal,
  Icon,
  ItemTagsList,
  Menu,
  Modal,
  useIsAdmin,
  useIsMobile,
  useModalConfirm,
  usePermissions,
  useWarehouse
} from 'components';
import AddProductToList from 'components/layout/AddProductToList';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { WarehouseApi } from 'src/api';
import { userPermissions } from 'src/constants/enums';
import { tabletWidth } from 'src/constants/responsive';
import { modalTypes } from 'src/features/Warehouse';
import { selectedCompanyAtom } from 'src/features/Warehouse';
import ProductComment from 'src/features/Warehouse/new/ProductComment';
import ProductDetails from 'src/features/Warehouse/new/ProductDetails';
import { toDateNumber } from 'src/utils/dateTime';
import { getExpireDateStatus } from 'src/utils/dateTime';
import { getFormattedAmount, imageErrorHandler } from 'src/utils/helpers';

import ProductModal from '../../components/ProductModal';
import MoveProductForm from '../MoveProductForm';

import style from './ProductThumbnail.module.scss';
import shared from 'styles/Shared.module.scss';

const ProductThumbnail = ({ product, refreshData, children, subwarehouseId, columns = [], gridStyle, onlyName, onlyMenu }) => {
  const {
    out_of_stock_quantity,
    latest_expire_date,
    top_category_name,
    product_comment,
    producer_name,
    newest_price,
    product_id,
    thumbnail,
    quantity,
    value,
    tags,
    name,
    id
  } = product;

  const isAdmin = useIsAdmin();
  const isMobile = useIsMobile();
  const isTablet = useIsMobile(tabletWidth);
  const { subwarehouses } = useWarehouse();

  const [canViewOrders] = usePermissions([userPermissions.orders.read]);
  const [canEditWarehouse] = usePermissions([userPermissions.warehouse.write]);
  const [canDeleteWarehouse] = usePermissions([userPermissions.warehouse.delete]);

  const hasSubwarehouses = subwarehouses.length > 0;
  const [selectedCompany] = useAtom(selectedCompanyAtom);

  const [modalType, setModalType] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProductDetailsOpen, setIsProductDetailsOpen] = useState(false);
  const [isMoveProductOpen, setIsMoveProductOpen] = useState(false);
  const [isProductCommentOpen, setIsProductCommentOpen] = useState(false);
  const [isModalAddToListOpen, setIsModalAddToListOpen] = useState(false);
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();

  const viewColumns = useMemo(() => columns.map((column) => column.id), [columns]);

  const deleteProduct = async () => {
    try {
      await WarehouseApi.deleteProduct(id);
      if (refreshData) refreshData();
      notifyCommon([`Produkt "${name}" został usunięty z magazynu.`]);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const saveOutOfStockQuantity = async (qty) => {
    const params = {
      ...(isAdmin && { company_id: selectedCompany }),
      out_of_stock_quantity: qty,
      wh_product_id: id,
      product_id
    };

    try {
      await WarehouseApi.addOrUpdateProduct(params);
      if (refreshData) await refreshData();
    } catch (err) {
      notifyApiError(err);
    }
  };

  const deleteProductHandler = () => {
    handleOpenModalConfirm({
      message: 'Na pewno chcesz usunąć produkt? Tej akcji nie można cofnąć.',
      handleConfirm: deleteProduct
    });
  };

  const actions = useMemo(() => {
    const items = [
      {
        title: 'Dodaj do listy',
        icon: 'add',
        action: () => setIsModalAddToListOpen(true)
      },
      {
        title: 'Dostawa',
        icon: 'add',
        color: 'blue',
        action: () => handleOpenModal(modalTypes.increase),
        hidden: !canEditWarehouse
      },
      {
        title: 'Wydanie',
        icon: 'minus',
        action: () => handleOpenModal(modalTypes.decrease),
        hidden: !canEditWarehouse,
        disabled: quantity <= 0
      },
      {
        title: 'Przesunięcie magazynowe',
        icon: 'move',
        action: () => setIsMoveProductOpen(true),
        hidden: (hasSubwarehouses && !subwarehouseId) || !canEditWarehouse,
        disabled: quantity <= 0
      },
      {
        title: 'Dodaj komentarz',
        icon: 'message',
        action: () => setIsProductCommentOpen(true)
      },
      {
        title: 'Usuń produkt',
        icon: 'trash',
        color: 'red',
        action: deleteProductHandler,
        hidden: !canDeleteWarehouse
      }
    ];

    return items;
  }, [subwarehouseId, hasSubwarehouses]);

  const outOfStockQuantityElement = canEditWarehouse ? (
    <ChangeValue
      apiCallback={saveOutOfStockQuantity}
      initialValue={out_of_stock_quantity}
      type={'number'}
      label={'Zmień stan min.'}
      placement={'bottom'}
      unit={'szt.'}
      icon={
        <Icon
          name={'edit'}
          fill={'#888'}
        />
      }
    />
  ) : (
    <p>{out_of_stock_quantity} szt.</p>
  );

  const handleOpenModal = (type) => {
    setModalType(type);
    setIsModalOpen(true);
  };

  const quantityWrapperStyles = useMemo(
    () =>
      cn(style.wrapper, {
        [style.error]: quantity < out_of_stock_quantity
      }),
    [quantity, out_of_stock_quantity]
  );

  const expireDateWrapperStyles = useMemo(() => {
    const type = getExpireDateStatus(latest_expire_date);
    return cn(style.wrapper, { [style[type]]: !!type }, style.expireDate);
  }, [latest_expire_date]);

  const modals = (
    <>
      <AddProductToList
        title={`Dodaj "${name}" do listy`}
        visible={isModalAddToListOpen}
        onClose={() => setIsModalAddToListOpen(false)}
        productId={product_id}
      />
      {renderedModalConfirm}
      <Modal
        title='Przenieś produkt'
        visible={isMoveProductOpen}
        onClose={() => setIsMoveProductOpen(false)}
      >
        <MoveProductForm
          product={product}
          apiCallback={WarehouseApi.moveProductsBetweenSubWarehouses}
          apiPayloadBase={{
            wh_product_id: id,
            from_subwarehouse_id: Number(subwarehouseId || -1) // -1 stands for main warehouse
          }}
          subwarehouseId={Number(subwarehouseId || -1)}
          maxQuantityToMove={quantity}
          onMove={() => {
            setIsMoveProductOpen(false);
            refreshData();
          }}
        />
      </Modal>
      <Modal
        title={'Dodaj komentarz'}
        visible={isProductCommentOpen}
        onClose={() => setIsProductCommentOpen(false)}
      >
        <ProductComment
          product={product}
          onClose={() => {
            setIsProductCommentOpen(false);
            refreshData();
          }}
        />
      </Modal>
      <ProductModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        modalType={modalType}
        refreshData={refreshData}
        product={product}
        subwarehouseId={subwarehouseId}
      />
    </>
  );

  if (onlyName) {
    return (
      <div
        className={style.container}
        style={{ gridTemplateColumns: gridStyle }}
      >
        {!!children && <div className={cn(style.wrapper, style.checkbox)}>{children}</div>}
        <div className={style.onlyName}>
          <a onClick={() => setIsProductDetailsOpen(true)}>
            <div className={style.wrapper}>
              {!isTablet && (
                <img
                  src={thumbnail}
                  alt={name}
                  className={cn(shared.defaultThumbnail)}
                  loading={'lazy'}
                  onError={imageErrorHandler}
                />
              )}
              <p className={style.text}>{name}</p>
            </div>
          </a>
          <div className={style.productMeta}>
            <p>
              <span>Producent: </span>
              {producer_name}
            </p>
            <p>
              <span>Kategoria: </span>
              {top_category_name}
            </p>
          </div>
        </div>

        <Modal
          title={'Detale produktu'}
          visible={isProductDetailsOpen}
          onClose={() => setIsProductDetailsOpen(false)}
        >
          <ProductDetails
            productData={product}
            refreshData={refreshData}
            subwarehouseId={Number(subwarehouseId)}
          />
        </Modal>
      </div>
    );
  }

  if (onlyMenu)
    return (
      <div className={style.container}>
        <div className={style.onlyMenu}>
          <Menu
            actions={actions}
            className={style.menu}
          />
        </div>
        {modals}
      </div>
    );

  return (
    <div
      className={cn(style.container, { [style.withChildren]: !!children, [style.hidePrices]: !canViewOrders })}
      style={{ gridTemplateColumns: gridStyle }}
    >
      {viewColumns.includes('out_of_stock_quantity') && <div className={style.wrapper}>{outOfStockQuantityElement}</div>}
      {viewColumns.includes('quantity') && <div className={quantityWrapperStyles}>{quantity} szt.</div>}
      {viewColumns.includes('price') && (
        <div className={style.wrapper}>
          <p>{newest_price ? getFormattedAmount(newest_price) : '-'}</p>
        </div>
      )}
      {viewColumns.includes('value') && (
        <div className={style.wrapper}>
          <p>{value ? getFormattedAmount(value) : '-'}</p>
        </div>
      )}
      {viewColumns.includes('expire_date') && (
        <div className={expireDateWrapperStyles}>{latest_expire_date ? toDateNumber(latest_expire_date) : '-'}</div>
      )}
      {viewColumns.includes('comment') && (
        <div className={style.wrapper}>
          <CommentModal
            comment={product_comment}
            title={name}
            modalTitle={'Komentarz do produktu'}
          />
        </div>
      )}
      {viewColumns.includes('tags') && (
        <div className={style.wrapper}>
          <div className={style.text}>
            <ItemTagsList
              displayOnly
              tags={tags ? tags : []}
            />
          </div>
        </div>
      )}

      {modals}
    </div>
  );
};

export default ProductThumbnail;
