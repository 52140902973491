import React, { useState } from 'react';
import dayjs from 'dayjs';

import { Button, DateRangePicker, Modal, useIsAdmin } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { WarehouseApi } from 'src/api';
import { months } from 'src/constants/misc';
import { EXCEL_FILE_TYPE } from 'src/constants/misc';
import { downloadFile, getDecodeBase64File } from 'src/utils/helpers';

import style from './ExportRaportModal.module.scss';

export const dateOptions = [
  { label: `bieżący miesiąc (${months[dayjs().month()]})`, value: 'CURRENT_MONTH' },
  { label: `poprzedni miesiąc (${months[dayjs().subtract(1, 'month').month()]})`, value: 'PREVIOUS_MONTH' },
  { label: 'bieżący kwartał', value: 'CURRENT_QUARTER' },
  { label: 'poprzedni kwartał', value: 'PREVIOUS_QUARTER' },
  { label: 'bieżący rok', value: 'CURRENT_YEAR' },
  { label: 'wybierz własny', value: 'CUSTOM_RANGE' }
];

const ExportRaportModal = ({ isOpen, setIsOpen, selectedCompany }) => {
  const isAdmin = useIsAdmin();

  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [isXlsxLoading, setIsXlsxLoading] = useState(false);
  const [isCsvLoading, setIsCsvLoading] = useState(false);

  const downloadHandler = async (type) => {
    const query = {
      ...(isAdmin && { company_id: selectedCompany }),
      file_format: type,
      date_from: dayjs(startDate).tz().format(),
      date_to: dayjs(endDate).tz().format()
    };

    const date = dayjs(query.date_from).format('DD.MM.YYYY') + '-' + dayjs(query.date_to).format('DD.MM.YYYY');

    const downloadCSV = (data) => {
      const blob = new Blob([data], { type: 'text/csv' });
      downloadFile(blob, 'raport_magazynu' + '_' + date + '.csv');
    };
    const downloadXLSX = (data) => {
      const blob = getDecodeBase64File(data, EXCEL_FILE_TYPE);
      downloadFile(blob, 'raport_magazynu' + '_' + date + '.xlsx');
    };

    try {
      type === 'CSV' ? setIsCsvLoading(true) : setIsXlsxLoading(true);
      const data = await WarehouseApi.exportWarehouseReport(query);
      type === 'CSV' ? downloadCSV(data.data) : downloadXLSX(data.data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      type === 'CSV' ? setIsCsvLoading(false) : setIsXlsxLoading(false);
    }
  };

  return (
    <Modal
      title={'Pobierz raport'}
      visible={isOpen}
      onClose={() => setIsOpen(false)}
      className={style.modal}
    >
      <div className={style.container}>
        <DateRangePicker
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          id={'rangeSelect'}
          label={'Wybierz zakres'}
          fullWidth
          rule={'required'}
        />
        <Button
          className={style.button}
          label={'Pobierz .xlsx'}
          onClick={() => downloadHandler('XLSX')}
          isLoading={isXlsxLoading}
          disabled={!startDate || !endDate}
        />
      </div>
    </Modal>
  );
};

export default ExportRaportModal;
