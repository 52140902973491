import React from 'react';
import classNames from 'classnames';

import { SaveButton } from 'components';

import { toDateNumber } from 'src/utils/dateTime';
import { getFormattedAmount, getInvoiceType } from 'src/utils/helpers';

import style from './SupplyInvoiceThumbnail.module.scss';

const SupplyInvoiceThumbnail = ({ invoice, onDelete }) => {
  const { supplier_name, company_name, type, sale_date, sum } = invoice;

  return (
    <div className={style.invoice}>
      {getInvoiceType({ type, className: style.tag })}

      <div className={style.data}>
        <div className={style.wrapper}>
          <p className={style.label}>Dostawca:</p>
          <p className={style.text}>{supplier_name}</p>
        </div>

        <div className={style.wrapper}>
          <p className={style.label}>Oddział:</p>
          <p
            title={company_name}
            className={classNames(style.text, style.company)}
          >
            {company_name}
          </p>
        </div>

        <div className={style.wrapper}>
          <p className={style.label}>Suma:</p>
          <p className={style.text}>{getFormattedAmount(sum)}</p>
        </div>

        <div className={style.wrapper}>
          <p className={style.label}>Data sprzedaży:</p>
          {<p className={style.text}>{sale_date ? toDateNumber(sale_date) : '-'}</p>}
        </div>
      </div>

      <SaveButton
        wrapperStyle={style.deleteButton}
        type={'delete'}
        onClick={onDelete}
      />
    </div>
  );
};

export default SupplyInvoiceThumbnail;
