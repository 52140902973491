import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import cn from 'classnames';

import { EmptyState, ScrollPagination, useRecentlyOrderedProducts } from 'components';

import ProductReplacementThumbnail from '../Product/containers/ProductReplacementThumbnail';
import ProductWithAllSuppliers from '../Product/containers/ProductWithAllSuppliers';
import ProductWithSelectedSuppliers from '../Product/containers/ProductWithSelectedSuppliers';
import ProductWithVolumes from '../Product/containers/ProductWithVolumes';

import style from '../../CatalogAndCartController.module.scss';
import shared from 'styles/Shared.module.scss';

const ProductsList = ({
  status,
  showRefPrice,
  isCatalogView,
  isQuietLoading,
  productClasses,
  selectedCompany,
  products = [],
  changeValuationSelections,
  replacementsList = false,
  isMonthVolumesView = false,
  setReplacement,
  currentReplacement,
  customQuantity,
  viewOnly = false,
  scrollPagination = false,
  scrollPage,
  setScrollPage,
  pageData
}) => {
  const { listUID } = useParams();

  const recentlyOrdered = useRecentlyOrderedProducts(listUID);
  const isDefinedView = useSelector((state) => state.cartAndCatalog.isDefinedView);

  const ProductComponent = useMemo(() => {
    if (isMonthVolumesView) return ProductWithVolumes;
    if (isDefinedView && !replacementsList) return ProductWithSelectedSuppliers;
    if (replacementsList) return ProductReplacementThumbnail;
    return ProductWithAllSuppliers;
  }, [isDefinedView, isMonthVolumesView, replacementsList]);

  const loadMoreResults = () => {
    if (setScrollPage) setScrollPage((prev) => prev + 1);
  };

  const productItems = products.map((product) => (
    <ProductComponent
      status={status}
      key={product.id}
      product={product}
      className={productClasses}
      showRefPrice={showRefPrice}
      isCatalogView={isCatalogView}
      selectedCompany={selectedCompany}
      changeValuationSelections={changeValuationSelections}
      recentlyOrdered={recentlyOrdered.find((prod) => prod.product_id === product.product_id)}
      isQuietLoading={isQuietLoading}
      setReplacement={setReplacement}
      currentReplacement={currentReplacement}
      customQuantity={customQuantity}
      isReplacementsList={replacementsList}
      viewOnly={viewOnly}
    />
  ));

  if (products.length === 0) {
    return (
      <section className={classNames(shared.tableWrapper, style.tableWrapper, { [style.elastic]: isDefinedView })}>
        <div className={cn(style.box, style.center, { [style.loadingAnimation]: isQuietLoading })}>
          <EmptyState type={'noPrices'} />
        </div>
      </section>
    );
  }

  return (
    <div className={style.leftColumn}>
      <section
        className={classNames(shared.tableWrapper, style.tableWrapper)}
        id={'valuation-table'}
      >
        {isDefinedView && !isMonthVolumesView && (
          <div
            className={cn(style.tableHeader, {
              [style.withRefPrices]: showRefPrice,
              [style.replacementsListThumbnail]: !!replacementsList,
              [style.viewOnly]: viewOnly
            })}
          >
            <p className={style.supplierLabel}>Produkt</p>
            {showRefPrice && <p className={style.supplierLabel}>Informacje</p>}
            <p className={style.supplierLabel}>Oferta preferowana</p>
            <p className={style.supplierLabel}>Oferta alternatywna</p>
            <p className={cn(style.supplierLabel, { [style.hideOnTablet]: !replacementsList })}>Oferta zapasowa</p>
          </div>
        )}
        {!isDefinedView && !isMonthVolumesView && (
          <div className={cn(style.tableHeader, style.withAll, { [style.withRefPrices]: showRefPrice })}>
            <p className={style.supplierLabel}>Produkt</p>
            <p className={style.supplierLabel}>Oferty</p>
          </div>
        )}
        {isMonthVolumesView && (
          <div
            className={cn(style.tableHeader, {
              [style.withVolumes]: isMonthVolumesView,
              [style.viewOnly]: viewOnly
            })}
          >
            <p className={style.supplierLabel}>Produkt</p>
            <p className={cn(style.supplierLabel, style.center)}>Śr. wol. roczny (OCR)</p>
            <p className={cn(style.supplierLabel, style.center)}>Śr. wol. roczny (Avalio)</p>
            <p className={style.supplierLabel}>Wolumeny miesięczne</p>
          </div>
        )}
        <div
          className={cn(style.box, { [style.loadingAnimation]: isQuietLoading, [style.isReplacementsTable]: replacementsList })}
        >
          {scrollPagination ? (
            <ScrollPagination
              wrapperStyle={style.paginationContainer}
              data={products}
              page={scrollPage}
              hasMore={scrollPage < pageData?.last_page}
              loadMoreResults={loadMoreResults}
            >
              {productItems}
            </ScrollPagination>
          ) : (
            productItems
          )}
        </div>
      </section>
    </div>
  );
};

export default ProductsList;
