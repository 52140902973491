import React, { useState } from 'react';

import { Button, useIsAdmin, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { OfferCatalogApi } from 'src/api';

import styles from './AssignVolumesView.module.scss';

const AssignVolumesView = ({ selectedCompany, refetchData, closeModal }) => {
  const isAdmin = useIsAdmin();
  const [isLoadingOcr, setIsLoadingOcr] = useState(false);
  const [isLoadingOrders, setIsLoadingOrders] = useState(false);

  const assignOcrVolumes = async () => {
    const params = {
      ...(isAdmin && selectedCompany && { company_id: parseInt(selectedCompany) })
    };

    try {
      setIsLoadingOcr(true);
      const { data } = await OfferCatalogApi.setOCRAvgVolumesForCatalog(params);
      if (refetchData) refetchData();
      if (closeModal) closeModal();
      notifyCommon([data.message]);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoadingOcr(false);
    }
  };

  const assignOrdersVolumes = async () => {
    const params = {
      ...(isAdmin && selectedCompany && { company_id: parseInt(selectedCompany) })
    };

    try {
      setIsLoadingOrders(true);
      const { data } = await OfferCatalogApi.setOrdersAvgVolumesForCatalog(params);
      if (refetchData) refetchData();
      if (closeModal) closeModal();
      notifyCommon([data.message]);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoadingOrders(false);
    }
  };

  if (!isAdmin) return null;
  return (
    <div className={styles.container}>
      <p>Algorytm automatycznie przypisze wolumen roczny do produktów na podstawie średniej wartości z wybranych danych</p>
      <p>
        <strong>Uwaga!</strong> Tej akcji nie będzie można cofnąć, ale zawsze można zmienić wolumen ręcznie.
      </p>
      <div className={styles.buttons}>
        <Button
          label={'Przypisz z OCR'}
          isLoading={isLoadingOcr}
          className={styles.button}
          onClick={assignOcrVolumes}
          disabled={isLoadingOrders}
        />
        <Button
          label={'Przypisz z zamówień'}
          isLoading={isLoadingOrders}
          className={styles.button}
          onClick={assignOrdersVolumes}
          disabled={isLoadingOcr}
        />
      </div>
    </div>
  );
};

export default AssignVolumesView;
