import React, { useState } from 'react';
import cn from 'classnames';

import { Icon, Loader, useAuthUser } from 'components';
import styles from 'components/layout/Actions/Actions.module.scss';

const Button = ({ item, classActionsOption, toggle, index }) => {
  const { roles, disabled, color, action, icon, title, hidden = false } = item;
  const user = useAuthUser();
  const [isLoading, setIsLoading] = useState(false);

  const buttonClasses = cn(styles.option, classActionsOption, {
    [styles.disabled]: disabled,
    [styles[color]]: color
  });

  const onClick = async () => {
    try {
      setIsLoading(true);
      await action();
      if (toggle) toggle();
    } finally {
      setIsLoading(false);
    }
  };

  if (roles && !roles?.includes(user?.role)) {
    return null;
  }

  if (hidden) return null;

  return (
    <button
      key={index}
      tabIndex={2}
      onClick={onClick}
      disabled={disabled}
      className={buttonClasses}
    >
      {icon &&
        (isLoading ? (
          <Loader />
        ) : (
          <Icon
            name={icon}
            size='24'
          />
        ))}
      <span>{title}</span>
    </button>
  );
};

export default Button;
